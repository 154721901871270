import { useEffect } from 'react';
import store from 'store2';

import { useProfileInfoQuery } from 'services';
import { useTranslation } from 'react-i18next';

export const useInitialLanguage = (): void => {
  const { i18n } = useTranslation();
  const storedLanguage = store('driverbook_language');
  const { data, isFetching } = useProfileInfoQuery();
  const databaseLanguage = data?.language;

  useEffect(() => {
    if (!isFetching && databaseLanguage !== storedLanguage) {
      i18n.changeLanguage(databaseLanguage as string);
      store('driverbook_language', databaseLanguage);
    }
  }, [isFetching, databaseLanguage, storedLanguage]); // eslint-disable-line react-hooks/exhaustive-deps
};
