import { Box } from '@mui/material';
import { ComponentType, useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Form, InnerMobileHeader, SelectBox, StyledButton, TextInput } from 'components';
import { FeedbackFormValues, useFeedbackMutation } from 'services';
import { getTKey, yupStringValidator } from 'utils';

const tKey = getTKey('settings_feedback');

const feedbackCategories = [
  { value: 'bug-report', label: 'settings_feedback_option_bug_report' },
  { value: 'improvement', label: 'settings_feedback_option_improvement' },
  { value: 'business-enquiry', label: 'settings_feedback_option_business' },
  { value: 'other', label: 'settings_feedback_option_other' },
];

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    category: yupStringValidator(t),
    text: yupStringValidator(t),
  });

const defaultValues = {
  category: 'bug-report',
  text: '',
};

export const FeedbackTab: ComponentType = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const mutation = useFeedbackMutation();

  const handleSubmit = (data: FeedbackFormValues) => {
    mutation.mutate(data, {
      onSuccess() {
        history.push('/');
        toast(t(tKey('success_message')));
      },
    });
  };

  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_feedback', { method: 'visited_feedback' });
  }, []);

  return (
    <>
      <InnerMobileHeader title={t(tKey('title'))} />
      <Form onSubmit={handleSubmit} schema={validationSchema(t)} defaultValues={defaultValues}>
        <SelectBox name='category' label={t(tKey('category'))} options={feedbackCategories} optionsWithTranslations />
        <TextInput name='text' label={t(tKey('text'))} textarea />
        <Box textAlign='center' mt={4} mb={4}>
          <StyledButton label={t(tKey('submit'))} type='submit' />
        </Box>
      </Form>
    </>
  );
};
