import { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';

import { CommonLayout, PageLoader } from 'components';
import { EmptyGarage } from 'components/Stories/EmptyGarage';
import { useInitialSettings } from 'lib';
import { useCarsQuery } from 'services';

export const RecordsRootPage: ComponentType = () => {
  useInitialSettings();
  const { data, isLoading } = useCarsQuery();

  return (
    <CommonLayout>
      {isLoading ? (
        <PageLoader />
      ) : data && data?.length > 0 ? (
        <Redirect to={`/records/${data[0].id}`} />
      ) : (
        <EmptyGarage />
      )}
    </CommonLayout>
  );
};
