import { Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CommonLayout, InnerMobileHeader, PageLoader } from 'components';
import { useCarQuery, useUpdateCarMutation } from 'services';
import { Car } from 'types';
import { getTKey } from 'utils';

import { useClasses } from 'utils/hooks/useClasses';
import { CarForm } from './components/CarForm';
import { useCarMakesData } from './components/useCarMakesData';

const tKey = getTKey('car_form');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  container: {
    maxWidth: 800,
    margin: spacing(0, 'auto'),
  },
});

export const EditCarPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();
  const { carId } = useParams<{ carId: string }>();
  const mutation = useUpdateCarMutation();
  const { data, isLoading } = useCarQuery(carId);
  const { makes, makesLoading } = useCarMakesData();

  const loading = isLoading || makesLoading;

  const onSubmit = (formData: any) => {
    mutation.mutate(
      { id: carId, formData },
      {
        onSuccess(res) {
          toast(t(tKey('car_updated')));
          history.push('/cars');
        },
        onError(error: any, variables, context) {
          if (error?.response?.data?.message === 'car_updates_banned') {
            toast(t(tKey('car_banned')));
          }
        },
      }
    );
  };

  return (
    <CommonLayout>
      <div className={classes.container}>
        <InnerMobileHeader title={t(tKey('edit_car'))} />
        {loading ? (
          <PageLoader />
        ) : makes ? (
          <CarForm makes={makes} onSubmit={onSubmit} defaultValues={data as Car} editMode car={data as Car}/>
        ) : (
          <div>{t('__common__error')}</div>
        )}
      </div>
    </CommonLayout>
  );
};
