import { CommonLayout, PageLoader } from 'components';
import { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import { useCarsQuery } from 'services';
import { EmptyGarage } from 'components/Stories/EmptyGarage';

export const RemindersRootPage: ComponentType = () => {
  const { data, isLoading } = useCarsQuery();
  return (
    <CommonLayout>
      {isLoading ? (
        <PageLoader />
      ) : data && data?.length > 0 ? (
        <Redirect to={`/reminders/${data[0].id}`} />
      ) : (
        <EmptyGarage />
      )}
    </CommonLayout>
  );
};
