import { ComponentType } from 'react';

import { Fab, Theme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Car } from 'types';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
    padding: spacing(2, 1.5, 2, 2.5),
    borderRadius: spacing(0.5),
    backgroundColor: '#4B01D0',

    [breakpoints.up('md')]: {
      marginBottom: spacing(3),
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
  },
  make: {
    textTransform: 'capitalize',
  },
  dot: {
    fontSize: 16,
    color: palette.additionalPrimary['200'],
  },
  addBtn: {
    minWidth: 56,
  }
});

export const AssistedRecordsCarItem: ComponentType<{ car: Car; identifier: string; executeScroll: () => void }> = ({
  car,
  identifier,
  executeScroll,
}) => {
  const classes = useClasses(styles);
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.title}>
          <span className={classes.make}>{car.make}</span> {car.model}
        </div>
        <div className={classes.subtitle}>
          {car.year} <span className={classes.dot}>·</span> {car.mileage} {car.mileageMetric}{' '}
          <span className={classes.dot}>·</span> {car.plate}
        </div>
      </div>
      <Fab
        color='secondary'
        aria-label='Add assisted record'
        onClick={() => {
          history.push(`/assisted/${identifier}/${car.id}/new`);
          executeScroll();
        }}
        className={classes.addBtn}
      >
        <Add />
      </Fab>
    </div>
  );
};
