import { ArrowBack } from '@mui/icons-material';
import { IconButton, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  backButton: {
    width: 44,
    height: 44,
  },
  backButtonIcon: {
    color: palette.text.hint,
  },
  errorMessage: {
    padding: spacing(2),
    textAlign: 'center',
  },
});
export const NotFoundUser: ComponentType<{ error: any }> = (error) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useClasses(styles);
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <IconButton
          onClick={() => {
            history.go(-1);
          }}
          className={classes.backButton}
        >
          <ArrowBack className={classes.backButtonIcon} />
        </IconButton>
      </div>
      <div className={classes.errorMessage}>{t('user_error_not_found')}</div>
    </div>
  );
};
