import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { useLocationQuery } from 'lib';
import { useFilteredPublicRecords } from 'lib/useFilteredPublicRecords ';
import { useEffect, useState } from 'react';
import { usePublicDashboardCarQuery } from 'services';
import { Car, Record } from 'types';

type usePublicDashboardDetailsData = () => {
  records: Record[] | undefined;
  car: Car | undefined;
  carLoading: boolean;
  chips: any;
  month: any;
};
export const usePublicDashboardDetails: usePublicDashboardDetailsData = () => {
  const date = dayjs();
  const params = useLocationQuery();
  const { identifier } = useParams<{ identifier: string }>();
  const year = Number(params.get('year')) || 2022;
  const month = Number(params.get('month')) || Number(date.month());
  const category = params.get('category') || '';
  const paramsArray = [year, month, category];
  const { data, isLoading: carLoading } = usePublicDashboardCarQuery(identifier);

  const { filteredRecords } = useFilteredPublicRecords(
    { year, month, category },
    { data: data?.records, isFetching: carLoading }
  );
  const [records, setRecords] = useState<Record[]>();

  useEffect(() => {
    if (!carLoading) {
      setRecords(filteredRecords);
    }
  }, [filteredRecords, carLoading]);

  return {
    records,
    car: data?.car,
    carLoading,
    chips: paramsArray,
    month
  };
};
