import { ArrowForward, Delete } from '@mui/icons-material';
import { Chip, IconButton, Theme } from '@mui/material';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Currency, Record, Volume } from 'types';
import { currencySymbol, getTKey, volumeMetric } from 'utils';

import { useClasses } from 'utils/hooks/useClasses';
import { useAssistedDraftItem } from '../hooks/useAssistedDraftItem';

const tKey = getTKey('record_item');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    marginBottom: spacing(2),
    padding: spacing(2, 1.5, 2, 2.5),
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: `1px solid rgba(255, 255, 255, 0.08)`,
    opacity: 0.6,
  },
  isActive: {
    backgroundColor: 'rgba(187, 134, 252, 0.1)',
    borderColor: palette.primary.main,
    opacity: 1,
  },
  title: {
    marginBottom: spacing(1),
    fontSize: 16,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  cost: {
    marginRight: spacing(1),
    fontSize: 24,
    fontWeight: 600,
  },
  chip: {
    backgroundColor: 'rgba(255, 255, 255, 0.38)',
    color: palette.text.primary,
    textTransform: 'capitalize',
  },
  deleteBtn: {
    marginLeft: 'auto',
    color: 'rgba(255, 255, 255, 0.6)',
  },
});

interface AssistedDraftItemProps {
  data: Record;
  carId: string;
  currency: string;
  volume: Volume;
  onDraftDelete: (id: string) => void;
}

export const AssistedDraftItem: ComponentType<AssistedDraftItemProps> = ({
  data,
  carId,
  currency,
  volume,
  onDraftDelete,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();
  const { isActive, identifier } = useAssistedDraftItem(data);

  const fuelCategoryUnit: { [category: string]: Volume | 'kWh' } = {
    refueling: volume,
    recharging: 'kWh',
  };
  const fuelCategory = (category: string) => category === 'refueling' || category === 'recharging';

  const getUnit = (plural?: boolean) =>
    t(tKey(volumeMetric[fuelCategoryUnit[data.category] as Volume] + (plural ? 's' : '')));

  return (
    <div className={clsx(classes.root, isActive && classes.isActive)}>
      <div className={classes.title}>
        {fuelCategory(data.category) && `${getUnit(true)}: `}
        {data.title}
      </div>
      <div className={classes.info}>
        <div className={classes.cost}>
          {data.cost}
          {currencySymbol[currency as Currency]}
        </div>
        <Chip label={t(`__record_category__${data.category}`)} className={classes.chip} />
        <div className={classes.deleteBtn}>
          {isActive ? (
            <IconButton color='inherit' onClick={() => onDraftDelete(data.id)} size='large'>
              <Delete />
            </IconButton>
          ) : (
            <IconButton
              color='inherit'
              onClick={() => history.push(`/assisted/${identifier}/${carId}/edit/${data.id}`)}
              size='large'
            >
              <ArrowForward />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};
