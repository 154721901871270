import { ArrowBack } from '@mui/icons-material';
import { Button, Theme, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { CommonLayout, ConfirmationDialog, PageLoader, TransferSuccessBox } from 'components';
import { useCarQuery } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { TransferCarForm } from './components/CarTransferForm';
import { useCarTransferData } from './components/useCarTransferData';
import { useCarTransfer } from './useCarTransfer';
import { Capacitor } from '@capacitor/core';
import store from 'store2';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  container: {
    maxWidth: 800,
    margin: spacing(0, 'auto'),
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: spacing(0, 0, 3),
    fontSize: 25,
    fontWeight: 600,

    [breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  btnBack: {
    justifyContent: 'flex-start',
    minWidth: 48,
    color: 'rgba(255, 255, 255, 0.87)',
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  more: {
    color: palette.secondary.main,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontSize: 24,
    marginTop: spacing(4),
    fontWeight: 600,
    textAlign: 'center',

    [breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
});

const tKey = getTKey('transfer_car');

export const TransferCarPage: ComponentType = () => {
  const { t } = useTranslation();
  const { carId } = useParams<{ carId: string }>();
  const { data: car } = useCarQuery(carId);
  const classes = useClasses(styles);
  const history = useHistory();
  const { count, countLoading } = useCarTransferData();

  const {
    idToTransfer,
    transferSuccess,
    newOwnerEmail,
    errorMessage,
    transferInProgress,
    handleOnTransfer,
    handleClose,
    handleConfirmTransfer,
  } = useCarTransfer();

  const [ownerEmail, setOwnerEmail] = useState<string>('');
  const carName = `${capitalize(car?.make)} ${capitalize(car?.model)}`;

  useEffect(() => {
    if (newOwnerEmail) {
      setOwnerEmail(newOwnerEmail);
    }
  }, [newOwnerEmail]);

  return (
    <CommonLayout>
      {transferInProgress ? <PageLoader /> : null}
      <ConfirmationDialog
        light
        title={t(tKey('dialog_title'))}
        description={`${t(tKey('dialog_transfer'))} ${carName}. ${t(tKey('dialog_warning'))}`}
        confirmText={t(tKey('dialog_confirm'))}
        open={Boolean(idToTransfer)}
        onConfirm={handleConfirmTransfer}
        onClose={handleClose}
      />
      {transferSuccess ? (
        <TransferSuccessBox carName={carName} email={ownerEmail} />
      ) : (
        <div className={classes.container}>
          <Typography>
            <span className={classes.heading}>
              {/* @ts-ignore */}
              <Button size='small' className={classes.btnBack} onClick={history.goBack}>
                <ArrowBack width={16} height={16} />
              </Button>
              {t(tKey('title'), { carName })}
            </span>
          </Typography>
          <Typography variant='body1'>{t(tKey('description'))}</Typography>
          <TransferCarForm onSubmit={handleOnTransfer} />
          {/* @ts-ignore */}
          {countLoading ? (
            <PageLoader />
          ) : (
            <span className={classes.count}>{t(tKey('remaining'), { count: Number(count) })}</span>
          )}
          {!Capacitor.isNativePlatform() && (store.get('googleAccessToken') || store.get('appleAccessToken')) ? (
            <span className={classes.error}>{t(tKey('error_web'))}</span>
          ) : errorMessage ? (
            <span className={classes.error}>{errorMessage}</span>
          ) : null}
        </div>
      )}
    </CommonLayout>
  );
};
