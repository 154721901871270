import { Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { LeanLayout, StyledButton } from 'components';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('confirm_email');

const styles = ({ spacing }: Theme) => ({
  title: {
    marginBottom: spacing(4),
    fontWeight: 600,
  },
  buttonWrapper: {
    marginTop: spacing(4),
    textAlign: 'center',
  },
});

export const ConfirmEmailPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  return (
    <LeanLayout>
      {token ? (
        <>
          <Typography variant='h4' align='center' className={classes.title}>
            {t(tKey('title'))}
          </Typography>
          <Typography variant='subtitle1' align='center'>
            {t(tKey('description'))}
          </Typography>
          <div className={classes.buttonWrapper}>
            <StyledButton label={t(tKey('button'))} onClick={() => history.push('/')} />
          </div>
        </>
      ) : (
        <Redirect to='/' />
      )}
    </LeanLayout>
  );
};
