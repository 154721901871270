import { Grid } from '@mui/material';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CommonLayout, PageLoader } from 'components';
import { useRecordQuery, useUpdateRecordMutation } from 'services';
import { RecordForm, RecordsCarsList } from './components';

export const EditRecordPage: ComponentType = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { carId, recordId } = useParams<{ carId: string; recordId: string }>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const mutation = useUpdateRecordMutation();
  const { data, isFetching, refetch } = useRecordQuery({ recordId, carId });

  useEffect(() => {
    refetch();
  }, [data, refetch]);

  const onSubmit = (formData: any) => {
    setIsSubmitting(true);

    mutation.mutate(
      { carId, id: recordId, formData },
      {
        onSuccess: () => {
          setIsSubmitting(false);
          toast(t('record_updated'));
          history.push(`/records/${carId}`);
        },
        onError: () => {
          console.warn('Something went wrong');
          setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <CommonLayout>
      {isFetching ? (
        <PageLoader />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
            <RecordsCarsList />
          </Grid>

          <Grid item xs={12} md={8}>
            <RecordForm onSubmit={onSubmit} defaultValues={data} isSubmitting={isSubmitting} editMode />
          </Grid>
        </Grid>
      )}
    </CommonLayout>
  );
};
