import { ArrowBack, Close } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { enUS, esES, ukUA } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { InnerMobileHeader, PageLoader, StyledButton } from 'components';
import { recordCategories } from 'features/records/components/record-categories';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCarQuery } from 'services';
import store from 'store2';
import { theme } from 'theme';
import { RemindEveryDateType, remindEveryDateTypes } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { useCreateReminder } from '../hooks/useCreateReminder';
import { AddPartReminder } from './AddPartReminder';
import { CategorySelectWithDialog } from './CategorySelectWithDialog';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/uk';
import 'dayjs/locale/es';

const locales: Record<string, any> = {
  en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  uk: ukUA.components.MuiLocalizationProvider.defaultProps.localeText,
  es: esES.components.MuiLocalizationProvider.defaultProps.localeText,
};

const tKey = getTKey('reminder_form');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  partHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(0, 0.5, 0, 0.25),
  },
  datePicker: {
    width: '100%',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& label.Mui-focused': {
      color: '#bb86fc',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#bb86fc',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      '& fieldset': {
        borderColor: '',
        width: '100%',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bb86fc',
      },
    },
    '& .MuiButtonBase-root': {
      color: '#FFFFFF99',
    },
    '& .MuiFormLabel-root': {
      maxWidth: 'calc(100% - 42px)',
    },
  },
  mileageInput: {
    '& .MuiInputAdornment-root': {
      color: '#FFFFFF73',
    },
  },
});

interface ReminderFormProp {
  edit?: boolean;
}

export const ReminderForm: ComponentType<ReminderFormProp> = ({ edit }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [language] = useState(i18n.language === 'en' ? 'en-gb' : i18n.language);
  dayjs.locale('en-gb');
  dayjs.locale('uk');
  dayjs.locale('es');
  const history = useHistory();
  const onCloseReminderForm = () => {
    history.go(-1);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { carId } = useParams<{ carId: string; reminderId: string }>();
  const location = useLocation();
  const isEditPath = location.pathname.includes('/edit/');
  const isCopyPath = location.pathname.endsWith('/copy');
  const localSettings = store('driverbook_settings');
  const localeDateFormat = localSettings.date_format.toUpperCase();
  const {
    enableNotes,
    enableCategory,
    enableDate,
    enableLink,
    enableMileage,
    toggleNotes,
    toggleLink,
    toggleDate,
    toggleMileage,
    toggleCategory,
    title,
    onChangeTitle,
    onChangeNotes,
    onChangeLink,
    onChangeDateDate,
    onChangeDateTime,
    onChangeDateRepeat,
    onChangeCategories,
    onChangeMileageWhen,
    onChangeMileageBefore,
    onChangeMileageEvery,
    notes,
    link,
    date,
    mileage,
    categories,
    pasteLink,
    submit,
    validTitle,
    validNotes,
    validLink,
    validDate,
    validTime,
    validMileageWhen,
    validMileageBefore,
    validCategory,
  } = useCreateReminder();

  const getTitle = () => {
    if (isEditPath) {
      return 'title_edit';
    } else if (isCopyPath) {
      return 'title_copy';
    } else return 'title';
  };

  const { data: carData, isFetching } = useCarQuery(carId);

  return (
    <Box className={classes.root}>
      {!isFetching && carData ? (
        <>
          <InnerMobileHeader
            title={t(tKey(getTitle())) + ` ${carData.make} ${carData.model}`}
            leftIcon={<ArrowBack width={16} height={16} />}
            showBack
            onLeftClick={onCloseReminderForm}
          />
          <TextField
            label={t(tKey('name_label'))}
            fullWidth
            onChange={(event) => onChangeTitle(event.target.value)}
            value={title}
            inputProps={{ maxLength: 50 }}
            error={!validTitle}
            helperText={!validTitle ? t('__validation__required') : ''}
          />
          {enableNotes && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('note_label'))}</Typography>
                <Close onClick={toggleNotes} />
              </Grid>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <TextField
                  fullWidth
                  onChange={(event) => onChangeNotes(event.target.value)}
                  value={notes}
                  inputProps={{ maxLength: 50 }}
                  error={!validNotes}
                  helperText={!validNotes ? t('__validation__required') : ''}
                />
              </FormControl>
            </Grid>
          )}

          {enableLink && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('link_label'))}</Typography>
                <Close onClick={toggleLink} />
              </Grid>
              <TextField
                value={link}
                fullWidth
                onChange={(event) => onChangeLink(event.target.value)}
                error={!validLink}
                helperText={!validLink ? t('__validation__required') : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Button onClick={pasteLink}>{t(tKey('button_paste'))}</Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {enableDate && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('date_reminder_label'))}</Typography>
                <Close onClick={toggleDate} />
              </Grid>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={language}
                localeText={locales[i18n.language]}
              >
                <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                  {!fullScreen ? (
                    <DesktopDatePicker
                      className={classes.datePicker}
                      sx={{ display: { sm: 'block', xs: 'none' } }}
                      label={t(tKey('date_label'))}
                      format={localeDateFormat}
                      onChange={(newValue) => {
                        onChangeDateDate(newValue);
                      }}
                      disablePast
                      value={date?.date || dayjs()}
                      slots={{ openPickerIcon: CalendarMonthIcon }}
                      slotProps={{
                        textField: {
                          error: !validDate,
                          helperText: !validDate ? t('__validation__required') : '',
                        },
                      }}
                    />
                  ) : (
                    <MobileDatePicker
                      className={classes.datePicker}
                      sx={{ display: { sm: 'block', xs: 'block' } }}
                      label={t(tKey('date_label'))}
                      format={localeDateFormat}
                      disablePast
                      value={date?.date || dayjs()}
                      onChange={(newValue) => {
                        onChangeDateDate(newValue);
                      }}
                      localeText={{ toolbarTitle: t('__date_picker__toolbar_title') }}
                      slotProps={{
                        textField: {
                          error: !validDate,
                          helperText: !validDate ? t('__validation__required') : '',
                        },
                      }}
                    />
                  )}
                </FormControl>
                <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                  <TimeField
                    label={t(tKey('time_label'))}
                    format='HH:mm'
                    value={date?.time}
                    onChange={(newValue) => {
                      onChangeDateTime(newValue);
                    }}
                    slotProps={{
                      textField: {
                        error: !validTime,
                        helperText: !validTime ? t('__validation__required') : '',
                      },
                    }}
                  />
                </FormControl>
              </LocalizationProvider>

              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <InputLabel id='date_repeat_label'>{t(tKey('repeat_label'))}</InputLabel>
                <Select
                  labelId='date_repeat_label'
                  label={t(tKey('repeat_label'))}
                  fullWidth
                  defaultValue='do_not_repeat'
                  value={date?.repeat}
                  onChange={(newValue) => {
                    onChangeDateRepeat(newValue.target.value as RemindEveryDateType);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: '#121212',
                      },
                    },
                  }}
                >
                  {remindEveryDateTypes.map((type) => {
                    return (
                      <MenuItem key={type} value={type}>
                        {t(tKey('remind_type_' + type))}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {enableMileage && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('mileage_reminder_label'))}</Typography>
                <Close onClick={toggleMileage} />
              </Grid>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <TextField
                  className={classes.mileageInput}
                  fullWidth
                  type='number'
                  label={t(tKey('remind_when_label'))}
                  value={mileage?.when}
                  onChange={(event) => {
                    onChangeMileageWhen(event.target.value);
                  }}
                  error={!validMileageWhen}
                  helperText={!validMileageWhen ? t('__validation__required') : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography>KM</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <TextField
                  className={classes.mileageInput}
                  fullWidth
                  type='number'
                  label={t(tKey('remind_before_label'))}
                  value={mileage?.before}
                  error={!validMileageBefore}
                  helperText={!validMileageWhen ? t('__validation__required') : ''}
                  onChange={(event) => {
                    onChangeMileageBefore(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography>KM</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <TextField
                  className={classes.mileageInput}
                  fullWidth
                  type='number'
                  label={t(tKey('repeat_every_label'))}
                  value={mileage?.every}
                  onChange={(event) => {
                    onChangeMileageEvery(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography>KM</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          )}
          {enableCategory && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('category_label'))}</Typography>
                <Close onClick={toggleCategory} />
              </Grid>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <CategorySelectWithDialog
                  options={recordCategories}
                  values={categories}
                  onChange={onChangeCategories}
                  error={!validCategory}
                />
              </FormControl>
            </Grid>
          )}

          <AddPartReminder
            addNotes={toggleNotes}
            addDate={toggleDate}
            addLink={toggleLink}
            addMileage={toggleMileage}
            addCategory={toggleCategory}
            enableNotes={enableNotes}
            enableCategory={enableCategory}
            enableDate={enableDate}
            enableLink={enableLink}
            enableMileage={enableMileage}
          />
          <Box display='flex' justifyContent='center'>
            <StyledButton
              label={isEditPath ? t('__common__save') : t(tKey('button_create'))}
              type='submit'
              onClick={submit}
            />
          </Box>
        </>
      ) : (
        <PageLoader />
      )}
    </Box>
  );
};
