import { Box, Button, Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { Capacitor } from '@capacitor/core';
import { LeanLayout } from 'components';
import { useHistory } from 'react-router-dom';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('signup_success');

const styles = ({ spacing }: Theme) => ({
  title: {
    marginBottom: spacing(4),
    fontWeight: 600,
  },
});

export const RegistrationSuccessPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const isNativePlatform = Capacitor.isNativePlatform();
  const history = useHistory();

  return (
    <LeanLayout>
      <Typography variant='h4' align='center' className={classes.title}>
        {t(tKey('title'))}
      </Typography>
      <Typography variant='subtitle1' align='center'>
        {t(tKey('description'))}
      </Typography>

      {isNativePlatform && (
        <>
          <Box marginTop={2}>
            <Typography variant='subtitle1' align='center'>
              {t(tKey('native_app_description'))}{' '}
            </Typography>

            <Typography align='center' marginTop={1.5}>
              <Button size='large' onClick={() => history.replace('/login')}>
                {t('signin_submit')}
              </Button>
            </Typography>
          </Box>
        </>
      )}
    </LeanLayout>
  );
};
