import { Description, SvgIconComponent, Widgets } from '@mui/icons-material';
import { BottomNavigationAction, Theme, Toolbar } from '@mui/material';
import { ImageLogo } from 'components/ImageLogo/ImageLogo';
import { PublicBookInstructionDropdown } from 'components/PublicBookInstruction/PublicBookInstruction';
import { Wrapper } from 'components/Wrapper/Wrapper';
import { ComponentType } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';

interface MobileNavigationItem {
  to: string;
  icon: SvgIconComponent;
}
const mobileNavigationItems: MobileNavigationItem[] = [
  { to: `book/`, icon: Description },
  { to: `book/dashboard/`, icon: Widgets },
];

const styles = ({ palette, spacing }: Theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(1.5, 0),
  },
  toolbarItems: {
    display: 'flex',
  },
  item: {
    flex: 'initial',
    minWidth: 0,
    padding: `${spacing(1, 1.5)} !important`,

    '& svg': {
      fill: 'rgba(255, 255, 255, 0.6)',
    },

    '& .MuiBottomNavigationAction-label': {
      display: 'none',
    },
    width: 40,
  },
  itemSelected: {
    '& svg': {
      fill: palette.primary.main,
    },
  },
  reminder: {
    position: 'relative',
    width: 50,
  },
});

export const PublicMobileNavigation: ComponentType = () => {
  const classes = useClasses(styles);

  const { identifier } = useParams<{ identifier: string }>();
  return (
    <>
      <div className={classes.root}>
        <Wrapper>
          <Toolbar disableGutters classes={{ root: classes.toolbar }}>
            <ImageLogo />
            <div className={classes.toolbarItems}>
              <div className={classes.reminder}>
                <PublicBookInstructionDropdown />
              </div>
              {mobileNavigationItems.map(({ to, icon: Icon }) => (
                <BottomNavigationAction
                  key={to}
                  component={NavLink}
                  to={`/${to}${identifier}`}
                  value={to}
                  icon={<Icon />}
                  classes={{ iconOnly: classes.item }}
                  activeClassName={classes.itemSelected}
                />
              ))}
            </div>
          </Toolbar>
        </Wrapper>
      </div>
    </>
  );
};
