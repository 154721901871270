import { Box, Button, FormHelperText, Theme } from '@mui/material';
import { Form, FormErrorsCleaner, PageLoader, StyledButton, TextInput } from 'components';
// import { User } from 'firebase/auth';
import * as H from 'history';
import { capitalize } from 'lodash';
import { ComponentType, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useUpdateUsernameMutation } from 'services/auth/useUpdateUsername';
import store from 'store2';
import { getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';
const tKey = getTKey('signin');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  inputStyle: {
    WebkitBoxShadow: '0 0 0 1000px #201C24 inset',
    WebkitTextFillColor: 'white',
    [breakpoints.down('sm')]: {
      WebkitBoxShadow: '0 0 0 1000px #121212 inset',
    },
  },
  formError: {
    textAlign: 'center',
    marginTop: spacing(2),
  },
  registerBtn: {
    margin: '0 auto',
    marginTop: spacing(2),
    textAlign: 'center',
    display: 'block'
  }
});

interface UsernameFormProps {
  user: any;
  isSubmitting?: boolean;
  isGoogle?: boolean;
  isApple?: boolean;
}

const defaultValues = {
  username: '',
};

const validationSchema = (t: TFunction): yup.AnyObjectSchema => {
  return yup.object().shape({
    username: yupStringValidator(t)
      .min(3, t(tKey('username_hint')))
      .matches(/^(?=.*[a-z].*)[a-z0-9_.-]+$/, { message: t('__validation__wrong_characters') }),
  });
};

export const SocialUsernameForm: ComponentType<UsernameFormProps> = ({ user, isSubmitting, isApple, isGoogle }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();
  const usernameMutation = useUpdateUsernameMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const { state } = useLocation<{ from: H.Location<any> }>();
  const { from } = state || { from: { pathname: '/' } };
  const [usernameValue, setUsernameValue] = useState<string>('');

  const handleSubmit = async (data: { username: string }) => {
    const socialId =
      (user?.additionalUserInfo?.profile?.id as string) ||
      (user?.additionalUserInfo?.profile?.sub as string) ||
      user?.user?.providerData[0].uid;

    const mutateData: any = {
      username: data.username,
    };
    if (isApple) {
      mutateData.apple_id = socialId;
    }
    if (isGoogle) {
      mutateData.google_id = socialId;
    }

    usernameMutation.mutate(mutateData, {
      onSuccess(data) {
        if (!data?.error) {
          store(data);
          store('driverbook_google_user', true);
          if (['signin', 'singup', 'complete-signup'].includes(from.pathname)) {
            history.replace('/stories/recent');
          } else {
            if (from && from.pathname) {
              history.replace(from);
            } else {
              history.replace('/stories/recent');
            }
          }
        } else {
          setFormError(capitalize(t(tKey(data?.error ?? ''))));
          setLoading(false);
        }
      },
    });
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsernameValue(event.target.value.toLowerCase());
  };

  const logout = () => {
    history.replace('/settings/logout');
  }

  return loading ? (
    <PageLoader />
  ) : (
    <Form defaultValues={defaultValues} schema={validationSchema(t)} onSubmit={handleSubmit}>
      <FormErrorsCleaner />
      <TextInput
        customHelperText={t(tKey('username_hint'))}
        name='username'
        label={t(tKey('create_username'))}
        inputProps={{ autoCapitalize: 'none', className: classes.inputStyle, onChange: handleUsernameChange }}
        textFieldProps={{ value: usernameValue }}
      />

      <Box textAlign='center' mt={4} mb={4}>
        <StyledButton label={t(tKey('continue'))} type='submit' loading={loading} />
        <Button
        className={classes.registerBtn}
        onClick={logout}
        >
        {t('settings_menu_logout')}
        </Button>

      </Box>

      {formError && (
        <FormHelperText classes={{ root: classes.formError }} error>
          {t(formError)}
        </FormHelperText>
      )}
    </Form>
  );
};
