import React, { ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isAppId, isLoggedIn } from 'utils/api';

interface AdminRouteProps extends RouteProps {
  component: ComponentType;
}

export const AdminRoute: ComponentType<AdminRouteProps> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props: RouteProps) => {
      return isAppId() ? (
        <Component {...props} />
      ) : isLoggedIn() 
          ? (<Redirect to={{ pathname: '/stories', state: { from: props.location } }} />) 
          : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      );
    }}
  />
);
