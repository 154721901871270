import { Grid, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageLoader, PublicRecordsLayout } from 'components';
import { usePublicCarCurrencyQuery } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { DashboardChart, DashboardFilters, DashboardStatCard, DashboardTile, EmptyRecords } from './components';
import { usePublicDashboardPage } from './usePublicDashboardPage';

const tKey = getTKey('dashboard');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  note: {
    margin: spacing(6, 0, 2),
    textAlign: 'left',
    fontSize: 12,
    opacity: 0.6,
  },
  innerWrapper: {
    margin: spacing(2, 0, 10),
    [breakpoints.up('md')]: {
      margin: spacing(6, 0),
    },
  },
});

export const PublicDashboardPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const {
    carLoading: loading,
    showRecordsCharts,
    car,
    records,
    allYears,
    allCategories,
    filters,
    handleFiltersChange,
    expensesByMonth,
    mileageByMonth,
    averageMonthlyExpenses,
    averageMonthlyMileage,
    totalExpenses,
    totalMileage,
    spendingByCategory,
    spendingByFrequency,
  } = usePublicDashboardPage();
  const currentCarMileageMetric = car?.mileageMetric;
  const { identifier } = useParams<{ identifier: string }>();
  const { data: currency, isLoading: currencyLoading } = usePublicCarCurrencyQuery(identifier);

  return (
    <PublicRecordsLayout>
      <div className={classes.innerWrapper}>
        {(loading || currencyLoading) && <PageLoader />}
        {records && records.length > 0 && (
          <DashboardFilters
            years={allYears}
            categories={allCategories}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            publicChart
          />
        )}
        {showRecordsCharts && !currencyLoading ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <DashboardChart
                  type='expenses'
                  totalValue={totalExpenses}
                  data={expensesByMonth}
                  mileageMetric={currentCarMileageMetric}
                  filters={filters}
                  currency={currency}
                  publicChart
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DashboardTile
                  heading={`${t(tKey('average_expenses'))} (${currency})`}
                  count={averageMonthlyExpenses}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9} sx={{ display: { xs: 'none' } }}>
                <DashboardChart
                  type='mileage'
                  totalValue={totalMileage}
                  data={mileageByMonth}
                  mileageMetric={currentCarMileageMetric}
                  currency={currency}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ display: { xs: 'none' } }}>
                <DashboardTile heading={t(tKey('average_mileage'))} count={averageMonthlyMileage} />
              </Grid>
            </Grid>

            <div className={classes.note}>{t(tKey('statistics_note'))}</div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DashboardStatCard heading={t(tKey('spending_category'))} data={spendingByCategory} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DashboardStatCard
                  color='secondary'
                  heading={t(tKey('spending_frequency'))}
                  data={spendingByFrequency}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <EmptyRecords title={t(tKey('no_records'))} />
        )}
      </div>
    </PublicRecordsLayout>
  );
};
