import { Grid } from '@mui/material';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CommonLayout } from 'components';
import { useCreateRecordMutation, useRecordForCopyQuery } from 'services';

import { RecordForm } from './components/RecordForm';
import { RecordsCarsList } from './components/RecordsCarsList';

export const CreateRecordPage: ComponentType = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { carId, recordId } = useParams<{ carId: string; recordId?: string }>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [recordData, setRecordData] = useState<any>(undefined);
  const mutation = useCreateRecordMutation();

  const copying = window.location.pathname.endsWith('/copy');
  const { data: dataCopy, isLoading } = useRecordForCopyQuery({ recordId, carId });

  useEffect(() => {
    if (dataCopy !== recordData) {
      setRecordData(dataCopy);
    }
  }, [recordData, dataCopy]);

  const onSubmit = (data: any) => {
    setIsSubmitting(true);
    //@ts-ignore
    gtag?.('event', 'record_added', { category: data.category });
    mutation.mutate(
      { carId, formData: data },
      {
        onSuccess: () => {
          setIsSubmitting(false);
          toast(t('record_added'));
          history.push(`/records/${carId}`);
        },
        onError: () => {
          console.warn('Something went wrong');
          setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <CommonLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
          <RecordsCarsList />
        </Grid>
        <Grid item xs={12} md={8}>
          {copying ? (
            <>
              {!isLoading && recordData !== undefined && (
                <RecordForm
                  onSubmit={onSubmit}
                  isSubmitting={isSubmitting}
                  defaultValues={copying ? recordData : undefined}
                  copying={copying}
                  editMode
                />
              )}
            </>
          ) : (
            <RecordForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
          )}
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
