import { ArrowBack } from '@mui/icons-material';
import { IconButton, Tab, Tabs, Theme } from '@mui/material';
import { AutoLoadContent, FollowButton, SpinFeedLoaderMini } from 'components';
import { EmptyStories } from 'features/stories-feed/components/EmptyStories';
import { StoriesList } from 'features/stories-feed/components/StoriesList';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';
import { PublicUserPageContext } from '../PublicUserPageContext';
import { useUserData } from '../hooks/useUserData';
import { UserProfileCard } from './UserProfileCard';
import { VehiclesList } from './VehicleList';
import { ProfileContext as Context } from 'store/profile/store';
import { NotFoundUser } from './NotFoundUser';
const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  root: {
    width: '50%',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.up('sm')]: {
      minWidth: 532,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  tabs: {
    marginTop: spacing(3),
    '& .MuiTab-root': {
      color: palette.text.hint,
      fontSize: 14,
      fontWeight: 600,
      padding: spacing(1.75, 1.5),
      letterSpacing: '1.25px',
      lineHeight: '16px',
      width: '50%',
    },
    '& .Mui-selected': {
      color: palette.text.primary,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#6200EE',
    },
  },
  hiddenTabs: {
    marginTop: spacing(3),
  },
  backButton: {
    width: 44,
    height: 44,
  },
  backButtonIcon: {
    color: palette.text.hint,
  },
  vehicles: {
    marginTop: spacing(0),
  },
});

export type PublicProfileTab = 'stories' | 'vehicles';

export const UserWrapper: ComponentType<{ userId: string }> = ({ userId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useClasses(styles);
  const { state } = useContext(Context);

  const {
    profile,
    isLoadingProfile,
    followers,
    isLoadingFollowers,
    vehicles,
    isLoadingVehicles,
    stories,
    isLoadingStories,
    isFetchingNextPageStories,
    hasNextPageStories,
    fetchNextPageStories,
    refetchFollowers,
    errorProfile,
  } = useUserData(userId);

  const [tab, setTab] = useState<PublicProfileTab>('stories');

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue as PublicProfileTab);
  };

  const onBack = () => {
    history.go(-1);
  };

  const { Provider } = PublicUserPageContext;

  const handleFollow = () => {
    refetchFollowers();
  };

  useEffect(() => {
    document.addEventListener('follow', handleFollow);
    return () => {
      document.removeEventListener('follow', handleFollow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      {!isLoadingProfile && !isLoadingFollowers && profile && followers && (
        <>
          <div className={classes.header}>
            <IconButton onClick={onBack} className={classes.backButton}>
              <ArrowBack className={classes.backButtonIcon} />
            </IconButton>
            {userId !== state.id && <FollowButton userId={userId} followed={profile?.already_following || false} />}
          </div>
          <UserProfileCard profile={profile} followers={followers} />
        </>
      )}
      {!isLoadingProfile &&
      profile?.profile_vehicles_display_mode !== 'hide' &&
      vehicles?.length !== 0 &&
      !errorProfile ? (
        <Tabs variant='scrollable' value={tab} onChange={handleTab} aria-label='profile tabs' className={classes.tabs}>
          <Tab LinkComponent={Link} value={'stories'} label={t('user_page_tab_title_stories')} />
          <Tab LinkComponent={Link} value={'vehicles'} label={t('user_page_tab_title_vehicles')} />
        </Tabs>
      ) : (
        <div className={classes.hiddenTabs}></div>
      )}
      {tab === 'stories' && !errorProfile && (
        <Provider value={{ isPublicUserPage: true }}>
          {stories?.pages[0]?.stories?.length !== 0 ? (
            stories?.pages.map((page, i) => {
              return (
                <div key={i}>
                  <StoriesList stories={page.stories} authorized={true} />
                </div>
              );
            })
          ) : (
            <EmptyStories onlyTitle={true} />
          )}
          <SpinFeedLoaderMini loading={isFetchingNextPageStories || isLoadingStories} />
          {hasNextPageStories && (
            <AutoLoadContent loading={isFetchingNextPageStories} onScrollToBottom={fetchNextPageStories} />
          )}
        </Provider>
      )}
      {tab === 'vehicles' && !errorProfile && !isLoadingVehicles && vehicles && (
        <VehiclesList vehicles={vehicles} className={classes.vehicles} />
      )}
      {errorProfile && <NotFoundUser error={errorProfile} />}
    </div>
  );
};
