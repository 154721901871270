import { useDeleteStoryMutation } from 'services/story/hooks/useDeleteStoryMutation';
import { StoryPayload } from '../StoriesTypes';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

type useDeleteStoryType = () => {
  deleteStory: (payload: StoryPayload) => void;
};

export const useDeleteStory: useDeleteStoryType = () => {
  const mutation = useDeleteStoryMutation();
  const history = useHistory();

  const deleteStory = (payload: StoryPayload) => {
    mutation.mutate(payload, {
      onSuccess(data, variables, context) {
        history.push('/stories');
        toast('Your story has been removed!');
      },
      onError(error, variables, context) {
        toast('Something went wrong!');
      },
    });
  };

  return {
    deleteStory,
  };
};
