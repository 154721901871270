import { Box, Button, FormHelperText, Theme, Typography } from '@mui/material';
import googleIcon from 'assets/google.png';
import * as H from 'history';
import { ComponentType, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ReCaptchaProvider, ReCaptchaV3 } from 'react-recaptcha-x';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import store from 'store2';
import * as yup from 'yup';

import {
  AppleSignInButton,
  Form,
  FormErrorsCleaner,
  LeanLayout,
  PageLoader,
  PasswordInput,
  StyledButton,
  TextInput,
} from 'components';
// import { GoogleAuthProvider, User, signInWithPopup } from 'firebase/auth';
import { FirebaseAuthentication, SignInResult } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { auth } from 'configurations/firebaseConfig';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { capitalize } from 'lodash';
import { RegisterValues, useAppleLoginMutation, useGoogleLoginMutation, useRegisterMutation } from 'services';
import { getTKey, yupPasswordValidator, yupStringValidator } from 'utils';
import { handleErrorGoogleAuth } from 'utils/google-auth';
import { useClasses } from 'utils/hooks/useClasses';
import { SocialUsernameForm } from './SocialUsernameForm';
import { firebaseNotificationsRegister } from 'utils/firebaseNotifications';

const tKey = getTKey('signup');
const recaptchaSiteKey = '6LcwpiQpAAAAABIDP3wgKgck0bPGlf3DLozAsRJ1';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  formContainer: {
    margin: spacing(0, 'auto', 4),

    [breakpoints.up('md')]: {
      maxWidth: 588,
      padding: spacing(4, 12),
      backgroundColor: 'rgba(187, 134, 252, 0.08)',
    },
  },
  email: { color: palette.additionalPrimary[200] },
  inputStyle: {
    boxShadow: '0 0 0 1000px #201C24 inset',
    WebkitBoxShadow: '0 0 0 1000px #201C24 inset',
    MozBoxShadow: '0 0 0 1000px #201C24 inset',
    WebkitAppearance: 'none',
    WebkitTextFillColor: 'white',
    textFillColor: 'white',
    [breakpoints.down('sm')]: {
      boxShadow: '0 0 0 1000px #121212 inset',
      WebkitBoxShadow: '0 0 0 1000px #121212 inset',
      MozBoxShadow: '0 0 0 1000px #121212 inset',
    },
  },
  title: {
    marginBottom: spacing(4),
    fontWeight: 600,
  },
  signInRedirect: {
    textAlign: 'center',
    marginTop: spacing(3),
  },
  btn: {
    textTransform: 'none',
  },
  formError: {
    textAlign: 'center',
    marginTop: spacing(2),
  },
  submitButtonContainer: {
    textAlign: 'center',
  },
  recaptchaContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(4, 0),
  },
  agreement: {
    paddingBottom: spacing(2),
  },
});

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    username: yupStringValidator(t)
      .min(3, t('__validation__minimum_three'))
      .matches(/^(?=.*[a-z].*)[a-z0-9_.-]+$/, { message: t('__validation__wrong_characters') }),
    email: yupStringValidator(t).email(t('__validation__email')),
    password: yupPasswordValidator(t).required(t('__validation__required')),
    repeatPassword: yupStringValidator(t).oneOf([yup.ref('password')], t('__validation__passwords_dont_match')),
  });

const defaultValues = {
  username: '',
  email: '',
  password: '',
  repeatPassword: '',
};

export const RegistrationPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const mutation = useRegisterMutation();
  const history = useHistory();
  const storedLanguage = store('driverbook_language');
  const [formError, setFormError] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [userExistsError, setUserExistsError] = useState<boolean>(false);
  const googleMutation = useGoogleLoginMutation();
  const appleMutation = useAppleLoginMutation();
  const { state } = useLocation<{ from: H.Location<any> }>();
  const { from } = state || { from: { pathname: '/' } };
  const [user, setUser] = useState<SignInResult>();
  const [usernameValue, setUsernameValue] = useState<string>('');
  const [pageLoader, setPageLoader] = useState(false);

  const submitRegistrationForm = (data: RegisterValues) => {
    const formData = {
      username: data.username,
      email: data.email,
      password: data.password,
      language: storedLanguage ? storedLanguage : 'en',
      captcha_response: captchaValue || '',
    };
    setFormError(false);
    setUserExistsError(false);

    mutation.mutate(formData, {
      onSuccess(data) {
        if (data?.message) {
          //@ts-ignore
          gtag?.('event', 'account_created', { method: 'account_created' });
          toast(t(tKey('snackbar_success_message')));
          if (Capacitor.isNativePlatform()) {
            firebaseNotificationsRegister();
          }
          history.push('/signup-success');
        } else {
          //@ts-ignore
          if (data === 'Such user exists') {
            setUserExistsError(true);
          } else {
            setFormError(true);
          }
        }
      },
    });
  };

  const v3Callback = (token: any, refreshToken: any) => {
    if (typeof token === 'string') {
      setCaptchaValue(token);
    }
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsernameValue(event.target.value.toLowerCase());
  };

  const loginWithGoogle = async () => {
    if (/Mobi/.test(navigator.userAgent) && !Capacitor.isNativePlatform()) {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      signInWithPopup(auth, provider)
        .then((result: any) => {
          const dataMutate = {
            email: result.user.email,
            google_id: result.user.providerData[0].uid,
            language: storedLanguage ? storedLanguage : 'en',
            access_token: result.user.stsTokenManager.accessToken,
          };
          setPageLoader(true);
          googleMutation.mutate(dataMutate, {
            onSuccess(data) {
              //@ts-ignore
              gtag?.('event', 'login', { method: 'google' });
              store(data);
              store('google_id', result.user.providerData[0].uid);
              if (data.username) {
                store('driverbook_google_user', true);
                history.replace(from);
              } else if (data.accessToken && result.user) {
                setUser(result);
              } else {
                toast(capitalize(t(tKey(data?.error ?? ''))));
              }
              setPageLoader(false);
            },
          });
        })
        .catch((error: any) => {
          handleErrorGoogleAuth(error);
        });
    } else {
      try {
        const result = await FirebaseAuthentication.signInWithGoogle({
          customParameters: [{ key: 'prompt', value: 'select_account' }],
        })
        const token = (await FirebaseAuthentication.getIdToken()).token;
        setPageLoader(true);
        googleMutation.mutate(
          {
            //@ts-ignore
            email: result.user.email,
            //@ts-ignore
            google_id: result?.additionalUserInfo?.profile?.id || result?.additionalUserInfo?.profile?.sub,
            language: storedLanguage ? storedLanguage : 'en',
            access_token: token ?? '',
          },
          {
            onSuccess(data) {
              //@ts-ignore
              gtag?.('event', 'login', { method: 'google' });
              store(data);
              if (data.accessToken && Capacitor.isNativePlatform()) {
                firebaseNotificationsRegister();
              }
              if (data.username) {
                store('driverbook_google_user', true);
                history.replace(from);
              } else if (data.accessToken && result.user) {
                setUser(result);
                store('google_id', result?.additionalUserInfo?.profile?.id || result?.additionalUserInfo?.profile?.sub);
              } else {
                toast(capitalize(t(tKey(data?.error ?? ''))));
              }
              setPageLoader(false);
            },
          }
        );
      } catch (error: any) {
        FirebaseAuthentication.signOut();
        handleErrorGoogleAuth(error);
        setPageLoader(false);
      }
    }
  };

  const loginWithApple = () => {
    //@ts-ignore
    gtag?.('event', 'login', { method: 'apple' });
    try {
      FirebaseAuthentication.signInWithApple({ scopes: ['email'] })
        .then((firebaseResult: any) => {
          firebaseResult.language = storedLanguage ? storedLanguage : 'en';
          if (Capacitor.isNativePlatform()) {
            firebaseResult.code = Capacitor.isNativePlatform()
              ? firebaseResult.credential.authorizationCode
              : firebaseResult.credential.accessToken;
          }
          setPageLoader(true);
          appleMutation.mutate(firebaseResult, {
            async onSuccess(data, variables, context) {
              if (data.username) {
                if (Capacitor.isNativePlatform()) {
                  firebaseNotificationsRegister();
                }
                store('driverbook_apple_user', true);
                store(data);
                store(
                  'apple_id',
                  firebaseResult.additionalUserInfo.profile.id || firebaseResult.additionalUserInfo.profile.sub
                );
                if (from.pathname === '') from.pathname = 'stories';
                history.replace(from);
              } else if (data.accessToken) {
                store(
                  'apple_id',
                  firebaseResult.additionalUserInfo.profile.id || firebaseResult.additionalUserInfo.profile.sub
                );
                store('driverbook_apple_user', true);
                store(data);
                setUser(firebaseResult);
              } else {
                toast(capitalize(t(tKey(data?.error ?? ''))));
              }
              setPageLoader(false);
            },
          });
        })
        .catch((error) => {
          console.log(error);
          handleErrorGoogleAuth(error);
          setPageLoader(false);
        });
    } catch (error) {
      FirebaseAuthentication.signOut();
      toast(t('__common__error'));
      setPageLoader(false);
    }
  };

  return (
    <LeanLayout>
      <div className={classes.formContainer}>
        <Typography variant='h4' align='center' className={classes.title}>
          {t(tKey('title'))}
        </Typography>
        {user ? (
          <SocialUsernameForm user={user} />
        ) : (
          <>
            <Box textAlign='center' mt={4} mb={4}>
              <StyledButton label={t(tKey('google_login'))} onClick={loginWithGoogle} icon={googleIcon} />
            </Box>
            <Box textAlign='center' mt={4} mb={4}>
              <AppleSignInButton label={t(tKey('apple_login'))} onClick={loginWithApple} />
            </Box>
            <Form defaultValues={defaultValues} schema={validationSchema(t)} onSubmit={submitRegistrationForm}>
              <FormErrorsCleaner />
              <TextInput
                customHelperText={t(tKey('username_hint'))}
                name='username'
                label={t(tKey('username'))}
                inputProps={{ autoCapitalize: 'none', className: classes.inputStyle, onChange: handleUsernameChange }}
                textFieldProps={{ value: usernameValue }}
                toLowerCase={true}
              />
              <TextInput
                customHelperText={t(tKey('email_hint'))}
                name='email'
                label={t(tKey('email'))}
                inputProps={{
                  autoCapitalize: 'none',
                  className: classes.inputStyle,
                }}
              />
              <PasswordInput
                helperText={t(tKey('password_hint'))}
                name='password'
                label={t(tKey('password'))}
                inputPropsClassName={classes.inputStyle}
              />
              <PasswordInput
                name='repeatPassword'
                label={t(tKey('repeat_password'))}
                inputPropsClassName={classes.inputStyle}
              />
              <ReCaptchaProvider siteKeyV3={recaptchaSiteKey} langCode='en' hideV3Badge={true}>
                <ReCaptchaV3 action='registration' callback={v3Callback} />
              </ReCaptchaProvider>
              <Typography variant='body1' className={classes.agreement}>
                {t(tKey('i_have_read_and_accept'))}{' '}
                <Link to='terms-of-use' className={classes.email}>
                  {t('terms_of_use_heading')}
                </Link>
                ,{' '}
                <Link to='privacy-policy' className={classes.email}>
                  {t('privacy_policy_heading')}
                </Link>
                {/* ,{t(tKey('and'))}
                <Link to='cookie-policy' className={classes.email}>
                  {t('cookie_policy_heading')}
                </Link> */}
              </Typography>
              <div className={classes.submitButtonContainer}>
                <StyledButton label={t(tKey('submit'))} type='submit' />
              </div>
              {formError && (
                <FormHelperText margin='dense' classes={{ root: classes.formError }} error>
                  {t('__common__error')}
                </FormHelperText>
              )}
              {userExistsError && (
                <FormHelperText margin='dense' classes={{ root: classes.formError }} error>
                  {t(tKey('user_exists_error'))}
                </FormHelperText>
              )}
              {pageLoader && <PageLoader />}
            </Form>
            <div className={classes.signInRedirect}>
              {t(tKey('have_account'))}
              <Button className={classes.btn} variant='text' color='secondary' onClick={() => history.push('/login')}>
                {t(tKey('signin'))}
              </Button>
            </div>
          </>
        )}
      </div>
    </LeanLayout>
  );
};
