import { ComponentType } from 'react';
import { CommonLayout } from 'components';
import { useParams } from 'react-router-dom';
import { StoryWrapper } from './components/StoryWrapper';
import { isLoggedIn } from 'utils';
import { CommonPublicLayout } from 'components/Layout/CommonLayoutPublic';

export const StoryPage: ComponentType = () => {
  const authorized = isLoggedIn();
  const { storyId, carId } = useParams<{ storyId: string; carId: string }>();

  return authorized ? (
    <CommonLayout hideMobileNav={true}>
      <StoryWrapper authorized={authorized} carId={carId} storyId={storyId} />
    </CommonLayout>
  ) : (
    <CommonPublicLayout hideMobileNav={true}>
      <StoryWrapper authorized={authorized} carId={carId} storyId={storyId} />
    </CommonPublicLayout>
  );
};
