import { Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import garage from '../../assets/garage.png';
import { getTKey } from 'utils';
import { StyledButton } from 'components/StyledButton/StyledButton';
import { useHistory } from 'react-router-dom';

const tKey = getTKey('empty_garage');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  garageImage: {
    maxWidth: 167,
    maxHeight: 140,
    marginTop: spacing(5),
  },
  text: {
    margin: spacing(5, 0),
  },
  title: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.18px',
    paddingBottom: spacing(2),
  },
  description: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
});

interface EmptyGarageType {
  forStory?: boolean;
}

export const EmptyGarage: ComponentType<EmptyGarageType> = ({ forStory }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <img alt='key' src={garage} className={classes.garageImage} />
      <div className={classes.text}>
        <div className={classes.title}>{t(tKey('title'))}</div>
        {forStory && <div className={classes.description}>{t(tKey('description'))}</div>}
      </div>
      <StyledButton
        label={t(tKey('add_button'))}
        onClick={() => {
          history.push('/cars/new');
        }}
      />
    </div>
  );
};
