import { IconButton, Menu, MenuItem, Theme } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowBack, MoreVert } from '@mui/icons-material';
import { ConfirmationDialog } from 'components';
import { IStory } from 'features/stories-feed/StoriesTypes';
import { useDeleteStory } from 'features/stories-feed/hooks/useDeleteStory';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  root: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing(0.75),
    [breakpoints.down('sm')]: {
      padding: spacing(0),
    },
  },
  back: {
    color: palette.text.hint,
  },
  more: {
    color: palette.text.hint,
  },
  moreMenu: {
    '& .MuiMenu-paper': {
      background: '#2E2E2E',
    },
    '& .MuiList-root': {
      minWidth: 128,
      background: '#2E2E2E',
    },
    '& .MuiButtonBase-root': {
      background: '#2E2E2E',
    },
  },
});

interface TopBarManageType {
  story: IStory;
}

export const TopBarManage: ComponentType<TopBarManageType> = ({ story }) => {
  const classes = useClasses(styles);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { deleteStory } = useDeleteStory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleEdit = () => {
    history.push(`/stories/edit/${story.car.id}/${story.id}`);
  };

  const onDelete = () => {
    deleteStory({ carId: story.car.id, storyId: story.id });
  };

  const onBack = () => {
    if (location?.state?.prev?.path === 'stories/edit' || window.history.length === 1 || !location?.state?.prevPage) {
      history.push('/stories/recent');
    } else {
      history.go(-1);
    }
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={onBack}>
        <ArrowBack className={classes.back} />
      </IconButton>
      <IconButton
        id='manage-button'
        aria-controls={open ? 'manage-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert className={classes.more} />
      </IconButton>
      <Menu
        id='manage-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.moreMenu}
        MenuListProps={{
          'aria-labelledby': 'manage-button',
        }}
      >
        <MenuItem onClick={handleEdit} disabled={!story.is_editable}>
          {t('__common__edit')}
        </MenuItem>
        <MenuItem onClick={handleDelete}>{t('__common__delete')}</MenuItem>
      </Menu>
      <ConfirmationDialog
        title={t('__common__confirm')}
        description={`${t('story_delete_confirmation_text')} ${story.title}?`}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={onDelete}
        open={openDeleteDialog}
      />
    </div>
  );
};
