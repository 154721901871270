import { ComponentType } from 'react';
import { Helmet } from 'react-helmet';

interface MetaTagsProps {
  image?: string;
  title: string;
  description?: string;
}

const MetaTags: ComponentType<MetaTagsProps> = ({ image, title, description }) => {
  return (
    <Helmet>
      {image && <meta property='og:image' content={image} />}
      <meta property='og:title' content={title} />
      <meta name='apple-itunes-app' content='app-id=6476807101' />
      <title>{title}</title>
      {description && [
        <meta name='description' key={1} content={description} />,
        <meta property='description' key={2} content={description} />,
        <meta property='og:description' key={3} content={description} />,
      ]}
    </Helmet>
  );
};

export default MetaTags;
