import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Theme, Typography } from '@mui/material';
import { ComponentType, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import store from 'store2';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ breakpoints, spacing }: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),

    [breakpoints.up('md')]: {
      marginBottom: spacing(0),
    },
  },
  btnBack: {
    justifyContent: 'flex-start',
    minWidth: 48,
    color: 'rgba(255, 255, 255, 0.87)',
  },
  title: {
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    [breakpoints.up('md')]: {
      marginBottom: spacing(2),
    },
  },
  titleText: {
    [breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
  },
});

interface InnerMobileHeaderProps {
  title?: string;
  onRightClick?: () => void;
  onLeftClick?: () => void;
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  showBack?: boolean;
  showRight?: boolean;
}

export const InnerMobileHeader: ComponentType<InnerMobileHeaderProps> = ({
  title,
  leftIcon,
  rightIcon,
  showBack,
  showRight,
  onLeftClick,
  onRightClick,
}) => {
  const classes = useClasses(styles);
  const history = useHistory();

  const onClick = () => {
    if (store('redirectToRecent')){
      store.remove('redirectToRecent')
      history.push('/stories/recent')
    }
    else if (onLeftClick){
       onLeftClick()
    }
    else {
      history.go(-1)
    }
    }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
          {/* @ts-ignore */}
          <Button size='small' className={classes.btnBack} onClick={onClick}>
            {leftIcon ? leftIcon : <ArrowBack width={16} height={16} />}
          </Button>
        </Box>
        {title && (
          <div className={classes.title}>
            {showBack && (
              <Box component={'div'} sx={{ display: { xs: 'none', md: 'block' } }}>
                {/* @ts-ignore */}
                <Button size='small' className={classes.btnBack} onClick={onLeftClick ? onLeftClick : history.goBack}>
                  {leftIcon ? leftIcon : <ArrowBack width={16} height={16} />}
                </Button>
              </Box>
            )}
            <Typography variant='h5' className={classes.titleText}>
              {title}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.root}>
        {rightIcon && (
          <Box component={'div'} sx={{ display: { xs: 'block', md: showRight ? 'block' : 'none' } }}>
            {/* @ts-ignore */}
            <Button size='small' className={classes.btnBack} onClick={onRightClick}>
              {rightIcon}
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
};
