import { Button, Theme, Toolbar, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TextLogo, Wrapper } from 'components';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ spacing }: Theme) => ({
  root: {
    backgroundColor: 'rgba(187, 134, 252, 0.12)',
  },
  toolbar: {
    padding: spacing(3, 0),
  },
  title: {
    marginRight: spacing(3),
    fontWeight: 600,
  },
  logoWrapper: {
    marginLeft: 'auto',
  },
});

export const SettingsHeader: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Wrapper>
        <Toolbar disableGutters classes={{ root: classes.toolbar }}>
          <Typography variant='h4' className={classes.title}>
            {t('header_main_menu')}
          </Typography>
          <Button color='primary' variant='text' onClick={() => history.push('/')}>
            {t('header_close_settings')}
          </Button>
          <div className={classes.logoWrapper}>
            <TextLogo withVersion />
          </div>
        </Toolbar>
      </Wrapper>
    </div>
  );
};
