import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  useCreateReminderMutation,
  useReminderCarQuery,
  // useRemindersCarQuery,
  useUpdateReminderMutation,
} from 'services/reminders';
import { RemindEveryDateType, RemindType, Reminder } from 'types';
import { pasteText } from 'utils/clipboard';

interface reminderDateFormType {
  date?: Dayjs | null;
  time?: Dayjs | null;
  repeat?: RemindEveryDateType;
}

interface reminderMileageFormType {
  when?: string;
  before?: string;
  every?: string;
}

export const useCreateReminder = () => {
  const { carId, reminderId } = useParams<{ carId: string; reminderId: string }>();
  const history = useHistory();
  const location = useLocation();
  const isEditPath = location.pathname.includes('/edit/');
  const isCopyPath = location.pathname.endsWith('/copy');

  const [enableNotes, setEnableNotes] = useState(false);
  const [enableLink, setEnableLink] = useState(false);
  const [enableDate, setEnableDate] = useState(false);
  const [enableMileage, setEnableMileage] = useState(false);
  const [enableCategory, setEnableCategory] = useState(false);
  const [title, setTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [link, setLink] = useState('');
  const [date, setDate] = useState<reminderDateFormType | null>();
  const [mileage, setMileage] = useState<reminderMileageFormType | null>();
  const [categories, setCategories] = useState<string[]>([]);

  const [validTitle, setValidTitle] = useState(true);
  const [validNotes, setValidNotes] = useState(true);
  const [validLink, setValidLink] = useState(true);
  const [validDate, setValidDate] = useState(true);
  const [validTime, setValidTime] = useState(true);
  const [validMileageWhen, setValidMileageWhen] = useState(true);
  const [validMileageBefore, setValidMileageBefore] = useState(true);
  const [validCategory, setValidCategory] = useState(true);

  const createMutation = useCreateReminderMutation();
  const editMutation = useUpdateReminderMutation();

  const { data: reminderData, isFetching, refetch: refetchReminder } = useReminderCarQuery({ carId, reminderId });

  useEffect(() => {
    // console.log({ isEditPath, isCopyPath });
    if (isEditPath || isCopyPath) {
      refetchReminder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((isEditPath || isCopyPath) && !isFetching && reminderData) {
      parseReminderDataToForm(reminderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminderData, isFetching]);

  const toggleNotes = () => {
    setEnableNotes((state) => {
      if (state) {
        setNotes('');
        setValidNotes(true);
      }
      return !state;
    });
  };

  const toggleLink = () => {
    setEnableLink((state) => {
      if (state) {
        setLink('');
        setValidLink(true);
      }
      return !state;
    });
  };
  const toggleDate = () => {
    setEnableDate((state) => {
      if (!state) {
        setDate({ time: null, date: dayjs() });
        setValidDate(true);
        setValidTime(true);
      }
      if (state) setDate(null);
      return !state;
    });
  };
  const toggleMileage = () => {
    setEnableMileage((state) => {
      if (!state) {
        setValidMileageWhen(true);
        setValidMileageBefore(true);
      }
      if (state) setMileage(null);
      return !state;
    });
  };
  const toggleCategory = () => {
    setEnableCategory((state) => {
      if (!state) {
        setValidCategory(true);
      }
      if (state) setCategories([]);
      return !state;
    });
  };

  const onChangeTitle = (newValue: string) => {
    setValidTitle(true);
    setTitle((state) => {
      return newValue;
    });
  };

  const onChangeNotes = (newValue: string) => {
    setValidNotes(true);
    setNotes((state) => {
      return newValue;
    });
  };

  const onChangeLink = (newValue: string) => {
    setValidLink(true);
    setLink((state) => {
      return newValue;
    });
  };
  const onChangeDateDate = (newValue: Dayjs | null) => {
    setValidDate(true);
    setDate((state) => {
      const newState: reminderDateFormType = { ...state, date: newValue };
      return newState;
    });
  };
  const onChangeDateTime = (newValue: Dayjs | null) => {
    setValidTime(true);
    setDate((state) => {
      const newState: reminderDateFormType = { ...state, time: newValue };
      return newState;
    });
  };
  const onChangeDateRepeat = (newValue: RemindEveryDateType) => {
    setDate((state) => {
      const newState: reminderDateFormType = { ...state, repeat: newValue };
      return newState;
    });
  };

  const onChangeMileageWhen = (newValue: string) => {
    setMileage((state) => {
      setValidMileageWhen(true);
      const newState: reminderMileageFormType = { ...state, when: newValue };
      return newState;
    });
  };

  const onChangeMileageBefore = (newValue: string) => {
    setMileage((state) => {
      setValidMileageBefore(true);
      const newState: reminderMileageFormType = { ...state, before: newValue };
      return newState;
    });
  };

  const onChangeMileageEvery = (newValue: string) => {
    setMileage((state) => {
      const newState: reminderMileageFormType = { ...state, every: newValue };
      return newState;
    });
  };

  const pasteLink = async () => {
    const text = await pasteText();
    setLink(text);
  };

  const onChangeCategories = (newValues: any) => {
    setCategories((state) => {
      setValidCategory(true);
      return newValues;
    });
  };

  const submit = () => {
    if (!validateForm()) {
      return;
    }
    let reminderType: RemindType | null = null;
    if (!enableDate && enableMileage) {
      reminderType = 'mileage';
    } else if (enableDate && !enableMileage) {
      reminderType = 'date';
    } else if (enableDate && enableMileage) {
      reminderType = 'date_and_mileage';
    }

    let reminder: Reminder = {
      title,
      reminder_type: reminderType,
    };
    if (enableNotes) {
      reminder.comment = notes;
    }
    if (enableLink) {
      reminder.link = link;
    }
    if (enableCategory && categories.length) {
      reminder.category = categories.join();
    }
    if (enableDate) {
      if (date?.date && date?.time) {
        const year = date?.date.year();
        const month = date?.date.month();
        const day = date?.date.date();
        const hour = date?.time.hour();
        const min = date?.time.minute();

        const remindDate = dayjs()
          .set('year', year)
          .set('month', month)
          .set('date', day)
          .set('hour', hour)
          .set('minute', min)
          .set('seconds', 0)
          .set('milliseconds', 0);
        let offset = remindDate.utcOffset();
        reminder.remind_date = dayjs(remindDate).subtract(offset, 'minute').format('YYYY-MM-DD HH:mm:ss');
      }
      reminder.remind_every_date_type = date?.repeat !== 'do_not_repeat' ? date?.repeat : 'None';
    }
    if (enableMileage) {
      reminder.remind_mileage = Number(mileage?.when);
      reminder.remind_before_mileage = Number(mileage?.before);
      reminder.to_repeat_mileage = Number(mileage?.every || 0);
    }

    if (isEditPath) {
      editMutation.mutate(
        { carId: reminderData.car_id as string, reminderId: reminderData.id as string, body: reminder },
        {
          onSuccess(data, variables, context) {
            history.push(`/reminders/${carId}`, { state: { reminderCreated: true } });
          },
        }
      );
    } else {
      createMutation.mutate(
        { body: reminder, carId },
        {
          onSuccess(data, variables, context) {
            history.push(`/reminders/${carId}`, { state: { reminderCreated: true } });
          },
        }
      );
    }
  };

  const validateForm = () => {
    let validated = true;

    if (!title || title.trim() === '') {
      validated = false;
      setValidTitle(false);
    }

    if (enableNotes) {
      if (!notes || notes.trim() === '') {
        validated = false;
        setValidNotes(false);
      }
    }
    if (enableLink) {
      if (!link || link.trim() === '') {
        validated = false;
        setValidLink(false);
      }
    }
    if (enableDate) {
      if (!date || !date?.date?.isValid()) {
        validated = false;
        setValidDate(false);
      }
      if (!date || !date?.time?.isValid()) {
        validated = false;
        setValidTime(false);
      }
    }
    if (enableMileage) {
      if (!mileage || mileage?.when?.trim() === '') {
        validated = false;
        setValidMileageWhen(false);
      }
      if (!mileage || mileage?.before?.trim() === '') {
        validated = false;
        setValidMileageBefore(false);
      }
    }
    if (enableCategory) {
      if (categories && (categories.length === 0 || categories.length > 3)) {
        validated = false;
        setValidCategory(false);
      }
    }
    return validated;
  };

  const parseReminderDataToForm = (reminder: Reminder) => {
    setTitle(reminder.title);
    if (reminder.comment) {
      setEnableNotes(true);
      setNotes(reminder.comment);
    }
    if (reminder.link) {
      setEnableLink(true);
      setLink(reminder.link);
    }
    if (reminder.category) {
      setEnableCategory(true);
      setCategories(reminder.category.split(','));
    }
    if (reminder.remind_date && reminder.remind_date !== 'None') {
      setEnableDate(true);
      setDate({
        date: dayjs(reminder.remind_date).add(dayjs().utcOffset(), 'minute'),
        time: dayjs(reminder.remind_date).add(dayjs().utcOffset(), 'minute'),
        repeat: reminder.remind_every_date_type ? reminder.remind_every_date_type : 'do_not_repeat',
      });
    }
    if (reminder.remind_mileage && reminder.remind_mileage !== 'None') {
      setEnableMileage(true);
      setMileage({
        when: reminder.remind_mileage as string,
        before: String(reminder.remind_before_mileage),
        every:
          reminder.to_repeat_mileage && reminder.to_repeat_mileage !== '0'
            ? (reminder.to_repeat_mileage as string)
            : '',
      });
    }
  };

  return {
    enableNotes,
    enableCategory,
    enableDate,
    enableLink,
    enableMileage,
    toggleNotes,
    toggleLink,
    toggleDate,
    toggleMileage,
    toggleCategory,
    title,
    onChangeTitle,
    onChangeNotes,
    onChangeLink,
    onChangeDateDate,
    onChangeDateTime,
    onChangeDateRepeat,
    onChangeCategories,
    onChangeMileageWhen,
    onChangeMileageBefore,
    onChangeMileageEvery,
    notes,
    link,
    date,
    mileage,
    categories,
    pasteLink,
    submit,
    validTitle,
    validNotes,
    validLink,
    validDate,
    validTime,
    validMileageWhen,
    validMileageBefore,
    validCategory,
  };
};
