import {
  // Calculate,
  Dashboard,
  ExitToApp,
  Explore,
  FileCopy,
  Message,
  Notifications,
  Person,
  Settings,
} from '@mui/icons-material';

export const menuItems = [
  {
    label: 'settings_menu_profile',
    icon: Person,
    withArrow: true,
    to: '/settings/profile',
  },
  {
    label: 'settings_menu_alerts',
    icon: Notifications,
    to: '/alerts',
    isBadge: true,
    keyBadge: 'alertActive',
    keyPulse: 'alertPulse',
  },
  {
    label: 'settings_menu_settings',
    icon: Settings,
    withArrow: true,
    to: '/settings/general',
  },
  {
    label: 'settings_dashboard',
    icon: Dashboard,
    withArrow: true,
    to: '/dashboard',
  },
  // {
  //   label: 'settings_calculator',
  //   icon: Calculate,
  //   withArrow: true,
  //   to: '/calculator',
  // },
  {
    label: 'settings_menu_demo',
    icon: Explore,
  },
  {
    label: 'settings_menu_feedback',
    icon: Message,
    to: '/settings/feedback',
  },
  {
    label: 'documents',
    icon: FileCopy,
    to: '/documents',
  },
  {
    label: 'settings_menu_logout',
    icon: ExitToApp,
    to: '/settings/logout',
  },
];

export const settingMenuItems = {
  settings_general: [
    {
      label: 'setting_date_and_metric',
      to: '/settings/date_metric',
    },
    {
      label: 'setting_currency',
      to: '/settings/currency',
    },
  ],
  settings_account: [
    {
      label: 'setting_language',
      to: '/settings/language',
    },
    {
      label: 'setting_sign_in',
      to: '/settings/sign_in',
    },
    {
      label: 'setting_password',
      to: '/settings/password',
    },
    {
      label: 'setting_blocked_users',
      to: '/settings/blocked_users',
    },
  ],
};
export const documentItems = [
  {
    label: 'privacy_policy_heading',
    to: '/privacy-policy',
  },
  {
    label: 'cookie_policy_heading',
    to: '/cookie-policy',
  },
  {
    label: 'terms_of_use_heading',
    to: '/terms-of-use',
  },
];

export const documentItemsNativeApp = [
  {
    label: 'privacy_policy_heading',
    to: '/privacy-policy',
  },
  {
    label: 'terms_of_use_heading',
    to: '/terms-of-use',
  },
];
