import { ComponentType, useEffect, useState } from 'react';

import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CommonLayout, InnerMobileHeader, PageLoader, StyledButton } from 'components';
import { useCarQuery, useGenerateAssistedLinkMutation, useGeneratedLinksAmountQuery } from 'services';
import { getTKey } from 'utils';
import { GeneratedLink, RecordsCarsList } from './components';

const tKey = getTKey('assisted_record_generate');

export const GenerateAssistedRecordPage: ComponentType = () => {
  const { t } = useTranslation();
  const { carId } = useParams<{ carId: string }>();
  const { data: carData, isLoading: carDataLoading } = useCarQuery(carId);
  const { data: generatedLeftData, isLoading: generatedLeftLoading } = useGeneratedLinksAmountQuery(carId);
  const { mutate, isLoading: generationProcessing } = useGenerateAssistedLinkMutation();

  const [generatedLink, setGeneratedLink] = useState<string>('');
  const [generatedLinkError, setGeneratedLinkError] = useState<boolean>(false);

  useEffect(() => {
    if (!generatedLeftLoading && generatedLeftData && generatedLeftData.last_valid_identifier) {
      setGeneratedLink(generatedLeftData.last_valid_identifier);
    }
  }, [generatedLeftLoading, generatedLeftData]);

  const handleGenerateAssistedLink = () => {
    setGeneratedLinkError(false);

    mutate(carId, {
      onSuccess: (response) => {
        setGeneratedLink(response.link);
      },
      onError: () => {
        setGeneratedLinkError(true);
      },
    });
  };

  return (
    <CommonLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
          <RecordsCarsList />
        </Grid>
        <Grid item xs={12} md={8}>
          {carDataLoading ? (
            <PageLoader />
          ) : (
            <>
              <InnerMobileHeader title={`${t(tKey('title'))} ${capitalize(carData?.make)} ${carData?.model}`} />
              <Typography variant='body1'>{t(tKey('description'))}</Typography>
              <Box textAlign='center' mt={4} mb={3}>
                <StyledButton label={t(tKey('generate_link'))} onClick={handleGenerateAssistedLink} />
                {!generatedLeftLoading && generatedLeftData && (
                  <Box mt={2}>
                    <Typography variant='subtitle2'>
                      {t(tKey('links_remain'))} - {generatedLeftData.amount}
                    </Typography>
                  </Box>
                )}
              </Box>

              {generatedLinkError && (
                <Typography variant='h6' align='center'>
                  {t(tKey('error_limit'))}
                </Typography>
              )}

              {generationProcessing ? (
                <Box textAlign='center'>
                  <CircularProgress />
                </Box>
              ) : (!generationProcessing && generatedLink) || (!generatedLeftLoading && generatedLink) ? (
                <GeneratedLink identifier={generatedLink} link='assisted' />
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
