import { ComponentType, useEffect, useState } from 'react';
import animationData from 'components/SpinFeedLoader/loader.json';
import { useEditStory } from './hooks/useEditStory';
import { AddStoryContent } from 'components/Stories/AddStoryContent';
import { Box, Button, Divider, Theme, Typography } from '@mui/material';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import { getTKey } from 'utils';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import clsx from 'clsx';
import { SelectCoverImage } from 'components/Stories/SelectCoverImage';
import { Preview } from 'components/Stories/Preview';
import { InnerMobileHeader } from 'components';
import { useHistory } from 'react-router-dom';
import { SaveStoryDialog } from 'components/Stories/SaveStoryDialog';
import Lottie from 'lottie-react';
import { StoryPublishDialog } from 'components/StoryPublishDialog/StoryPublishDialog';
const tKey = getTKey('edit_story');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    margin: spacing(1, 0, 2.5),
    width: '33.3%',
  },
  dividerActive: {
    backgroundColor: 'rgba(3, 218, 197, 1)',
  },
  dividerInactive: {
    backgroundColor: 'rgba(3, 218, 197, 0.6)',
  },
  buttonRoot: {
    gap: 10,
    padding: 0,
    fontSize: 14,
    color: 'white',
  },
  button: {
    color: 'rgba(72,72,72, 1) !important',
  },
  formContainer: {
    marginTop: spacing(2),
  },
  spinnerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(18,18,18,0.4)',
    width: '100%',
    height: '100%',
  },
  spinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface EditStoryFormType {
  carId: string;
  storyId: string;
}

const steps = ['edit_content', 'select_story_cover', 'preview'];
export const EditStoryForm: ComponentType<EditStoryFormType> = ({ carId, storyId }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const history = useHistory();
  const {
    elements,
    recordGroup,
    records,
    title,
    descLength,
    disabled,
    activeStep,
    coverIndex,
    isDirty,
    loading,
    isLoading,
    saving,
    setCover,
    setDisabled,
    setElements,
    setTitle,
    addDescription,
    addGallery,
    addText,
    addRecord,
    addRecordGroup,
    removeElement,
    handlePrevious,
    handleNext,
    autoSelectCover,
    setSaving,
    addVideo,
  } = useEditStory(carId, storyId);

  useEffect(() => {
    if (activeStep === 0) {
      setDisabled(title === '' || elements.length === 0 || descLength > 4000);
    }
    if (activeStep === 1) {
      setDisabled(false);
    }
    if (activeStep === 2 && coverIndex.elementIndex === -1 && descLength === 0) {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, elements, activeStep]);

  useEffect(() => {
    if (activeStep === 1) {
      setDisabled(autoSelectCover);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const [leaveStory, setLeaveStory] = useState(false);

  const onCloseStoryForm = () => {
    if (isDirty) {
      setLeaveStory(true);
    } else {
      history.go(-1);
    }
  };

  useEffect(() => {
    const onBeforeUnload = (e: any) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    if (isDirty) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isDirty]);

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <InnerMobileHeader title={t(tKey('title'))} leftIcon={<Close />} showBack onLeftClick={onCloseStoryForm} />
      {leaveStory && (
        <SaveStoryDialog
          onLeave={() => {
            history.go(-1);
          }}
          onClose={() => setLeaveStory(false)}
        />
      )}
      <Box className={classes.container}>
        {t(tKey(steps[activeStep]))}
        <Typography>
          {activeStep + 1}/{steps.length}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {steps.map((label, index) => {
          return (
            <Divider
              key={index}
              className={clsx(classes.divider, index <= activeStep ? classes.dividerActive : classes.dividerInactive)}
            />
          );
        })}
      </Box>
      <Box className={classes.container}>
        <Button
          disabled={activeStep === 0}
          classes={{ disabled: classes.button, root: classes.buttonRoot }}
          onClick={() => handlePrevious()}
        >
          <ArrowBack /> {t('create_story_previous')}
        </Button>
        <Button
          disabled={disabled}
          classes={{ disabled: classes.button, root: classes.buttonRoot }}
          onClick={handleNext}
        >
          {t('create_story_' + (activeStep === 2 ? 'publish' : 'next'))} <ArrowForward />
        </Button>
      </Box>
      {activeStep === 0 && elements?.length > -1 && (
        <AddStoryContent
          carId={carId}
          elements={elements}
          setElements={setElements}
          addGallery={addGallery}
          addDescription={addDescription}
          addRecord={addRecord}
          addRecordGroup={addRecordGroup}
          addText={addText}
          recordGroup={recordGroup}
          records={records}
          title={title}
          descLength={descLength}
          setTitle={setTitle}
          updateDraft={() => {}}
          removeElement={removeElement}
          addVideo={addVideo}
        />
      )}
      {activeStep === 1 && <SelectCoverImage elements={elements} setCover={setCover} coverIndex={coverIndex} />}
      {activeStep === 2 && (
        <Preview
          elements={elements}
          title={title}
          recordGroup={recordGroup}
          records={records}
          carId={carId}
          coverIndex={coverIndex}
        />
      )}
      <StoryPublishDialog
        open={saving}
        onClose={() => {
          setSaving(false);
        }}
      />
      {(isLoading || loading) && (
        <div className={classes.spinnerOverlay}>
          <div className={classes.spinner}>
            <Lottie animationData={animationData} />
          </div>
        </div>
      )}
    </Box>
  );
};
