import { Grid } from '@mui/material';
import { ComponentType, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CommonLayout, PageLoader } from 'components';
import { useFilteredRecords } from 'lib';
import { useCarQuery } from 'services';
import { RecordsFiltersInterface } from 'types';
import { RecordsCarsList, RecordsFilters, RecordsList, RecordsTotals } from './components';

export const RecordsDashboardPage: ComponentType = () => {
  const [filters, setFilters] = useState<RecordsFiltersInterface>(() => {
   
    return {
      year: 'all',
      month: 'all',
      category: 'all',
    };
  });
  const [openGroupId, setOpenGroupId] = useState<string>('');
  const { carId } = useParams<{ carId: string }>();
  const history = useHistory();
  const { data: carData } = useCarQuery(carId);
  const { records, filteredRecords, isLoading, lastMileage } = useFilteredRecords(filters, carId, 'records');
  const mileageMetric = carData && carData.mileageMetric;

  useEffect(() => {
   
    handleFiltersChange({
      year: 'all',
      month: 'all',
      category: 'all',
    });
  }, [carId]);

  useEffect(() => {
    const unlisten = history.listen(handleCloseGroup);

    return unlisten;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFiltersChange = (filters: Partial<RecordsFiltersInterface>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  };

  const handleOpenGroup = (id: string) => {
    setOpenGroupId(id);
  };

  const handleCloseGroup = () => {
    setOpenGroupId('');
  };

  return (
    <CommonLayout>
      {isLoading && <PageLoader />}
      {records ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <RecordsCarsList />
          </Grid>
          <Grid item xs={12} md={8}>
            <RecordsFilters
              data={records}
              filters={filters}
              filteredRecordsQuantity={filteredRecords?.length}
              isFaded={!!openGroupId}
              onFiltersChange={handleFiltersChange}
            />
            {filteredRecords.length > 0 && !openGroupId && (
              <RecordsTotals data={filteredRecords} mileageMetric={mileageMetric} lastMileage={lastMileage} />
            )}
            <RecordsList
              carId={carId}
              mileageMetric={mileageMetric}
              data={filteredRecords}
              openGroupId={openGroupId}
              filterCategory={filters.category}
              handleOpenGroup={handleOpenGroup}
              handleCloseGroup={handleCloseGroup}
            />
          </Grid>
        </Grid>
      ) : null}
    </CommonLayout>
  );
};
