import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { useFilteredRecords, useLocationQuery } from 'lib';
import { useCarQuery } from 'services';
import { Car, Record } from 'types';

type useDashboardDetailsData = () => {
  records: Record[] | undefined;
  recordsLoading: boolean;
  car: Car | undefined;
  carLoading: boolean;
  chips: any;
  month: number;
};
export const useDashboardDetails: useDashboardDetailsData = () => {
  const date = dayjs();
  const { carId } = useParams<{ carId: string }>();
  const params = useLocationQuery();
  const year = Number(params.get('year')) || 2022;
  const month = Number(params.get('month')) || Number(date.month());
  const category = params.get('category') || '';
  const paramsArray = [year, month, category];
  const { data: car, isLoading: carLoading } = useCarQuery(carId);
  const { filteredRecords: records, isLoading: recordsLoading } = useFilteredRecords(
    { year, month, category },
    carId,
    'dashboard'
  );

  return {
    records,
    recordsLoading,
    car,
    month,
    carLoading,
    chips: paramsArray,
  };
};
