import { LibraryBooks, MonetizationOn, Speed } from '@mui/icons-material';
import { Box, Chip, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';

import { Currency, Record } from 'types';
import { countTotalMoneySpend, currencySymbol, getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('records_totals');

interface RecordsTotalsProps {
  data: Record[];
  mileageMetric: string | undefined;
  lastMileage: number;
  publicRecord?: boolean;
  currency?: string;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    marginBottom: spacing(3),
    marginTop: spacing(2),
  },
  chip: {
    marginRight: spacing(1),
  },
  text: {
    maxWidth: '85%',
    margin: '0 auto',
    textAlign: 'center',
  },
});

export const RecordsTotals: ComponentType<RecordsTotalsProps> = ({
  data,
  mileageMetric,
  lastMileage,
  currency,
  publicRecord,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const settingsData = store('driverbook_settings');
  const flattenData = data.flat();

  return flattenData && flattenData.length ? (
    <div className={classes.root}>
      <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
        <div className={classes.text}>
          {t(tKey('mobile_title'), {
            mileage: lastMileage,
            mileageMetric,
            costs: countTotalMoneySpend(flattenData),
            symbol: currencySymbol[(publicRecord ? currency : settingsData?.currency) as Currency],
          })}
        </div>
      </Box>

      <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
        <Chip avatar={<Speed />} label={`${t(tKey('last_mileage'))}: ${lastMileage}`} className={classes.chip} />
        <Chip
          avatar={<MonetizationOn />}
          label={`${t(tKey('total_spent'))}: ${countTotalMoneySpend(flattenData)}`}
          className={classes.chip}
        />
        <Chip
          avatar={<LibraryBooks />}
          label={`${t(tKey('total_records'))}: ${flattenData.length}`}
          className={classes.chip}
        />
      </Box>
    </div>
  ) : null;
};
