import { Close } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { ImageLogo } from 'components/ImageLogo/ImageLogo';
import { StyledButton } from 'components/StyledButton/StyledButton';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';
import cover from '../../assets/backgroundOverview.png';
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  titleWrap: {
    display: 'flex',
    justifyContent: 'end',
    padding: 0,
    '& svg': {
      color: palette.text.primary,
    },
  },
  content: {
    padding: spacing(4),
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(4, 0),
    '& img': {
      height: 62,
      width: 60,
    },
  },
  text: {
    '& div': {
      textAlign: 'center',
    },
  },
  helloText: {
    color: palette.text.primary,
    fontSize: 24,
    lineHeight: '24px',
    letterSpacing: '0.2px',
    marginBottom: spacing(1),
  },
  welcomeText: {
    color: palette.text.hint,
    lineHeight: '24px',
    letterSpacing: '0.2px',
    fontSize: 14,
  },
  actions: {
    flexDirection: 'column',
    margin: spacing(4, 0, 0),
  },
  registerBtn: {
    margin: spacing(2, 0, 0),
    padding: '10px 3px',
  },
  overviewBox: {
    background: `url(${cover})`,
    width: 460,
    height: 260,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32,
    border: '1px solid #FFFFFF40',
    [breakpoints.down('sm')]: {
      height: 160,
    },
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  overviewBoxEmbed: {
    width: 460,
    marginTop: 32,
    [breakpoints.down('sm')]: {
      height: 160,
    },
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  overviewButton: {
    height: 56,
    borderRadius: 56,
    padding: '8px 16px 8px 8px',
    background: '#00000080',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      height: 48,
      borderRadius: 48,
    },
  },
  overviewIconPlay: {
    width: 40,
    height: 40,
    borderRadius: 40,
    background: '#BB86FC26',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: 36,
      height: 36,
      borderRadius: 36,
    },
  },
  overviewButtonText: {
    marginLeft: 16,
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: '1.25px',
    [breakpoints.down('sm')]: {
      marginLeft: 8,
      fontSize: 12,
      letterSpacing: '1px',
    },
  },
});

interface UnregisteredPopupType {
  open: boolean;
  onClose: () => void;
  from?: string;
}
// paste only ID Youtube Video
const reviewVideos = {
  en: 'Hdgz_qXQaZw',
  es: 'La0ussNOkZM',
  uk: 'Tg4ffmEoSwM',
};

export const UnregisteredPopup: ComponentType<UnregisteredPopupType> = ({ open, onClose, from }) => {
  const classes = useClasses(styles);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const handleLogin = () => {
    let state = { from: { pathname: from } };
    history.push('/login', state);
  };

  const [watchingReview, setWatchingReview] = useState(false);

  const handlePlayReview = () => {
    setWatchingReview(true);
  };

  const handleClose = () => {
    setWatchingReview(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='lg'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent className={classes.content} sx={{ padding: '24px 12px  !important' }}>
        <DialogTitle id='alert-dialog-title' className={classes.titleWrap}>
          <IconButton onClick={handleClose}>
            <Close className={classes.closeButton} />
          </IconButton>
        </DialogTitle>
        <div className={classes.logo}>
          <ImageLogo />
        </div>
        <div className={classes.text}>
          <div className={classes.helloText}>{t('unregistered_popup_hello')}</div>
          <div className={classes.welcomeText}>{t('unregistered_popup_welcome')}</div>
        </div>
        <DialogActions className={classes.actions}>
          <StyledButton label={t('signin_submit')} onClick={handleLogin} />
          <Button
            className={classes.registerBtn}
            onClick={() => {
              //@ts-ignore
              gtag?.('event', 'register_account', { method: 'register_account' });
              history.push('/signup');
            }}
          >
            {t('signin_register')}
          </Button>
        </DialogActions>
        {!watchingReview ? (
          <Box className={classes.overviewBox}>
            <Box className={classes.overviewButton} onClick={handlePlayReview}>
              <Box className={classes.overviewIconPlay}>
                <PlayArrowIcon style={{ color: '#BB86FC' }} />
              </Box>
              <Typography className={classes.overviewButtonText}>{t('unregistered_popup_overview_button')}</Typography>
            </Box>
          </Box>
        ) : (
          <Box className={classes.overviewBoxEmbed}>
            <Box style={{ position: 'relative', width: '100%', overflow: 'hidden', paddingTop: '56.25%' }}>
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
                src={`https://www.youtube.com/embed/${reviewVideos[i18n.language as 'en' | 'es' | 'uk']}?&autoplay=1`}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                title='Embedded youtube'
              />
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
