import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { Box, Grid, Theme } from '@mui/material';
import { Form, PasswordInput, StyledButton, TextInput } from 'components';
import { ComponentType } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TransferCarPayload } from 'services';
import store from 'store2';
import { getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';

const tKey = getTKey('transfer_car_form');
interface TransferCarFormProp {
  onSubmit: ({ id, new_owner_email, password, access_token }: TransferCarPayload) => void;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  container: {
    [breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  column: {
    marginTop: spacing(1),
  },
  field: {
    height: 100,
  },
  inputStyle: {
    boxShadow: '0 0 0 1000px #201C24 inset',
    WebkitBoxShadow: '0 0 0 1000px #201C24 inset',
    MozBoxShadow: '0 0 0 1000px #201C24 inset',
    WebkitAppearance: 'none',
    WebkitTextFillColor: 'white',
    textFillColor: 'white',
    [breakpoints.down('sm')]: {
      boxShadow: '0 0 0 1000px #121212 inset',
      WebkitBoxShadow: '0 0 0 1000px #121212 inset',
      MozBoxShadow: '0 0 0 1000px #121212 inset',
    },
  },
});

export type NewTransferValues = {
  new_owner: string;
  current_pass: string;
  access_token: string;
};

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    new_owner: yupStringValidator(t).email(t('__validation__email')),
    current_pass: yup.string().when('gat', {
      is: () => {
        return typeof store('googleAccessToken') === 'string' || typeof store('appleAccessToken') === 'string';
      },
      then: yup.string().notRequired(),
      otherwise: yupStringValidator(t),
    }),
  });

export const TransferCarForm: ComponentType<TransferCarFormProp> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { carId } = useParams<{ carId: string }>();
  const classes = useClasses(styles);
  const GAT = store('googleAccessToken');
  const AAT = store('appleAccessToken');

  const handleSubmit = async (data: NewTransferValues) => {
    var values: TransferCarPayload = {
      id: carId,
      new_owner_email: data.new_owner,
    };
    if (GAT) {
      await FirebaseAuthentication.getIdToken().then(
        (access_token) => (values = { ...values, access_token: access_token.token })
      );
    } else if (AAT) {
      await FirebaseAuthentication.signInWithApple({ scopes: ['email'] }).then((firebaseResult: any) => {
        values = { ...values, code: firebaseResult.credential.authorizationCode };
      });}
      else {
        values = { ...values, password: data.current_pass };
      }
    onSubmit(values);
  };

  return (
    <Form schema={validationSchema(t)} onSubmit={handleSubmit} defaultValues={{ email: '', password: '' }}>
      <Grid container justifyContent='center' spacing={3} className={classes.container}>
        <Grid item md={12} className={classes.column}>
          <Grid container spacing={2}>
            <Box sx={{ height: 0, overflow: 'hidden' }}>
              <TextInput name='email' label={t(tKey('email'))} />
              <PasswordInput name='password' label={t(tKey('password'))} />
            </Box>
            <Grid className={classes.field} item xs={12} md={!(AAT || GAT) ? 6 : 12}>
              <TextInput name='new_owner' label={t(tKey('email'))} inputProps={{ className: classes.inputStyle, disabled: (store.get('googleAccessToken') || store.get('appleAccessToken')) && !Capacitor.isNativePlatform()}} />
            </Grid>
            {!(AAT || GAT) && (
              <Grid className={classes.field} item xs={12} md={6}>
                <PasswordInput
                  name='current_pass'
                  label={t(tKey('password'))}
                  inputPropsClassName={classes.inputStyle}
                  inputProps={{ disabled: Boolean(AAT || GAT) }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Box mt={3} mb={1}>
          <StyledButton type='submit' label={t(tKey('submit'))} disabled={!Capacitor.isNativePlatform() && (store.get('googleAccessToken') || store.get('appleAccessToken'))}/>
        </Box>
      </Grid>
    </Form>
  );
};
