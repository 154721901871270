import { Theme, Typography } from '@mui/material';
import { StyledButton } from 'components/StyledButton/StyledButton';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

interface TransferSuccessBoxProps {
  carName: string;
  email: string;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 780,
    minHeight: 600,
    margin: spacing(0, 'auto'),
    padding: spacing(4),
    // backgroundColor: 'rgba(255,255,255,0.08)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    height: 131,
    width: 102,
    textAlign: 'center',
  },
  icon: {
    fontSize: 85,
  },
  text: {
    fontSize: 16,
    opacity: 1,
    margin: spacing(0, 0, 3),

    [breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
});

const tKey = getTKey('transfer_car');
export const TransferSuccessBox: ComponentType<TransferSuccessBoxProps> = ({ carName, email }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.iconWrapper}>
          <span className={classes.icon}>😊</span>
        </div>
        <Typography variant='body1' align='center' className={classes.text}>
          <span>{t(tKey('success_congrats'))}</span>
          <br />
          <span>{t(tKey('success_message'), { carName, email })}</span>
        </Typography>
        <StyledButton onClick={() => history.push('/cars')} label={t(tKey('back'))} />
      </div>
    </div>
  );
};
