import { ArrowBack, BlockRounded, Flag, MoreVert } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem, Theme } from '@mui/material';
import { toast } from 'react-toastify';
import { IStory } from 'features/stories-feed/StoriesTypes';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';
import { ReportDialog } from './ReportDialog';
import { BlockUserDialog } from 'components/BlockUserDialog/BlockUserDialog';
import { useStoryReport } from 'features/stories-feed/hooks/useReportStory';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import { useBlockUserMutation } from 'services/profile/v2/useBlockUserMutation';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  root: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing(0.75),
  },
  back: {
    color: palette.text.hint,
  },
  originalBtnIcon: {
    color: palette.text.hint,
    paddingRight: spacing(2),
  },
  btnIcon: {
    color: palette.text.hint,
    marginRight: spacing(1),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  },
  more: {
    color: palette.text.hint,
  },
  moreMenu: {
    '& .MuiMenu-paper': {
      background: '#2E2E2E',
    },
    '& .MuiList-root': {
      minWidth: 128,
      background: '#2E2E2E',
    },
    '& .MuiButtonBase-root': {
      background: '#2E2E2E',
    },
  },
});

interface TopBarType {
  story: IStory;
  noTranslate: boolean;
  onShowOriginal: () => void;
  authorized: boolean;
}

export const TopBar: ComponentType<TopBarType> = ({ story, noTranslate, onShowOriginal, authorized }) => {
  const classes = useClasses(styles);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [openReport, setOpenReport] = useState(false);
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const { report } = useStoryReport();

  const mutationBlock = useBlockUserMutation();

  const [openBlockDialog, setOpenBlockDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = (data: any) => {
    report({
      body: data,
      storyId: story.id,
      carId: story.car.id,
    });
    setOpenReport(false);
  };

  const handleReportDialog = () => {
    handleClose();
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    setOpenReport(true);
  };

  const handleBlockDialog = () => {
    handleClose();
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    setOpenBlockDialog(true);
  };

  const onBack = () => {
    if (location?.state?.prev?.path === 'stories/edit' || window.history.length === 1 || !location?.state?.prevPage) {
      history.push('/stories/recent');
    } else {
      history.go(-1);
    }
  };

  const handleBlock = async () => {
    setOpenBlockDialog(false);
    await mutationBlock.mutate(story.user.id, {
      onSuccess(data, variables, context) {
        if (data.message === 'success') {
          onBack();
          toast(t('block_user_success'));
        } else {
          toast(t('__common__error'));
        }
      },
      onError(error, variables, context) {
        toast(t('__common__error'));
      },
    });
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={onBack}>
        <ArrowBack className={classes.back} />
      </IconButton>
      <div className={classes.buttons}>
        {!story.do_not_translate && (
          <Button className={classes.originalBtnIcon} onClick={onShowOriginal}>
            {!noTranslate ? t('story_details_see_original') : t('story_details_see_translated')}
          </Button>
        )}
        <IconButton
          id='manage-button'
          aria-controls={open ? 'manage-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVert className={classes.more} />
        </IconButton>
      </div>

      <Menu
        id='manage-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.moreMenu}
        MenuListProps={{
          'aria-labelledby': 'manage-button',
        }}
      >
        <MenuItem onClick={handleReportDialog}>
          <Flag className={classes.btnIcon} />
          {t('__common__report')}
        </MenuItem>
        <MenuItem onClick={handleBlockDialog}>
          {' '}
          <BlockRounded className={classes.btnIcon} />
          {t('__common__block_user')}
        </MenuItem>
      </Menu>
      <ReportDialog open={openReport} onClose={() => setOpenReport(false)} onSubmit={onSubmit} />
      <UnregisteredPopup
        open={openUnregistered}
        from={pathname}
        onClose={() => {
          setOpenUnregistered(false);
        }}
      />
      <BlockUserDialog
        open={openBlockDialog}
        username={story.user.username}
        onClose={() => {
          setOpenBlockDialog(false);
        }}
        onBlock={handleBlock}
      />
    </div>
  );
};
