import { CommonLayout, PageLoader } from 'components';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const SiriVoiceToRecordPage = () => {
  const history = useHistory();
  const { carId } = useParams<{ carId: string }>();

  useEffect(() => {
    history.push(`/records/${carId}/voice`, { state: {siri: true} });
  });


  return (<CommonLayout><PageLoader /></CommonLayout>);
}
