import { YouTube } from '@mui/icons-material';
import { Box, Theme } from '@mui/material';
import dayjs from 'dayjs';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getYoutubeVideoIdFromCoverUrl } from 'utils/YouTubeUtil';
import { useClasses } from 'utils/hooks/useClasses';
import { IElement, IStory } from '../StoriesTypes';
import { useViewStory } from '../hooks/useViewStory';
import { Reactions } from './Reactions';
import { StoryHeader } from './StoryHeader';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    margin: spacing(2, 0),
    padding: spacing(1),
    borderRadius: 4,
    border: '1px solid rgba(255, 255, 255, 0.08)',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%), #121212',
  },
  divider: {
    border: '1px solid #484848',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '0.15px',
    whitespace: 'nowrap',
  },
  cover: {
    maxWidth: '100%',
    borderRadius: 4,
    maxHeight: '60vh',
  },
  coverWrapper: {
    textAlign: 'center',
    padding: spacing(1, 0),
  },
  previewText: {
    padding: spacing(1, 0),
    wordWrap: 'break-word',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timeAgo: {
    color: palette.text.hint,
  },
  linkTime: {
    color: 'unset',
    textDecoration: 'none',
    cursor: 'default',
  },
});

interface StoryItemType {
  story: IStory;
  authorized: boolean;
  openUnregisteredDialog?: () => void;
}

export const StoryItem: ComponentType<StoryItemType> = ({ story, authorized, openUnregisteredDialog }) => {
  const classes = useClasses(styles);

  const { i18n } = useTranslation();

  const getLang = () => {
    return i18n.language;
  };
  const lang = getLang();

  const history = useHistory();
  const location = useLocation();

  try {
    if (lang === 'uk') {
      dayjs.locale(require(`dayjs/locale/uk`));
    } else if (lang === 'es') {
      dayjs.locale(require(`dayjs/locale/es`));
    } else {
      dayjs.locale(require(`dayjs/locale/en`));
    }
  } catch (e) {
    console.log(`Cannot load ${lang} language. Will be used english language`);
  }

  const previewTextElement: IElement | undefined = story.elements.find(
    (element) => element.element_type === 'original_text'
  );
  let previewText: string = '';
  if (previewTextElement?.texts && previewTextElement?.texts?.length > 0) {
    if (previewTextElement.texts[0].do_not_translate) {
      previewText =
        previewTextElement.texts[0]?.original_text?.length > 100
          ? previewTextElement.texts[0].original_text.substring(0, 100) + '...'
          : previewTextElement.texts[0].original_text;
    } else {
      previewText =
        previewTextElement.texts[0]?.translated_text?.length > 100
          ? previewTextElement.texts[0].translated_text.substring(0, 100) + '...'
          : previewTextElement.texts[0].translated_text;
    }
  }

  const goToStory = () => {
    history.push(`/stories/${story.car.id}/${story.id}`, { prevPage: location });
  };

  const [isVideo] = useState(getYoutubeVideoIdFromCoverUrl(story.cover));
  const [turnVideo, setTurnVideo] = useState(false);
  const { viewStory } = useViewStory();
  const handleTurnVideo = async () => {
    setTurnVideo(true);
    const viewResponse = await viewStory({ carId: story.car.id, storyId: story.id });
    if (viewResponse.message === 'success') {
    }
  };
  return (
    <div className={classes.root}>
      <StoryHeader
        car={story.car}
        user={story.user}
        authorized={authorized}
        is_mileage_hidden={story.is_mileage_hidden}
        openUnregisteredDialog={openUnregisteredDialog}
      />
      <hr className={classes.divider} />
      <div>
        <Box onClick={goToStory}>
          <div className={classes.title}>{story.do_not_translate ? story.title : story.translated_title}</div>
          {previewText !== '' && <div className={classes.previewText}>{previewText}</div>}
        </Box>

        {story.cover && (
          <div className={classes.coverWrapper}>
            {isVideo ? (
              <>
                {turnVideo ? (
                  <Box style={{ position: 'relative', width: '100%', overflow: 'hidden', paddingTop: '56.25%' }}>
                    <iframe
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                      }}
                      src={`https://www.youtube.com/embed/${isVideo}?&autoplay=1`}
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                      title='Embedded youtube'
                    />
                  </Box>
                ) : (
                  <Box style={{ position: 'relative', width: '100%', overflow: 'hidden' }} onClick={handleTurnVideo}>
                    <img src={story.cover} alt='' className={classes.cover} />
                    <YouTube
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        right: 0,
                        bottom: 0,
                        width: '50px',
                        height: '100%',
                        border: 'none',
                      }}
                    />
                  </Box>
                )}
              </>
            ) : (
              <img src={story.cover} alt='' className={classes.cover} onClick={goToStory} />
            )}
          </div>
        )}
        <div className={classes.footer} onClick={goToStory}>
          <Reactions
            comments={story.comments_count}
            dislikes={story.dislikes}
            isDisliked={story.is_disliked}
            isLiked={story.is_liked}
            likes={story.likes}
            views={story.views}
          />
          <div className={classes.timeAgo}>
            <Link
              className={classes.linkTime}
              to={{ pathname: `/stories/${story.car.id}/${story.id}`, state: { prevPage: location } }}
            >
              {
                //@ts-ignore
                dayjs.utc(story.date_created).fromNow()
              }
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
