import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import store from 'store2';
import { Maybe } from 'yup/lib/types';

import {
  useAssistedCarQuery,
  useCreateAssistedRecordMutation,
  useDeleteDraftMutation,
  useDraftsQuery,
  useRecordGroupQuery,
} from 'services';
import { Car, Record } from 'types';
import { getTotalExpenses } from 'utils';

export type GroupDataType = {
  id: string;
  title: Maybe<string>;
  date_created: string;
  date_performed: string;
};

type useAssistedLayoutType = () => {
  carDataLoading: boolean;
  carData: Car;
  draftsDataLoading: boolean;
  draftsData: Record[];
  identifier: string;
  deleteRecordId: string;
  groupData: GroupDataType;
  groupDataLoading: boolean;
  totalCost: number;
  onSessionFinish: () => void;
  handleDraftDelete: (id: string) => void;
  handleConfirmDraftDelete: () => void;
  handleConfirmDraftClose: () => void;
};
export const useAssistedLayout: useAssistedLayoutType = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { identifier } = useParams<{ identifier: string }>();
  const [deleteRecordId, setDeleteRecordId] = useState<string>('');

  const { data: carData, isLoading: carDataLoading } = useAssistedCarQuery(identifier);
  const {
    data: draftsData,
    isLoading: draftsDataLoading,
    isFetching: draftsDataFetching,
    refetch: draftRefetch,
  } = useDraftsQuery(carData && carData.id, identifier);
  const { data: groupData, isLoading: groupDataLoading } = useRecordGroupQuery(identifier);
  const mutation = useCreateAssistedRecordMutation();
  const deleteMutation = useDeleteDraftMutation();
  const totalCost = getTotalExpenses(draftsData);

  useEffect(() => {
    if (!carDataLoading && carData?.error) {
      history.push('/assisted/error');
    }
  }, [carDataLoading, carData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!draftsDataFetching && draftsData?.length === 0) {
      const localStorageMileage = store('driverbook_draftMileage');

      if (localStorageMileage) {
        store.remove('driverbook_draftMileage');
      }
    }
  }, [draftsDataFetching, draftsData]);

  useEffect(() => {
    const refetch = () => {
      draftRefetch();
    };

    window.addEventListener('assistedRecordCreate', refetch);

    return () => {
      window.removeEventListener('assistedRecordCreate', refetch);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSessionFinish = () => {
    mutation.mutate(
      { identifier },
      {
        onSuccess: () => {
          draftRefetch();
          toast(t('assisted_record_session_finished'));
          history.push(`/assisted/success`);
          store.remove('driverbook_draftMileage');
        },
      }
    );
  };

  const handleDraftDelete = (id: string) => {
    setDeleteRecordId(id);
  };

  const handleConfirmDraftClose = () => {
    setDeleteRecordId('');
  };

  const handleConfirmDraftDelete = () => {
    deleteMutation.mutate(
      { carId: carData.id, recordId: deleteRecordId, identifier },
      {
        onSuccess: () => {
          setDeleteRecordId('');
          toast(t('assisted_record_draft_deleted'));
          history.push(`/assisted/${identifier}`);
        },
      }
    );
  };

  return {
    carDataLoading,
    carData,
    deleteRecordId,
    draftsData,
    draftsDataLoading,
    groupData,
    groupDataLoading,
    identifier,
    totalCost,
    onSessionFinish,
    handleDraftDelete,
    handleConfirmDraftDelete,
    handleConfirmDraftClose,
  };
};
