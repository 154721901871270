import { Box, Theme } from '@mui/material';
import { ComponentType, useContext, useEffect, useState } from 'react';

import { CommonHeader, MobileNavigation, Wrapper } from 'components';
import { DemoTour } from 'components/DemoTour/DemoTour';
import { useHistory } from 'react-router-dom';
import { useProfileInfoQuery } from 'services';
import { useHasUnreadAlertQuery } from 'services/alerts';
import { setAlertActive, setProfile, setRemindersActive } from 'store/profile/slice';
import { ProfileContext as Context } from 'store/profile/store';
import { useClasses } from 'utils/hooks/useClasses';
import { useCheckUnreadRemindersQuery } from 'services/reminders/hooks/useCheckUnreadRemindersQuery';

const styles = ({ breakpoints, spacing }: Theme) => ({
  root: {
    position: 'relative',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
  },
  innerWrapper: {
    margin: spacing(2, 0, 10, 0),

    [breakpoints.up('md')]: {
      margin: spacing(4, 0),
    },
  },
});

export const CommonLayout: ComponentType<{ hideMobileNav?: boolean }> = ({ children, hideMobileNav }) => {
  const classes = useClasses(styles);
  const { data, isFetched } = useProfileInfoQuery();
  const { data: unreadAlerts, isFetched: isFetchedUnreadAlerts } = useHasUnreadAlertQuery();
  const { data: unreadReminders, isFetched: isFetchedUnreadReminders } = useCheckUnreadRemindersQuery();
  const [demo, setDemo] = useState(false);
  const history = useHistory();
  const { dispatch } = useContext(Context);
  useEffect(() => {
    if (!data) return;
    dispatch(setProfile(data));
    setDemo((!data.tour_finished && !data?.tour_skipped) ?? true);
    if (!data?.email_confirmed) {
      history.replace('/confirm-your-email');
    }
    if (data.username === null) {
      history.replace('/complete-signup');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.tour_skipped, history, isFetched]);

  useEffect(() => {
    if (isFetchedUnreadAlerts && unreadAlerts.message === true) {
      dispatch(setAlertActive(true));
    }
    if (isFetchedUnreadAlerts && unreadAlerts.message === false) {
      dispatch(setAlertActive(false));
    }
  }, [isFetchedUnreadAlerts, unreadAlerts, dispatch]);

  useEffect(() => {
    if (isFetchedUnreadReminders && unreadReminders.message === 'not_read_reminders') {
      dispatch(setRemindersActive(unreadReminders.car_ids));
    }
    if (isFetchedUnreadReminders && unreadReminders.message === 'reminders_are_read') {
      // dispatch(setRemindersActive([]));
    }
  }, [unreadReminders, isFetchedUnreadReminders, dispatch]);
  return (
    <div className={classes.root}>
      <Box sx={{ display: { md: 'block', xs: 'none' } }}>
        <CommonHeader />
      </Box>
      <Wrapper>
        <div className={classes.innerWrapper}>{children}</div>
      </Wrapper>
      {!hideMobileNav && (
        <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
          <MobileNavigation
            startDemo={() => {
              //@ts-ignore
              gtag?.('event', 'demo_tour_clicked', { method: 'demo_tour_clicked' });
              setDemo(true);
            }}
          />
        </Box>
      )}
      {demo && <DemoTour handleCloseModal={() => setDemo(false)} />}
    </div>
  );
};
