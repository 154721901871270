import { UseQueryOptions, useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const alerts = async (pagination: {page: number}) =>{
  try{
    const { data } = await api.get('users/notifications?page='+pagination.page);
    return data;
  }
  catch (error){
    return { error: true };
  }
}

export const useAlertsQuery = (pagination: {page: number},  options: UseQueryOptions<any>) => {
  return useQuery("getAlerts", () => alerts(pagination), options);
}