import { Box, Grid, Theme } from '@mui/material';
import { ComponentType, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageLoader, PublicRecordsLayout } from 'components';
import { useFilteredPublicRecords } from 'lib/useFilteredPublicRecords';
import { usePublicCarQuery } from 'services';
import { RecordsFiltersInterface } from 'types';
import { useClasses } from 'utils/hooks/useClasses';
import { PublicRecordList, RecordsCarItem, RecordsFilters, RecordsTotals } from './components';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    [breakpoints.up('md')]: {
      marginTop: spacing(2),
    },
  },
  carItem: {
    [breakpoints.down('sm')]: {
      marginBottom: spacing(-2.5),
    },
  },
});

export const PublicRecordsPage: ComponentType = () => {
  const history = useHistory();
  const classes = useClasses(styles);
  const { identifier } = useParams<{ identifier: string }>();
  const { data, isLoading: carLoading } = usePublicCarQuery(identifier);
  const [openGroupId, setOpenGroupId] = useState<string>('');

  const [filters, setFilters] = useState<RecordsFiltersInterface>(() => {
  
    return {
      year: 'all',
      month: 'all',
      category: 'all',
    };
  });
  const { filteredRecords, lastMileage } = useFilteredPublicRecords(filters, {
    data: data?.records ?? [],
    isFetching: carLoading,
  });

  const mileageMetric = data?.car?.mileageMetric;

  useEffect(() => {
    const unlisten = history.listen(handleCloseGroup);

    return unlisten;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!carLoading && data?.error) history.push('/public/error');
  }, [data, carLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFiltersChange = (filters: Partial<RecordsFiltersInterface>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  };

  const handleOpenGroup = (id: string) => {
    setOpenGroupId(id);
  };

  const handleCloseGroup = () => {
    setOpenGroupId('');
  };
  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'opened_public_link', { method: 'opened_public_link' });
  }, []);

  return (
    <PublicRecordsLayout>
      <Box mt={2} mb={10}>
        <Grid container spacing={4} className={classes.root}>
          {carLoading || data.error ? (
            <PageLoader />
          ) : (
            <>
              <Grid item xs={12} md={4} className={classes.carItem}>
                <RecordsCarItem car={data?.car} isActive={true} publicRecord />
              </Grid>
              <Grid item xs={12} md={8}>
                <RecordsFilters
                  data={data?.records ?? []}
                  filters={filters}
                  filteredRecordsQuantity={filteredRecords?.length}
                  isFaded={!!openGroupId}
                  onFiltersChange={handleFiltersChange}
                />
                {filteredRecords.length > 0 && !openGroupId && (
                  <RecordsTotals
                    publicRecord
                    data={filteredRecords}
                    mileageMetric={mileageMetric}
                    lastMileage={lastMileage}
                    currency={data.currency}
                  />
                )}
                <PublicRecordList
                  openGroupId={openGroupId}
                  data={filteredRecords}
                  filterCategory={filters.category}
                  mileageMetric={mileageMetric}
                  handleCloseGroup={handleCloseGroup}
                  handleOpenGroup={handleOpenGroup}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </PublicRecordsLayout>
  );
};
