import { Box, Button, FormHelperText, Theme, Typography } from '@mui/material';
// import { User } from 'firebase/auth';
import * as H from 'history';
import { ComponentType, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import store from 'store2';
import * as yup from 'yup';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import googleIcon from 'assets/google.png';
import {
  AppleSignInButton,
  Form,
  FormErrorsCleaner,
  LeanLayout,
  PageLoader,
  PasswordInput,
  StyledButton,
  TextInput,
} from 'components';
import { auth } from 'configurations/firebaseConfig';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { capitalize } from 'lodash';
import { toast } from 'react-toastify';
import { getProfileInfo, useGoogleLoginMutation, useLoginMutation, useUnlockLoginMutation } from 'services';
import { ProfileValues } from 'types';
import { getTKey, yupStringValidator } from 'utils';
import { firebaseNotificationsRegister } from 'utils/firebaseNotifications';
import { handleErrorGoogleAuth } from 'utils/google-auth';
import { useClasses } from 'utils/hooks/useClasses';
import { SocialUsernameForm } from './SocialUsernameForm';
import { useAppleLoginMutation } from 'services/auth/useAppleLoginMutation';

const tKey = getTKey('signin');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  formContainer: {
    margin: spacing(0, 'auto', 4),

    [breakpoints.up('md')]: {
      maxWidth: 588,
      padding: spacing(4, 12),
      backgroundColor: 'rgba(187, 134, 252, 0.08)',
    },
  },
  formError: {
    textAlign: 'center',
    marginTop: spacing(2),
  },
  title: {
    marginBottom: spacing(1),
    fontWeight: 600,
    letterSpacing: '0.4px',
  },
  subtitle: {
    marginBottom: spacing(4),
    opacity: '0.8',
    letterSpacing: '0.2px',
  },
  btn: {
    textTransform: 'none',
  },
  inputStyle: {
    boxShadow: '0 0 0 1000px #201C24 inset',
    WebkitBoxShadow: '0 0 0 1000px #201C24 inset',
    MozBoxShadow: '0 0 0 1000px #201C24 inset',
    WebkitAppearance: 'none',
    WebkitTextFillColor: 'white',
    textFillColor: 'white',
    [breakpoints.down('sm')]: {
      boxShadow: '0 0 0 1000px #121212 inset',
      WebkitBoxShadow: '0 0 0 1000px #121212 inset',
      MozBoxShadow: '0 0 0 1000px #121212 inset',
    },
  },
  inputLabel: {
    margin: spacing(1, 0, -1),
  },
  blockedWrapper: {
    display: 'flex',
    width: '100%',
    padding: spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 4,
    borderRadius: 4,
    background: 'rgba(255, 255, 255, 0.08)',
    textAlign: 'left',
  },
  blockedTitle: {
    fontWeight: 700,
  },
  resendText: {
    color: palette.text.hint,
  },
  unlockResendButton: {
    textTransform: 'capitalize',
    padding: 0,
    fontSize: 14,
    '&:disabled': {
      color: palette.text.hint,
    },
  },
});

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    email_username: yupStringValidator(t),
    password: yupStringValidator(t),
  });

const defaultValues = {
  email_username: '',
  password: '',
};

const ERROR_INVALID_CREDENTIALS = 'Invalid credentials';
const ERROR_TRY_TO_USE_GOOGLE_LOGIN = 'Error, try to use Google login';

export const parseApiError = (error: string) => {
  if (error === ERROR_INVALID_CREDENTIALS) {
    return 'signin_invalid_credentials';
  } else if (error === ERROR_TRY_TO_USE_GOOGLE_LOGIN) {
    return 'signin_use_google_login';
  } else {
    return '__common__error';
  }
};

export const LoginPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();
  const { state } = useLocation<{ from: H.Location<any> }>();
  const [formError, setFormError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const mutation = useLoginMutation();
  const googleMutation = useGoogleLoginMutation();
  const appleMutation = useAppleLoginMutation();
  const unlockMutation = useUnlockLoginMutation();
  const { from } = state || { from: { pathname: '/stories' } };
  const storedLanguage = store('driverbook_language');
  const [user, setUser] = useState<any>();
  const [emailUsernameValue, setEmailUsernameValue] = useState<string>('');
  const [blocked, setBlocked] = useState(false);
  const [unlockEmailSent, setUnlockEmailSent] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  const submitLoginForm = (data: ProfileValues) => {
    setFormError('');
    setLoading(true);
    mutation.mutate(data, {
      async onSuccess(data) {
        //@ts-ignore
        gtag?.('event', 'login', { method: 'email' });
        if (data.accessToken) {
          store(data);
          setUser(undefined);
          const isConfirm = await checkConfirmEmail();
          if (!isConfirm) return;
          if (from.pathname === '') from.pathname = 'stories';
          if (Capacitor.isNativePlatform()) {
            firebaseNotificationsRegister();
          }
          history.replace(from);
        } else if (data.error === 'locked_account_error') {
          setBlocked(true);
          setLoading(false);
        } else {
          setFormError(parseApiError(data.error as string));
          setLoading(false);
        }
      },
    });
  };

  const checkConfirmEmail = async () => {
    const user = await getProfileInfo();
    if (!user.email_confirmed) {
      history.replace('/confirm-your-email');
      return false;
    }
    return true;
  };

  const loginWithGoogle = async () => {
    //@ts-ignore
    gtag?.('event', 'login', { method: 'google' });
    if (/Mobi/.test(navigator.userAgent) && !Capacitor.isNativePlatform()) {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      signInWithPopup(auth, provider)
        .then((result: any) => {
          const dataMutate = {
            email: result.user.email,
            google_id: result.user.providerData[0].uid,
            language: storedLanguage ? storedLanguage : 'en',
            access_token: result.user.stsTokenManager.accessToken,
          };
          postAuth(result, dataMutate);
        })
        .catch((error) => {
          handleErrorGoogleAuth(error);
        });
    } else {
      try {
        FirebaseAuthentication.signInWithGoogle({
          customParameters: [{ key: 'prompt', value: 'select_account' }],
        })
          .then((result: any) => {
            FirebaseAuthentication.getIdToken()
              .then((token) => {
                const dataMutate = {
                  email: result.user.email,
                  google_id: result.additionalUserInfo.profile.id || result.additionalUserInfo.profile.sub,
                  language: storedLanguage ? storedLanguage : 'en',
                  access_token: token.token,
                };
                postAuth(result, dataMutate);
              })
              .catch((error) => {
                toast(error.message);
              });
          })
          .catch((error) => {
            handleErrorGoogleAuth(error);
          });
      } catch (error: any) {
        FirebaseAuthentication.signOut();
        toast(error.message);
      }
    }
  };

  const loginWithApple = () => {
    //@ts-ignore
    gtag?.('event', 'login', { method: 'apple' });
    try {
      FirebaseAuthentication.signInWithApple({ scopes: ['email'] })
        .then((firebaseResult: any) => {
          setPageLoader(true);
          firebaseResult.language = storedLanguage ? storedLanguage : 'en';
          if (Capacitor.isNativePlatform()) {
            firebaseResult.code = Capacitor.isNativePlatform()
              ? firebaseResult.credential.authorizationCode
              : firebaseResult.credential.accessToken;
          }
          appleMutation.mutate(firebaseResult, {
            async onSuccess(data, variables, context) {
              if (data.username) {
                if (Capacitor.isNativePlatform()) {
                  firebaseNotificationsRegister();
                }
                store('driverbook_apple_user', true);
                store(data);
                store(
                  'apple_id',
                  firebaseResult.additionalUserInfo.profile.id || firebaseResult.additionalUserInfo.profile.sub
                );
                const isConfirm = await checkConfirmEmail();
                if (!isConfirm) return;
                if (from.pathname === '') from.pathname = 'stories';
                history.replace(from);
              } else if (data.accessToken) {
                store(
                  'apple_id',
                  firebaseResult.additionalUserInfo.profile.id || firebaseResult.additionalUserInfo.profile.sub
                );
                store('driverbook_apple_user', true);
                store(data);
                setUser(firebaseResult);
              } else {
                setLoading(false);
                toast(capitalize(t(tKey(data?.error ?? ''))));
              }
              setPageLoader(false);
            },
          });
        })
        .catch((error) => {
          handleErrorGoogleAuth(error);
          setPageLoader(false);
        });
    } catch (error) {
      FirebaseAuthentication.signOut();
      toast(t('__common__error'));
      setPageLoader(false);
    }
  };

  const postAuth = (result: any, data: any) => {
    setPageLoader(true);
    store('google_id', data.google_id);
    googleMutation.mutate(data, {
      async onSuccess(data) {
        if (data.username) {
          store(data);
          store('driverbook_google_user', true);
          setLoading(false);
          if (Capacitor.isNativePlatform()) {
            firebaseNotificationsRegister();
          }
          const isConfirm = await checkConfirmEmail();
          if (!isConfirm) return;
          if (from.pathname === '') from.pathname = 'stories';
          history.replace(from);
        } else if (data.accessToken) {
          store(data);
          setUser(result);
        } else {
          setLoading(false);
          toast(capitalize(t(tKey(data?.error ?? ''))));
          FirebaseAuthentication.signOut();
          signOut(auth);
        }
        setPageLoader(false);
      },
    });
  };

  const handleEmailUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailUsernameValue(event.target.value.toLowerCase());
  };

  const unlockResend = () => {
    setUnlockEmailSent(true);
    unlockMutation.mutate(
      { email_username: emailUsernameValue },
      {
        onSuccess(data) {
          if (data.success) {
            toast(t(tKey('blocked_resend_sent')));
          } else if (data.error === 'limit_exceeded') {
            toast(t(tKey('try_later')));
          }
        },
      }
    );
  };

  return (
    <LeanLayout>
      <div className={classes.formContainer}>
        <Typography variant='h4' align='center' className={classes.title}>
          {t(tKey('title'))}
        </Typography>
        <Typography variant='body2' align='center' className={classes.subtitle}>
          {t(tKey('description'))}
        </Typography>
        {user ? (
          <SocialUsernameForm
            user={user}
            isApple={user.additionalUserInfo.providerId === 'apple.com'}
            isGoogle={user.additionalUserInfo.providerId === 'google.com'}
          />
        ) : (
          <>
            <Form defaultValues={defaultValues} schema={validationSchema(t)} onSubmit={submitLoginForm}>
              <FormErrorsCleaner />
              <div className={classes.inputLabel}>{t(tKey('email_or_username'))}</div>
              <TextInput
                name='email_username'
                label={''}
                inputProps={{
                  autoCapitalize: 'none',
                  className: classes.inputStyle,
                  onChange: handleEmailUsernameChange,
                }}
                textFieldProps={{ value: emailUsernameValue, autoComplete: 'email' }}
              />
              {!user && (
                <>
                  <div className={classes.inputLabel}>{t(tKey('password'))}</div>
                  <PasswordInput name='password' label={''} inputPropsClassName={classes.inputStyle} />
                </>
              )}
              {blocked && (
                <>
                  <Box textAlign='center' mt={4} mb={4}>
                    <div className={classes.blockedWrapper}>
                      <div className={classes.blockedTitle}>{t(tKey('blocked_title'))}</div>
                      <div>
                        <span className={classes.resendText}>{t(tKey('blocked_text'))}</span>
                        <Button
                          variant='text'
                          color='secondary'
                          onClick={unlockResend}
                          className={classes.unlockResendButton}
                          disabled={unlockEmailSent}
                        >
                          {t(tKey('blocked_button'))}
                        </Button>
                      </div>
                    </div>
                  </Box>
                </>
              )}
              {user ? (
                <Box textAlign='center' mt={4} mb={4}>
                  <StyledButton label={t(tKey('continue'))} type='submit' loading={loading} />
                </Box>
              ) : (
                <>
                  <Box textAlign='center' mt={4} mb={4}>
                    <StyledButton label={t(tKey('submit'))} type='submit' loading={loading} />
                  </Box>
                  <Box textAlign='center' mt={4} mb={4}>
                    <StyledButton label={t(tKey('google_login'))} onClick={loginWithGoogle} icon={googleIcon} />
                  </Box>
                  <Box textAlign='center' mt={4} mb={4}>
                    <AppleSignInButton onClick={loginWithApple} label={t(tKey('apple_login'))} />
                  </Box>
                </>
              )}
              {formError && (
                <FormHelperText margin='dense' classes={{ root: classes.formError }} error>
                  {t(formError)}
                </FormHelperText>
              )}
              {pageLoader && <PageLoader />}
            </Form>
            <Box mt={4} textAlign='center'>
              {t(tKey('no_account'))}
              <Button className={classes.btn} variant='text' color='secondary' onClick={() => history.push('/signup')}>
                {t(tKey('register'))}
              </Button>
            </Box>
            <Box mt={1} textAlign='center'>
              <Button
                className={classes.btn}
                variant='text'
                color='secondary'
                onClick={() => history.push('/forgot-password')}
              >
                {t(tKey('forgot_password'))}
              </Button>
            </Box>{' '}
          </>
        )}
      </div>
    </LeanLayout>
  );
};
