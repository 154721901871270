import { ComponentType, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import store from 'store2';
import secureLocalStorage from 'react-secure-storage';
import { Capacitor } from '@capacitor/core';
import { firebaseNotificationsUnregister } from 'utils/firebaseNotifications';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

export const LogoutTab: ComponentType = () => {
  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'logout', { method: 'logout' });

    return () => {
      if ((store.get('googleAccessToken') || store.get('driverbook_apple_user')) && Capacitor.isNativePlatform()) {
        FirebaseAuthentication.signOut();
      }
      store.remove('accessToken');
      store.remove('refreshToken');
      store.remove('driverbook_google_user');
      store.remove('driverbook_apple_user');
      store.remove('googleAccessToken');
      store.remove('appleAccessToken');
      store.remove('google_id');
      store.remove('apple_id');
      secureLocalStorage.removeItem('app_id_hash');
      if (Capacitor.isNativePlatform()) {
        firebaseNotificationsUnregister();
      }
    };
  }, []);

  return <Redirect to='/login' />;
};
