import { Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AssistedRecordsHeader, StyledButton, Wrapper } from 'components';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ spacing }: Theme) => ({
  root: {
    textAlign: 'center',
    marginTop: spacing(15),
  },
  emoji: {
    fontSize: 100,
  },
  title: {
    marginBottom: spacing(2),
    fontSize: 16,
  },
});

export const AssistedRecordsStatusPage: ComponentType<{ type: 'success' | 'error' }> = ({ type }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();

  return (
    <>
      <AssistedRecordsHeader notification={true} />
      <Wrapper>
        <div className={classes.root}>
          {type === 'success' ? (
            <>
              <span className={classes.emoji}>😊</span>
              <Typography className={classes.title}>{t('assisted_record_success')}</Typography>
            </>
          ) : (
            <>
              <span className={classes.emoji}>😮</span>
              <Typography className={classes.title}>{t('assisted_record_link_expired')}</Typography>
            </>
          )}
          <StyledButton label={t('assisted_record_go_home')} onClick={() => history.push('/')} />
        </div>
      </Wrapper>
    </>
  );
};
