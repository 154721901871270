import { Theme } from '@mui/material';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { useCarQuery } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('breadcrumbs');

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    display: 'none',
    alignItems: 'center',
  },
  label: {
    display: 'inline-block',
    maxWidth: 140,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: palette.primary.main,
    textDecoration: 'none',

    '&::after': {
      margin: spacing(0, 0.5),
      content: '"/"',
    },
  },
  text: {
    color: palette.text.hint,
  },
});

const DynamicBreadcrumb: ComponentType<{ match: any }> = ({ match }) => {
  const { data: car } = useCarQuery(match.params.carId);

  if (car) {
    return <span>{`${capitalize(car.make)} ${car.model}`}</span>;
  }

  return null;
};

const SimpleBreadcrumb: ComponentType<{ breadcrumbTranslationKey: string }> = ({ breadcrumbTranslationKey }) => {
  const { t } = useTranslation();

  return <span>{t(tKey(breadcrumbTranslationKey))}</span>;
};

const CroppedBreadcrumb: ComponentType<{ match: any }> = ({ match }) => {
  return <span>{match.params.recordId.slice(0, 5)}...</span>;
};

const routes = [
  { path: '/calculator', breadcrumb: () => SimpleBreadcrumb({ breadcrumbTranslationKey: 'calculator' }) },
  { path: '/dashboard', breadcrumb: () => SimpleBreadcrumb({ breadcrumbTranslationKey: 'dashboard' }) },
  { path: '/dashboard/:carId', breadcrumb: DynamicBreadcrumb },
  { path: '/cars', breadcrumb: () => SimpleBreadcrumb({ breadcrumbTranslationKey: 'cars' }) },
  { path: '/cars/new', breadcrumb: () => SimpleBreadcrumb({ breadcrumbTranslationKey: 'create_car' }) },
  { path: '/cars/:carId', breadcrumb: DynamicBreadcrumb },
  { path: '/cars/:carId/edit', breadcrumb: () => SimpleBreadcrumb({ breadcrumbTranslationKey: 'edit' }) },
  { path: '/records', breadcrumb: () => SimpleBreadcrumb({ breadcrumbTranslationKey: 'records' }) },
  { path: '/records/:carId', breadcrumb: DynamicBreadcrumb },
  { path: '/records/:carId/edit', breadcrumb: () => SimpleBreadcrumb({ breadcrumbTranslationKey: 'edit' }) },
  { path: '/records/:carId/edit/:recordId', breadcrumb: CroppedBreadcrumb },
  { path: '/records/new', breadcrumb: () => SimpleBreadcrumb({ breadcrumbTranslationKey: 'create_record' }) },
];

export const Breadcrumbs: ComponentType = () => {
  const classes = useClasses(styles);
  const { pathname } = useLocation();
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <div className={classes.root}>
      {breadcrumbs.map(({ match, breadcrumb }) => {
        return match.url !== '/' && pathname !== match.url ? (
          <NavLink key={match.url} to={match.url} className={classes.link}>
            <span className={classes.label}>{breadcrumb}</span>
          </NavLink>
        ) : pathname === match.url ? (
          <span key={match.url} className={clsx(classes.label, classes.text)}>
            {breadcrumb}
          </span>
        ) : null;
      })}
    </div>
  );
};
