import { Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { StyledButton, Wrapper } from 'components';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    textAlign: 'center',
    margin: spacing(15, 2),
  },
  emoji: {
    fontSize: 100,
  },
  title: {
    marginBottom: spacing(2),
    fontSize: 16,
  },
});

const tKey = getTKey('public');

export const PublicRecordErrorPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();

  return (
    <Wrapper>
      <div className={classes.root}>
        <>
          <span className={classes.emoji}>😮</span>
          <Typography className={classes.title}>{t(tKey('record_link_expired'))}</Typography>
        </>
        <StyledButton label={t(tKey('home_screen'))} onClick={() => history.push('/')} />
      </div>
    </Wrapper>
  );
};
