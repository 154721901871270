import { getUsersPayload } from 'features/admin-panel/components/AdminDashboardTypes';
import { useQuery, UseQueryOptions } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

export const getUsers = async (body: getUsersPayload) => {
  try {
    const { data } = await api.get(`/engineroom/users${body.usernameOrId ? '/search' : ''}`, {
      params: mapKeysToSnakeCase(body),
    });
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useUsersQuery = (body: getUsersPayload, options?: UseQueryOptions<any>) => {
  return useQuery('adminGetUsers', () => getUsers(body), options);
};
