import { ComponentType } from 'react';

import { Chip, ListItem, Theme } from '@mui/material';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { Car } from 'types';
import { normalizeChipName } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    minWidth: 320,
    padding: spacing(2),
    borderRadius: spacing(0.5),
    backgroundColor: '#4B01D0',
    cursor: 'pointer',
    opacity: 0.6,

    '&:not(:last-of-type)': {
      marginRight: spacing(2),
    },

    [breakpoints.up('md')]: {
      flexShrink: 1,
      minWidth: 'auto',
      marginBottom: spacing(2),
    },
  },
  active: {
    opacity: 1,
  },
  image: {
    flexShrink: 0,
    width: 44,
    height: 44,
    marginRight: spacing(2),
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#ccc',
  },
  info: {
    flexGrow: 1,
    maxWidth: 'calc(100% - 60px)',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
    },
  },
  make: {
    textTransform: 'capitalize',
  },
  dot: {
    fontSize: 16,
    color: palette.additionalPrimary['200'],
  },
  chips: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1),
    },
  },
});

interface RecordsCarItemProps {
  car: Car;
  isActive?: boolean;
  isSingle?: boolean;
  chips?: any;
  publicRecord?: boolean;
}

export const RecordsCarItem: ComponentType<RecordsCarItemProps> = ({
  car,
  isActive,
  isSingle,
  chips,
  publicRecord,
}) => {
  const classes = useClasses(styles);
  const history = useHistory();

  const redirectToRecordsPage = (): void => {
    history.push(`/records/${car.id}`);
  };

  return (
    <ListItem
      className={clsx(classes.root, { [classes.active]: isActive || isSingle })}
      onClick={publicRecord || isSingle ? undefined : redirectToRecordsPage}
      role='button'
      tabIndex={0}
    >
      <div className={classes.image} style={{ backgroundImage: car.image ? `url('${car.image}')` : undefined }} />
      <div className={classes.info}>
        <div className={classes.title}>
          <span className={classes.make}>{car.make}</span> {car.model}
        </div>
        <div className={classes.subtitle}>
          {car.year} <span className={classes.dot}>·</span> {car.mileage} {car.mileageMetric}
        </div>
      </div>
      {isSingle && (
        <div className={classes.chips}>
          {chips.map((chip: number | string) => (
            <Chip key={chip} label={normalizeChipName(chip)} />
          ))}
        </div>
      )}
    </ListItem>
  );
};
