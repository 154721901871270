import {
  CheckCircle,
  ContentCopy,
  Delete,
  Edit,
  EventRepeat,
  Link,
  Notes,
  RadioButtonUnchecked,
  Tag,
} from '@mui/icons-material';
import { Box, Checkbox, IconButton, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Reminder } from 'types';
import { useClasses } from 'utils/hooks/useClasses';
import { useCompleteReminder } from '../hooks/useCompleteReminder';
import { useHistory, useParams } from 'react-router-dom';
import { useDeleteReminder } from '../hooks/useDeleteReminder';
import { DeleteReminderDialog } from './DeleteReminderDialog';
import { DetailsReminderDialog } from './DetailsReminderDialog';
import { formatNumberMileageK } from 'utils';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    borderBottom: '1px solid #FFFFFF1F',
    padding: spacing(1, 2, 2),
    display: 'flex',
    width: '100%',
    [breakpoints.up('md')]: {
      '&:hover': {
        '& .actions': {
          display: 'block',
        },
      },
    },

    '& .actions': {
      display: 'none',
    },
  },
  main: {
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    height: 40,
  },
  title: {
    flex: 1,
  },
  body: {
    width: '100%',
  },
  checkbox: {
    left: spacing(-1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    '& svg': {
      width: 15,
    },
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
  },

  bottomRow: {
    '& svg:first-child': {
      margin: spacing(0, 0.5, 0, 0),
    },
    '& svg': {
      margin: spacing(0, 0.5),
    },
  },
  iconRepeat: {
    margin: spacing(0, 1),
  },
});

interface ReminderItemProps {
  reminder: Reminder;
  changeCompleteReminderById: (id: string) => void;
  refetch: () => void;
}

export const ReminderItem: ComponentType<ReminderItemProps> = ({ reminder, changeCompleteReminderById, refetch }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const history = useHistory();
  const { reminderId } = useParams<{ carId: string; reminderId: string }>();
  const [checked, setChecked] = useState(reminder.is_completed);

  const { completeReminder, incompleteReminder } = useCompleteReminder();
  const { deleteReminder } = useDeleteReminder();

  useEffect(() => {
    if (reminderId === reminder.id) {
      onOpenDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onComplete = (event: any) => {
    if (checked) {
      incompleteReminder({ carId: reminder.car_id as string, reminderId: reminder.id as string }).then(
        (result: any) => {
          if (result.message === 'success') {
            setChecked((state) => !state);
            changeCompleteReminderById(reminder.id as string);
          }
        }
      );
    } else {
      completeReminder({ carId: reminder.car_id as string, reminderId: reminder.id as string }).then((result: any) => {
        if (result.message === 'success') {
          setChecked((state) => !state);
          changeCompleteReminderById(reminder.id as string);
        }
      });
    }
  };

  const onCopy = () => {
    history.push(`/reminders/${reminder.car_id}/${reminder.id}/copy`);
  };

  const onEdit = () => {
    history.push(`/reminders/${reminder.car_id}/edit/${reminder.id}`);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onDeleteOpenDialog = () => {
    setOpenDeleteDialog(true);
  };

  const onCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const onDelete = () => {
    deleteReminder({ carId: reminder.car_id as string, reminderId: reminder.id as string }).then((data) => {
      refetch();
    });
  };

  const { i18n } = useTranslation();

  const getLang = () => {
    return i18n.language;
  };
  const lang = getLang();
  try {
    if (lang === 'uk') {
      dayjs.locale(require(`dayjs/locale/uk`));
    } else if (lang === 'es') {
      dayjs.locale(require(`dayjs/locale/es`));
    } else {
      dayjs.locale(require(`dayjs/locale/en`));
    }
  } catch (e) {
    console.log(`Cannot load ${lang} language. Will be used english language`);
  }

  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const onOpenDetails = () => {
    if (!isTitleOnly) {
      setOpenDetailsDialog(true);
      history.replace(`/reminders/${reminder.car_id}/${reminder.id}/details`);
    }
  };
  const onCloseDetails = () => {
    setOpenDetailsDialog(false);
    history.replace(`/reminders/${reminder.car_id}`);
  };

  const [isTitleOnly] = useState(
    reminder.reminder_type === null && !reminder.comment && !reminder.link && !reminder.category
  );

  return (
    <>
      <Box
        className={classes.root}
        sx={{ background: !checked ? '#FFFFFF0A' : 'unset', color: !checked ? '#FFFFFFA6' : '#FFFFFF73' }}
      >
        <Box className={classes.sideBar}>
          <Checkbox
            id={'completeReminder' + reminder.id}
            className={classes.checkbox}
            checked={checked}
            icon={<RadioButtonUnchecked />}
            checkedIcon={<CheckCircle />}
            onChange={onComplete}
          />
        </Box>
        <Box className={classes.main}>
          <Box className={classes.header}>
            <Typography
              className={classes.title}
              onClick={onOpenDetails}
              sx={{ cursor: isTitleOnly ? 'default' : 'pointer' }}
            >
              {reminder.title}
            </Typography>
            <Box className={clsx(classes.actions, 'actions')}>
              <IconButton color='primary' onClick={onCopy}>
                <ContentCopy />
              </IconButton>
              <IconButton color='primary' onClick={onEdit}>
                <Edit />
              </IconButton>
              <IconButton color='error' onClick={onDeleteOpenDialog}>
                <Delete />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.body} onClick={onOpenDetails} sx={{ cursor: isTitleOnly ? 'default' : 'pointer' }}>
            <Box className={classes.row} sx={{ textTransform: 'uppercase', letterSpacing: '1.5px' }}>
              <>
                {reminder.remind_date && reminder.remind_date !== 'None' && (
                  <>
                    {dayjs(reminder.remind_date).add(dayjs().utcOffset(), 'minutes').format('DD MMMM, HH:mm')}
                    {reminder?.remind_every_date_type && reminder?.remind_every_date_type !== 'None' && (
                      <>
                        <EventRepeat className={classes.iconRepeat} />
                        {['day', 'week', 'month', 'year'].includes(reminder?.remind_every_date_type)
                          ? t('__recurrence_every_single')
                          : t('__recurrence_every_plurals')}{' '}
                        {t('reminder_form_remind_type_' + reminder?.remind_every_date_type)}
                      </>
                    )}
                  </>
                )}
              </>
            </Box>
            <Box className={classes.row} sx={{ textTransform: 'uppercase', letterSpacing: '1.5px' }}>
              {reminder.remind_mileage && reminder.remind_mileage !== 'None' && (
                <>
                  {t('__recurrence_when')} {formatNumberMileageK(reminder.remind_mileage as string)} KM
                  {reminder?.to_repeat_mileage !== '0' && reminder?.to_repeat_mileage !== 'None' && (
                    <>
                      <EventRepeat className={classes.iconRepeat} />
                      {t('__recurrence_every_plurals')} {formatNumberMileageK(reminder.to_repeat_mileage as string)} KM
                    </>
                  )}
                </>
              )}
            </Box>
            <Box className={clsx(classes.row, classes.bottomRow)}>
              {reminder.link && (
                <>
                  <Link /> {t('add_part_reminder_link')}
                </>
              )}
              {reminder.comment && (
                <>
                  <Notes /> {t('add_part_reminder_notes')}
                </>
              )}

              {reminder.category &&
                reminder.category.split(',').map((tag) => {
                  return (
                    <Box key={tag} display='flex' alignItems='center' ml={0.5}>
                      <Tag sx={{ marginRight: '2px !important' }} /> {t(`__record_category__${tag}`)}{' '}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
        <DeleteReminderDialog open={openDeleteDialog} cancel={onCancelDelete} remove={onDelete} />
        <DetailsReminderDialog
          open={openDetailsDialog}
          close={onCloseDetails}
          reminder={reminder}
          remove={() => {
            setOpenDetailsDialog(false);
            onDelete();
          }}
        />
      </Box>
    </>
  );
};
