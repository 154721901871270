import { getCommentsPayload } from 'features/admin-panel/components/AdminDashboardTypes';
import { useQuery, UseQueryOptions } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';



export const getComments = async (body: getCommentsPayload) => {
  try {
    const { data } = await api.get(`/engineroom/comments`, { params: mapKeysToSnakeCase(body) });
    return  data;
  } catch (err) {
    return { error: true };
  }
};

export const useCommentsQuery = (body: getCommentsPayload, options: UseQueryOptions<any>) => {
  return useQuery('adminGetComments', () => getComments(body), options)
};
