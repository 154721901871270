import { SvgIconComponent } from '@mui/icons-material';
import { Badge, Theme } from '@mui/material';
import { pulse } from 'animations/Pulse';
import clsx from 'clsx';
import React, { ComponentType, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { isLoggedIn } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(0.5),
    padding: spacing(1, 1.5),
    color: palette.text.primary,
    fontSize: 16,
    fontWeight: 600,
    textDecoration: 'none',

    '&.selected': {
      backgroundColor: 'rgba(187,134,252,0.08)',
      color: palette.primary.main,
      borderRadius: spacing(0.5),

      '& $icon': {
        fill: palette.primary.main,
      },
    },

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  icon: {
    marginRight: spacing(2),
    fill: palette.text.hint,
  },
  badge: {
    '& span': {
      // right: 20,
      // top: 6,
      outline: '1px solid #27212f',
    },
  },
  pulse: {
    '& span:before': {
      content: '""',
      position: 'absolute',
      border: '2px solid #d32f2f',
      left: -1,
      right: -1,
      top: -1,
      bottom: -1,
      borderRadius: '50%',
      animation: `${pulse[0]} 1.5s infinite`,
    },
  },
  unAuth: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface MenuButtonProps {
  startIcon: SvgIconComponent | React.FunctionComponent;
  text: string;
  to: string;
  badge?: boolean;
  pulse?: boolean;
  isAlert?: boolean;
  openUnregisteredPopup?: (from: string) => void;
  badgeStyles?: any;
}
export const MenuButton: ComponentType<MenuButtonProps> = ({
  startIcon: StartIcon,
  text,
  to,
  isAlert,
  badge,
  pulse,
  openUnregisteredPopup,
  badgeStyles,
}) => {
  const classes = useClasses(styles);
  const isAuth = isLoggedIn();

  useEffect(() => {}, [badge]);

  const onOpenUnregisteredPopup = (event: any) => {
    event.preventDefault();
    if (openUnregisteredPopup) openUnregisteredPopup(to);
  };

  return isAuth ? (
    <NavLink to={to} className={classes.root} activeClassName='selected'>
      {isAlert ? (
        <Badge
          color='error'
          variant='dot'
          invisible={!badge}
          className={clsx(classes.badge, [pulse && classes.pulse])}
          sx={badgeStyles}
        >
          <StartIcon className={classes.icon} />
        </Badge>
      ) : (
        <StartIcon className={classes.icon} />
      )}
      {text}
    </NavLink>
  ) : (
    <NavLink onClick={onOpenUnregisteredPopup} to={to} className={classes.root} activeClassName='selected'>
      <StartIcon className={classes.icon} />
      {text}
    </NavLink>
  );
};
