import Lottie from 'lottie-react';
import animationData from 'components/SpinFeedLoader/loader.json';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import { Box, Button, Divider, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { AddStoryContent } from 'components/Stories/AddStoryContent';
import { CarList } from 'components/Stories/CarList';
import { Preview } from 'components/Stories/Preview';
import { SelectCoverImage } from 'components/Stories/SelectCoverImage';
import { UnfinishedDraftDialog } from 'components/Stories/UnfinishedDraftDialog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { useCreateStory } from './hooks/useCreateStory';
import { InnerMobileHeader } from 'components';
import { SaveDraftDialog } from 'components/Stories/SaveDraftDialog';
import { useHistory } from 'react-router-dom';
import { EmptyGarage } from 'components/Stories/EmptyGarage';
import { StoryPublishDialog } from 'components/StoryPublishDialog/StoryPublishDialog';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    margin: spacing(1, 0, 2.5),
    width: '25%',
  },
  dividerActive: {
    backgroundColor: 'rgba(3, 218, 197, 1)',
  },
  dividerInactive: {
    backgroundColor: 'rgba(3, 218, 197, 0.6)',
  },
  buttonRoot: {
    gap: 10,
    padding: 0,
    fontSize: 14,
    color: 'white',
  },
  button: {
    color: 'rgba(72,72,72, 1) !important',
  },
  formContainer: {
    marginTop: spacing(2),
  },
  spinnerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(18,18,18,0.4)',
    width: '100%',
    height: '100%',
  },
  spinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const tKey = getTKey('create_story');

const steps = ['select_car', 'add_content', 'select_story_cover', 'preview'];

export const CreateStoryForm = () => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const history = useHistory();
  const {
    elements,
    activeStep,
    recordGroup,
    records,
    title,
    coverIndex,
    disabled,
    cars,
    selectedCar,
    carDraft,
    unfinishedDraft,
    carDraftLoading,
    isLoading,
    loading,
    descLength,
    leaveDraft,
    saving,
    recordRefetch,
    setUnfinishedDraft,
    transformDraftNext,
    setDisabled,
    setElements,
    // setActiveStep,
    setCover,
    addDescription,
    addGallery,
    addRecord,
    addText,
    addVideo,
    addRecordGroup,
    setSelectedCar,
    handleNext,
    updateDraft,
    removeElement,
    setTitle,
    refetch,
    clearState,
    onDeleteDraft,
    handlePrevious,
    setLeaveDraft,
    autoSelectCover,
    setSaving,
  } = useCreateStory();

  useEffect(() => {
    if (activeStep === 0 && !carDraftLoading && carDraft && carDraft?.id) {
      setUnfinishedDraft(true);
    }

    if (activeStep === 0 && !carDraftLoading && carDraft && !carDraft?.id && selectedCar.car?.id === undefined) {
      clearState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carDraft, carDraftLoading]);

  useEffect(() => {
    if (activeStep === 1) {
      setDisabled(elements.length === 0);
    }
    if (selectedCar.car && activeStep === 0) {
      setDisabled(false);
    }
    if (activeStep === 3 && coverIndex.elementIndex === -1 && descLength === 0) {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCar, title, elements, activeStep]);

  useEffect(() => {
    if (activeStep === 2) {
      setDisabled(autoSelectCover());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (selectedCar.car?.id !== undefined) {
      refetch();
      recordRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCar.car]);

  const onCloseStoryForm = () => {
    if (activeStep !== 0 && (title !== '' || elements.length !== 0)) {
      setLeaveDraft(true);
    } else {
      history.go(-1);
    }
  };

  const onSaveDraft = async () => {
    setLoadingSaveDraft(true);
    const data = await updateDraft();
    setLoadingSaveDraft(false);
    if (data) {
      history.go(-1);
    }
  };
  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [previousLeave, setPreviousLeave] = useState(false);

  useEffect(() => {
    const onBeforeUnload = (e: any) => {
      if (activeStep !== 0 && (title !== '' || elements.length !== 0)) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    if (activeStep === 0) {
      window.removeEventListener('beforeunload', onBeforeUnload);
    } else {
      window.addEventListener('beforeunload', onBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [activeStep, title, elements]);

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <InnerMobileHeader
        title={t(tKey('title'))}
        leftIcon={<Close width={16} height={16} />}
        showBack
        onLeftClick={onCloseStoryForm}
      />
      {unfinishedDraft && selectedCar.car?.id !== undefined && (
        <UnfinishedDraftDialog
          onConfirm={() => transformDraftNext()}
          onCancel={async ({ deleteDraft }: { deleteDraft: boolean }) => {
            if (deleteDraft) {
              await onDeleteDraft();
            }
            setUnfinishedDraft(false);
          }}
          carName={`${selectedCar.car?.make} ${selectedCar.car?.model}`}
        />
      )}
      {leaveDraft && (
        <SaveDraftDialog
          onLeave={() => {
            setLeaveDraft(false);
            if (previousLeave) {
              handlePrevious();
              setPreviousLeave(false);
              clearState();
            } else {
              history.go(-1);
            }
          }}
          onSave={onSaveDraft}
          onClose={() => setLeaveDraft(false)}
          loading={loadingSaveDraft}
        />
      )}
      <Box className={classes.container}>
        {t(tKey(steps[activeStep]))}
        <Typography>
          {activeStep + 1}/{steps.length}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {steps.map((label, index) => {
          return (
            <Divider
              key={index}
              className={clsx(classes.divider, index <= activeStep ? classes.dividerActive : classes.dividerInactive)}
            />
          );
        })}
      </Box>
      <Box className={classes.container}>
        <Button
          disabled={activeStep === 0}
          classes={{ disabled: classes.button, root: classes.buttonRoot }}
          onClick={() => {
            if (activeStep === 1 && (title !== '' || elements.length !== 0)) {
              setLeaveDraft(true);
              setPreviousLeave(true);
            } else {
              handlePrevious();
            }
          }}
        >
          <ArrowBack /> {t(tKey('previous'))}
        </Button>
        <Button
          disabled={disabled}
          classes={{ disabled: classes.button, root: classes.buttonRoot }}
          onClick={handleNext}
        >
          {t(tKey(activeStep === 3 ? 'publish' : 'next'))} <ArrowForward />
        </Button>
      </Box>
      <Box className={classes.formContainer}>
        {activeStep === 0 &&
          !isLoading &&
          (cars?.length ? (
            <CarList cars={cars} selectedCar={selectedCar} setSelectedCar={setSelectedCar} />
          ) : (
            <EmptyGarage forStory={true} />
          ))}
        {activeStep === 1 && (
          <AddStoryContent
            carId={selectedCar.car?.id}
            elements={elements}
            setElements={setElements}
            addGallery={addGallery}
            addDescription={addDescription}
            addRecord={addRecord}
            addRecordGroup={addRecordGroup}
            addText={addText}
            recordGroup={recordGroup}
            records={records}
            title={title}
            descLength={descLength}
            setTitle={setTitle}
            updateDraft={updateDraft}
            removeElement={removeElement}
            addVideo={addVideo}
          />
        )}
        {activeStep === 2 && <SelectCoverImage elements={elements} setCover={setCover} coverIndex={coverIndex} />}
        {activeStep === 3 && (
          <Preview
            elements={elements}
            title={title}
            recordGroup={recordGroup}
            records={records}
            carId={selectedCar.car?.id}
            coverIndex={coverIndex}
          />
        )}
      </Box>
      <StoryPublishDialog
        open={saving}
        onClose={() => {
          setSaving(false);
        }}
      />
      {(isLoading || loading) && (
        <div className={classes.spinnerOverlay}>
          <div className={classes.spinner}>
            <Lottie animationData={animationData} />
          </div>
        </div>
      )}
    </Box>
  );
};
