import { EventRepeat, Notes, Link, Tag, Close, ArrowBack, ContentCopy, Edit, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as LinkReminder, useHistory } from 'react-router-dom';
import { Reminder } from 'types';
import { useClasses } from 'utils/hooks/useClasses';
import Mileage from 'assets/swap_driving_apps_wheel.svg';
import { theme } from 'theme';
import { DeleteReminderDialog } from './DeleteReminderDialog';
import { formatNumberMileageK } from 'utils';

const MileageIcon: ComponentType<{ width?: number; height?: number; className?: any }> = ({ className }) => (
  <img className={className} src={Mileage} alt='mileage' />
);

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      minWidth: 406,
    },
  },
  closeButton: {
    '& svg': {
      color: '#FFFFFF99',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
  },
  row: {
    display: 'flex',
    borderBottom: '1px solid #FFFFFF1F',
    padding: spacing(2, 0, 1),
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },
  icon: {
    width: 24,
    margin: spacing(0.5, 2, 0.5, 0),
    position: 'relative',
    top: -2,
  },
  iconMileage: {
    width: 20,
    margin: spacing(0, 2, 0, 0.25),
  },
  link: {
    color: palette.secondary.main,
    textDecoration: 'none',
  },
  repeat: {
    color: '#FFFFFFA6',
    fontSize: 14,
  },
  repeatType: {
    textTransform: 'lowercase',
  },
});
interface DetailsReminderDialogProp {
  open: boolean;
  close: () => void;
  reminder: Reminder;
  remove: () => void;
}

export const DetailsReminderDialog: ComponentType<DetailsReminderDialogProp> = ({ open, reminder, close, remove }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { i18n } = useTranslation();

  const getLang = () => {
    return i18n.language;
  };
  const lang = getLang();
  try {
    if (lang === 'uk') {
      dayjs.locale(require(`dayjs/locale/uk`));
    } else if (lang === 'es') {
      dayjs.locale(require(`dayjs/locale/es`));
    } else {
      dayjs.locale(require(`dayjs/locale/en`));
    }
  } catch (e) {
    console.log(`Cannot load ${lang} language. Will be used english language`);
  }

  const [categoriesArray] = useState(reminder?.category?.split(','));
  const onEdit = () => {
    history.push(`/reminders/${reminder.car_id}/edit/${reminder.id}`);
  };

  const onDelete = () => {
    setOpenDeleteDialog(true);
  };

  const onCopy = () => {
    history.push(`/reminders/${reminder.car_id}/${reminder.id}/copy`);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  return (
    <Dialog open={open} fullScreen={fullScreen} className={classes.root}>
      <DialogTitle>
        {fullScreen ? (
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <IconButton onClick={close} className={classes.closeButton}>
                <ArrowBack />
              </IconButton>
            </Box>
            <Box>
              <IconButton color='primary' onClick={onCopy}>
                <ContentCopy />
              </IconButton>
              <IconButton color='primary' onClick={onEdit}>
                <Edit />
              </IconButton>
              <IconButton color='error' onClick={onDelete}>
                <Delete />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box display='flex' justifyContent='end'>
            <IconButton onClick={close} className={classes.closeButton}>
              <Close />
            </IconButton>
          </Box>
        )}
        <Typography className={classes.title}>{reminder.title}</Typography>
      </DialogTitle>
      <DialogContent>
        {reminder.comment && (
          <Box className={classes.row}>
            <Box>
              <Notes className={classes.icon} />
            </Box>

            <Typography>{reminder.comment}</Typography>
          </Box>
        )}
        {reminder.link && (
          <Box className={classes.row}>
            <Box>
              <Link className={classes.icon} />
            </Box>
            <LinkReminder className={classes.link} to={{ pathname: reminder.link }} target='blank'>
              {reminder.link}
            </LinkReminder>
          </Box>
        )}

        {reminder.remind_date && reminder.remind_date !== 'None' && (
          <Box className={classes.row}>
            <Box>
              <EventRepeat className={classes.icon} />
            </Box>
            <Box>
              <Typography>
                {dayjs(reminder.remind_date).add(dayjs().utcOffset(), 'minutes').format('DD MMMM, HH:mm')}
              </Typography>
              {reminder?.remind_every_date_type && reminder?.remind_every_date_type !== 'None' && (
                <Typography className={classes.repeat}>
                  {['day', 'week', 'month', 'year'].includes(reminder?.remind_every_date_type)
                    ? t('__recurrence_every_single')
                    : t('__recurrence_every_plurals')}{' '}
                  <span className={classes.repeatType}>
                    {t('reminder_form_remind_type_' + reminder?.remind_every_date_type)}
                  </span>
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {reminder.remind_mileage && reminder.remind_mileage !== 'None' && (
          <Box className={classes.row}>
            <Box>
              <MileageIcon className={classes.iconMileage} />
            </Box>
            <Box>
              {t('__recurrence_when')} {formatNumberMileageK(reminder.remind_mileage as string)} km
              {reminder?.remind_before_mileage !== '0' && reminder?.remind_before_mileage !== 'None' && (
                <Typography className={classes.repeat}>
                  {' '}
                  {t('reminder_form_remind_before_label')}{' '}
                  {formatNumberMileageK(reminder.remind_before_mileage as string)} km
                </Typography>
              )}
              {reminder?.to_repeat_mileage !== '0' && reminder?.to_repeat_mileage !== 'None' && (
                <Typography className={classes.repeat}>
                  {' '}
                  {t('__recurrence_every_plurals')} {formatNumberMileageK(reminder.to_repeat_mileage as string)} km
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {reminder.category && (
          <Box className={classes.row}>
            <Box>
              <Tag className={classes.icon} />
            </Box>
            <Box>
              {reminder.category.split(',').map((tag, i) => {
                return (
                  <span key={i}>
                    {t(`__record_category__${tag}`)}
                    {i + 1 !== categoriesArray?.length ? ', ' : ''}
                  </span>
                );
              })}
            </Box>
          </Box>
        )}
      </DialogContent>
      {!fullScreen && (
        <DialogActions>
          <Button onClick={onCopy}>{t('__common__copy')} </Button>
          <Button onClick={onEdit}>{t('__common__edit')} </Button>
          <Button color='error' onClick={onDelete}>
            {t('__common__delete')}
          </Button>
        </DialogActions>
      )}
      <DeleteReminderDialog
        open={openDeleteDialog}
        remove={remove}
        cancel={() => {
          setOpenDeleteDialog(false);
        }}
      />
    </Dialog>
  );
};
