import { Box, Button, Chip, Theme, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { ComponentType } from 'react';

import { CommonLayout, InnerMobileHeader, PageLoader } from 'components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { countTotalMoneySpend, normalizeChipName } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { RecordItem, RecordsCarItem } from '../records/components';
import { useDashboardDetails } from './useDashboardDetails';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  wrapper: {
    maxWidth: 800,
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    marginRight: spacing(-2),
    paddingRight: spacing(2),
    whiteSpace: 'nowrap',
  },
  chips: {
    overflowX: 'auto',
    marginLeft: spacing(2),

    '& > *:not(:last-child)': {
      marginRight: spacing(1),
    },
  },
  back: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: spacing(2, 0),
  },
});

export const DashboardDetailsPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();
  const { car, carLoading, records, recordsLoading, chips, month } = useDashboardDetails();
  const loading = carLoading || recordsLoading;
  const totalExpenses = countTotalMoneySpend(records);
  const mileageMetric = car?.mileageMetric;

  return (
    <CommonLayout>
      {loading ? (
        <PageLoader />
      ) : (
        <div className={classes.wrapper}>
          <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
            <div className={classes.back}>
              <Typography variant='h6'>
                {t('breadcrumbs_records')} {t(`__month__${dayjs().set('month', month).format('MMMM')}`)}
              </Typography>
              <Button color='primary' onClick={() => history.go(-1)}>
                {t('dashboard_back_to')}
              </Button>
            </div>
          </Box>
          {car && (
            <>
              <Box sx={{ display: { md: 'block', xs: 'none' } }} mb={3}>
                <RecordsCarItem car={car} isSingle chips={chips} />
              </Box>

              <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
                <div className={classes.header}>
                  <InnerMobileHeader title={`${capitalize(car.make)}`} />
                  <div className={classes.chips}>
                    {chips.map((chip: string) => (
                      <Chip key={chip} label={normalizeChipName(chip)} />
                    ))}
                  </div>
                </div>
              </Box>
            </>
          )}
          {records?.map((record) => (
            <RecordItem key={record.id} data={record} mileageMetric={mileageMetric} totalExpenses={totalExpenses} />
          ))}
        </div>
      )}
    </CommonLayout>
  );
};
