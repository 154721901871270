import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import { ComponentType, Suspense, useEffect } from 'react';
import { Redirect, Route, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import store from 'store2';

import i18n from './i18n';
import ProfileProvider from './store/profile/store';
import { theme } from './theme';

import '@mui/styles';
import {
  // CountryRoute,
  PrivateRoute,
  ReactQueryClientProvider,
  ScrollToTop,
  Sockets,
  StyledToast,
} from 'components';
import { AdminRoute } from 'components/AdminRoute/AdminRoute';
import { AdminLoginPage } from 'features/account/AdminLoginPage';
import { UnconfirmedEmailPage } from 'features/account/UnconfirmedEmailPage';
import { AdminCommentsPage } from 'features/admin-panel/AdminCommentsPage';
import { AdminLogout } from 'features/admin-panel/AdminLogout';
import { AdminReviewPage } from 'features/admin-panel/AdminReviewPage';
import { AdminStoriesPage } from 'features/admin-panel/AdminStoriesPage';
import { AdminUsersPage } from 'features/admin-panel/AdminUsersPage';
import { AdminNewVersionPage } from 'features/admin-panel/components/AdminNewVersionPage';
import { AlertsPage } from 'features/alerts/AlertsPage';
// import { GasCalculator } from 'features/calculator';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SocketsStory } from 'components/Sockets/SocketsStory';
import { SocialUsernamePage } from 'features/account/SocialUsernamePage';
import { ShareCarPage } from 'features/cars/ShareCarPage';
import { TransferCarPage } from 'features/cars/TransferCarPage';
import { PublicDashboardDetailsPage } from 'features/dashboard/PublicDashboardDetailsPage';
import { PublicDashboardPage } from 'features/dashboard/PublicDashboardPage';
import { CookiePolicy } from 'features/help/cookie-policy/CookiePolicy';
import { PrivacyPolicy } from 'features/help/privacy-policy/PrivacyPolicy';
import { TermsOfUse } from 'features/help/terms-of-use/TermsOfUse';
import { PublicRecordErrorPage } from 'features/records/PublicRecordErrorPage';
import { PublicRecordsPage } from 'features/records/PublicRecordsPage';
import { Documents } from 'features/settings/Tabs/Documents';
import { LogoutTab } from 'features/settings/Tabs/LogoutTab';
import { StoriesPage } from 'features/stories-feed/StoriesPage';
import { StoryPage } from 'features/stories-feed/StoryPage';
import { CreateStoryPage } from 'features/stories/CreateStoryPage';
import { EditStoryPage } from 'features/stories/EditStoryPage';
import { StoryPublishProvider } from 'features/stories/hooks/StoryPublishContext';
import { PublicUserPage } from 'features/users/PublicUserPage';
import { useUserIsBannedCreationQuery } from 'services/stories/useUserIsBannedQuery';
import { isLoggedIn } from 'utils';
// import { firebaseNotificationsListener } from 'utils/firebaseNotifications';
import MetaTags from 'components/MetaTags/MetaTegs';
import { InvoiceToRecordPage } from 'features/records/InvoinceToRecordPage';
import { SiriVoiceToRecordPage } from 'features/records/SiriVoiceToRecordPage';
import { VoiceToRecordPage } from 'features/records/VoiceToRecordPage';
import { ReminderCreatePage } from 'features/reminders/ReminderCreatePage';
import { RemindersDashboardPage } from 'features/reminders/RemindersDashboardPage';
import { RemindersRootPage } from 'features/reminders/RemindersRootPage';
import { useTranslation } from 'react-i18next';
import { FirebaseNotificationsComponent } from 'utils/firebaseNotificationsComp';
import { usePwa } from 'utils/pwa';
import { ConfirmEmailPage } from './features/account/ConfirmEmailPage';
import { ForgotPasswordPage } from './features/account/ForgotPasswordPage';
import { LoginPage } from './features/account/LoginPage';
import { RegistrationPage } from './features/account/RegistrationPage';
import { RegistrationSuccessPage } from './features/account/RegistrationSuccessPage';
import { ResetPasswordPage } from './features/account/ResetPasswordPage';
import { AssistedRecordsPage } from './features/assisted-records/AssistedRecordsPage';
import { AssistedRecordsStatusPage } from './features/assisted-records/AssistedRecordsStatusPage';
import { CreateAssistedRecordPage } from './features/assisted-records/CreateAssistedRecordPage';
import { EditAssistedRecordPage } from './features/assisted-records/EditAssistedRecordPage';
import { CarsPage } from './features/cars/CarsPage';
import { CreateCarPage } from './features/cars/CreateCarPage';
import { EditCarPage } from './features/cars/EditCarPage';
import { DashboardDetailsPage } from './features/dashboard/DashboardDetailsPage';
import { DashboardPage } from './features/dashboard/DashboardPage';
import { CreateRecordPage } from './features/records/CreateRecordPage';
import { EditRecordPage } from './features/records/EditRecordPage';
import { GenerateAssistedRecordPage } from './features/records/GenerateAssistedRecordPage';
import { RecordsDashboardPage } from './features/records/RecordsDashboardPage';
import { RecordsRootPage } from './features/records/RecordsRootPage';
import { SettingsPage } from './features/settings/SettingsPage';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
  interface TypeText {
    hint: string;
  }
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

interface StoryCreateRouteProps extends RouteProps {
  component: ComponentType;
}

const StoryCreateRoute: ComponentType<StoryCreateRouteProps> = ({ component: Component, ...rest }) => {
  const isAuth = isLoggedIn();
  const { data: isBanned, isFetched: isFetchedStoryBanned } = useUserIsBannedCreationQuery();
  if (isAuth && isFetchedStoryBanned && isBanned.is_story_banned === true) {
    return <Redirect to='/stories/recent' />;
  }
  return <PrivateRoute path='/stories/new' component={CreateStoryPage} />;
};

const storedLanguage = store('driverbook_language');
if (storedLanguage) i18n.changeLanguage(storedLanguage as string);

export const App: ComponentType = () => {
  const isPwa = usePwa();
  const { t } = useTranslation();

  useEffect(() => {
    if (isPwa) {
      //@ts-ignore
      gtag?.('event', 'using_pwa', { method: 'using_pwa' });
    } else {
      //@ts-ignore
      gtag?.('event', 'using_browser', { method: 'using_browser' });
    }
  }, [isPwa]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });
      // firebaseNotificationsListener();
    }
    return () => {
      if (Capacitor.isNativePlatform()) {
        CapacitorApp.removeAllListeners();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <StyledToast />

            <ReactQueryClientProvider>
              <ProfileProvider>
                <StoryPublishProvider>
                  <Router>
                    {Capacitor.isNativePlatform() && <FirebaseNotificationsComponent />}
                    <Sockets />
                    <SocketsStory />
                    <ScrollToTop />
                    <MetaTags title={t('app_title')} description={t('app_description')} />
                    <Switch>
                      <PrivateRoute path='/cars' component={CarsPage} exact />
                      <PrivateRoute path='/cars/:carId/edit' component={EditCarPage} />
                      <PrivateRoute path='/siri-voice/:carId' component={SiriVoiceToRecordPage} />
                      <PrivateRoute path='/cars/new' component={CreateCarPage} />
                      <PrivateRoute path='/cars/:carId/transfer' component={TransferCarPage} />
                      <PrivateRoute path='/cars/:carId/share' component={ShareCarPage} />
                      <PrivateRoute path='/records/:carId/edit/:recordId' component={EditRecordPage} />
                      <PrivateRoute path='/records/:carId/generate' component={GenerateAssistedRecordPage} />
                      <PrivateRoute path='/records/:carId/new' component={CreateRecordPage} />
                      <PrivateRoute path='/records/:carId/:recordId/copy' component={CreateRecordPage} />
                      <PrivateRoute path='/records/:carId/invoice' component={InvoiceToRecordPage} />
                      <PrivateRoute path='/records/:carId/voice' component={VoiceToRecordPage} />
                      <PrivateRoute path='/records/:carId' component={RecordsDashboardPage} />
                      <PrivateRoute path='/records' component={RecordsRootPage} />
                      <PrivateRoute path='/dashboard' component={DashboardPage} exact />
                      <PrivateRoute path='/dashboard/:carId' component={DashboardDetailsPage} />
                      <PrivateRoute path='/settings/logout' component={LogoutTab} />
                      <PrivateRoute path='/documents' component={Documents} />
                      <PrivateRoute path='/settings' component={SettingsPage} />
                      <StoryCreateRoute path='/stories/new' component={CreateStoryPage} />
                      <PrivateRoute path='/stories/edit/:carId/:storyId' component={EditStoryPage} />
                      {/* <CountryRoute path='/calculator' component={GasCalculator} /> */}
                      <PrivateRoute path='/confirm-your-email' component={UnconfirmedEmailPage} />
                      <PrivateRoute path='/alerts' component={AlertsPage} />
                      <PrivateRoute path='/user/:userId' component={PublicUserPage} />
                      <Route
                        path='/assisted/error'
                        component={() => <AssistedRecordsStatusPage type='error' />}
                        exact
                      />
                      <Route
                        path='/assisted/success'
                        component={() => <AssistedRecordsStatusPage type='success' />}
                        exact
                      />
                      <Route path='/assisted/:identifier/:carId/edit/:recordId' component={EditAssistedRecordPage} />
                      <Route path='/assisted/:identifier/:carId/new' component={CreateAssistedRecordPage} />
                      <Route path='/assisted/:identifier' component={AssistedRecordsPage} />
                      <Route path='/public/error' component={PublicRecordErrorPage} />
                      <Route path='/book/dashboard/details/:identifier' component={PublicDashboardDetailsPage} />
                      <Route path='/book/dashboard/:identifier' component={PublicDashboardPage} />
                      <Route path='/book/:identifier' component={PublicRecordsPage} />
                      <Route path='/login' component={LoginPage} />
                      <Route path='/complete-signup' component={SocialUsernamePage} />
                      <Route path='/signup' component={RegistrationPage} />
                      <Route path='/signup-success' component={RegistrationSuccessPage} exact />
                      <Route path='/confirm-email/:token' component={ConfirmEmailPage} />
                      <Route path='/forgot-password' component={ForgotPasswordPage} exact />
                      <Route path='/reset/:token' component={ResetPasswordPage} />
                      <Route path='/privacy-policy' component={PrivacyPolicy} exact />
                      <Route path='/cookie-policy' component={CookiePolicy} exact />
                      <Route path='/terms-of-use' component={TermsOfUse} exact />
                      <Route path='/stories/:carId/:storyId' component={StoryPage} />
                      <Route path='/stories/follow' component={StoriesPage} exact />
                      <Route path='/stories/news' component={StoriesPage} exact />
                      <Route path='/stories/:period' component={StoriesPage} />
                      <Route path='/stories' component={StoriesPage} exact />
                      <Route path='/reminders' component={RemindersRootPage} exact />
                      <Route path='/reminders/:carId' component={RemindersDashboardPage} exact />
                      <Route path='/reminders/:carId/:reminderId/details' component={RemindersDashboardPage} exact />
                      <Route path='/reminders/:carId/new' component={ReminderCreatePage} exact />
                      <Route path='/reminders/:carId/edit/:reminderId' component={ReminderCreatePage} exact />
                      <Route path='/reminders/:carId/:reminderId/copy' component={ReminderCreatePage} exact />
                      <Route path='/secret/machineroom/login' component={AdminLoginPage} />
                      <AdminRoute path='/secret/machineroom/review' component={AdminReviewPage} />
                      <AdminRoute path='/secret/machineroom/logout' component={AdminLogout} />
                      <AdminRoute path='/secret/machineroom/review' component={AdminReviewPage} />
                      <AdminRoute path='/secret/machineroom/stories' component={AdminStoriesPage} />
                      <AdminRoute path='/secret/machineroom/comments' component={AdminCommentsPage} />
                      <AdminRoute path='/secret/machineroom/users' component={AdminUsersPage} />
                      <AdminRoute path='/secret/machineroom/new-version' component={AdminNewVersionPage} />
                      <AdminRoute path='/secret/machineroom' component={AdminStoriesPage} />
                      <Route path='' exact component={StoriesPage}></Route>
                      <Redirect to='/stories/recent' />
                    </Switch>
                  </Router>
                </StoryPublishProvider>
              </ProfileProvider>
            </ReactQueryClientProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};
