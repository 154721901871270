import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useCarsQuery, useRecordsByCarQuery } from 'services';
import { Car, Record } from 'types';
import {
  aggregateExpensesByMonth,
  aggregateMileageByMonth,
  aggregateSpendingByCategory,
  aggregateSpendingByFrequency,
  calcAverageMonthlyExpenses,
  calcAverageMonthlyMileage,
  getAllCategories,
  getAllYears,
  getFilteredRecordsByCar,
  getTotalExpenses,
  getTotalMileage,
} from 'utils';

import { useLocationQuery } from 'lib';
import { useHistory } from 'react-router-dom';
import { BoardFilters } from './components/DashboardFilters';
import { Stats } from './components/DashboardStatItem';

interface DashboardPageData {
  loading: boolean;
  showFilters: boolean;
  showRecordsCharts: boolean;
  cars: Car[] | undefined;
  allYears: number[];
  allCategories: string[];
  filters: BoardFilters;
  expensesByMonth: number[];
  mileageByMonth: number[];
  averageMonthlyExpenses: number;
  averageMonthlyMileage: number;
  totalExpenses: number;
  totalMileage: number;
  spendingByCategory: Stats[];
  spendingByFrequency: Stats[];
  handleFiltersChange: (filters: Partial<BoardFilters>) => void;
  refetch: any;
}

type useDashboardPageType = () => DashboardPageData;
export const useDashboardPage: useDashboardPageType = () => {
  const { data: cars, isLoading: carsLoading } = useCarsQuery();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showRecordsCharts, setShowRecordsCharts] = useState<boolean>(false);
  const params = useLocationQuery();
  const history = useHistory();
  const date = dayjs();
  const carId = params.get('carId') || '';
  const year = Number(params.get('year')) || date.year();
  const category = params.get('category') || 'all';
  const [filters, setFilters] = useState<BoardFilters>(() => {
    return {
      carId,
      year,
      category,
    };
  });

  const [filteredRecords, setFilteredRecords] = useState<Record[]>([]);
  const [filteredRecordsForStats, setFilteredRecordsForStats] = useState<Record[]>([]);

  const { refetch, data: records, isLoading: recordsLoading } = useRecordsByCarQuery(filters.carId, 'dashboard', {
    enabled: !!filters.carId,
  });

  const loading = carsLoading || recordsLoading;
  const flattenRecords = records?.flat();
  const allYears = getAllYears(flattenRecords);
  const allCategories = getAllCategories(flattenRecords);

  const expensesByMonth = aggregateExpensesByMonth(filteredRecords);
  const mileageByMonth = aggregateMileageByMonth(filteredRecords);
  const averageMonthlyExpenses = calcAverageMonthlyExpenses(filteredRecords, filters.year);
  const averageMonthlyMileage = calcAverageMonthlyMileage(filteredRecords);
  const totalExpenses = getTotalExpenses(filteredRecords);
  const totalMileage = getTotalMileage(filteredRecords);
  const spendingByCategory = aggregateSpendingByCategory(filteredRecordsForStats);
  const spendingByFrequency = aggregateSpendingByFrequency(filteredRecordsForStats);

  useEffect(() => {
    if (filters.carId && carId !== filters.carId) {
      setFilters((prev) => ({ ...prev, carId }));
      handleFiltersChange({ carId: carId, category: 'all', year: date.year() });
    } else {
      setFilters({
        carId,
        year,
        category,
      });
    }
  }, [carId, year, category]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!carsLoading && cars && cars.length) {
      const firstCarId = cars[0].id;

      if (!carId) handleFiltersChange({ carId: firstCarId });
      setShowFilters(true);
    }
  }, [cars, carsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!recordsLoading && records && records.length) {
      const { filteredRecords: recordsAfterFilter, filteredRecordsForStats: statsRecords } = getFilteredRecordsByCar(
        filters,
        records
      );

      if (recordsAfterFilter.length > 0) {
        setFilteredRecords(recordsAfterFilter);
        setFilteredRecordsForStats(statsRecords);
        setShowRecordsCharts(true);
      } else {
        setShowRecordsCharts(false);
      }
    }

    if (!recordsLoading && records && records.length === 0) {
      setShowRecordsCharts(false);
    }
  }, [records, recordsLoading, filters]);

  const handleFiltersChange = (filter: Partial<BoardFilters>) => {
    history.push(
      `/dashboard?year=${filter.year ?? filters.year}&category=${filter.category ?? filters.category}&carId=${
        filter.carId ?? filters.carId
      }`
    );
  };

  return {
    loading,
    showFilters,
    showRecordsCharts,
    cars,
    allYears,
    allCategories,
    filters,
    handleFiltersChange,
    expensesByMonth,
    mileageByMonth,
    averageMonthlyExpenses,
    averageMonthlyMileage,
    totalExpenses,
    totalMileage,
    spendingByCategory,
    spendingByFrequency,
    refetch
  };
};
