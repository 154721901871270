import { ComponentType, useEffect, useState } from 'react';

import { Box, Grid, Theme } from '@mui/material';
import getOr from 'lodash/fp/getOr';
import { useFormContext, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Form, ImageUploader, RadioSwitch, SearchSelect, StyledButton, TextInputControlled } from 'components';
import { useCompressImage } from 'lib';
import { Car, CarOption, MappedCarMakeGroup } from 'types';
import { getTKey, yupNumberValidator } from 'utils';

import { useClasses } from 'utils/hooks/useClasses';
import { CarModelSelect } from './CarModelSelect';
import { useCarFormImage } from './useCarFormImage';
import { searchInMakes } from './useCarMakesData';
import dayjs from 'dayjs';
import { defaultCar } from '../CreateCarPage';

const tKey = getTKey('car_form');

const styles = ({ spacing }: Theme) => ({
  column: {
    marginTop: spacing(-2),
  },
});

interface CarFormProps {
  makes: MappedCarMakeGroup[];
  onSubmit: (values: any) => void;
  defaultValues?: Car;
  editMode?: boolean;
  car: Car;
}

export type NewCarValues = {
  make: CarOption;
  model: CarOption;
  year: string;
  plate: string;
  mileage: string;
  mileageMetric: string;
};

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    make: yup.object().required(),
    model: yup.object().required(),
    year: yupNumberValidator(t),
    plate: yup.string(),
    mileage: yupNumberValidator(t),
    mileageMetric: yup.string().oneOf(['km', 'mi']).default('km'),
  });

const createDefaultValues = (values?: Car) => ({
  make: '',
  model: '',
  year: getOr(dayjs().year(), 'year', values),
  plate: getOr('', 'plate', values),
  mileage: getOr(1, 'mileage', values),
  mileageMetric: getOr('km', 'mileageMetric', values),
});

export const MakesModelsFields: ComponentType<{
  makes: MappedCarMakeGroup[];
  defaultValues?: Car;
  labelBackground?: string;
}> = ({ makes, defaultValues, labelBackground }) => {
  const { t } = useTranslation();
  const chosenMake = useWatch({ name: 'make' });
  const [model, setModel] = useState<any>(defaultValues?.model);
  const { setValue } = useFormContext();
  const defaultMake = defaultValues && searchInMakes(makes, defaultValues.make.toLowerCase());
  const defaultModel = defaultValues && { label: defaultValues.model, value: defaultValues.model };

  useEffect(() => {
    if (!chosenMake) {
      setModel('');
    }
  }, [chosenMake]);

  useEffect(() => {
    if (defaultMake && defaultModel && !chosenMake) {
      setValue('make', defaultMake);
      setValue('model', defaultModel);
    }
  }, [defaultMake, defaultModel]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SearchSelect
        name='make'
        options={makes}
        placeholder='makes'
        label={t('search_select_label_make')}
        labelBackground={labelBackground}
      />
      <CarModelSelect defaultModel={model} labelBackground={labelBackground} />
    </>
  );
};

export const CarForm: ComponentType<CarFormProps> = ({ makes, onSubmit, defaultValues, editMode, car }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [uploaderIsDirty, setUploaderIsDirty] = useState<boolean>(false);
  const { images, onImageChange } = useCarFormImage(defaultValues);
  const { compressedImage: carImage, isOldImage } = useCompressImage(
    images[0]?.file,
    !!defaultValues?.image,
    uploaderIsDirty
  );

  const [year, setYear] = useState(getOr(dayjs().year(), 'year', defaultValues));

  const [plate, setPlate] = useState(getOr('', 'plate', defaultValues));

  const [mileage, setMileage] = useState(getOr('0', 'mileage', defaultValues));

  const handleSubmit = (data: NewCarValues) => {
    const fd = new FormData();

    if (carImage) {
      fd.append('image', carImage, 'carImage.jpg');
    }

    if (isOldImage) {
      fd.append('old_image', 'true');
    }

    fd.append('make', data.make.value);
    fd.append('mileage', data.mileage);
    fd.append('mileage_metric', data.mileageMetric);
    fd.append('model', data.model.value);
    fd.append('plate', data.plate);
    fd.append('year', data.year);

    onSubmit(fd);
  };
  const values = createDefaultValues(defaultValues);

  return values ? (
    <Form onSubmit={handleSubmit} schema={validationSchema(t)} defaultValues={createDefaultValues(defaultValues)}>
      <Grid container justifyContent='center' spacing={3}>
        <Grid item md={6}>
          <ImageUploader images={images} onChange={onImageChange} setIsDirty={setUploaderIsDirty} car={defaultValues ?? defaultCar}/>
        </Grid>
        <Grid item md={6} className={classes.column}>
          <MakesModelsFields makes={makes} defaultValues={defaultValues} labelBackground='#121212' />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInputControlled
                name='year'
                label={t(tKey('year'))}
                textFieldProps={{
                  value: year,
                  type: 'number',
                  onChange: (event) => setYear(parseInt(event.target.value)),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputControlled
                name='plate'
                label={t(tKey('plate_number'))}
                textFieldProps={{ value: plate, onChange: (event) => setPlate(event.target.value) }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputControlled
                name='mileage'
                label={t(tKey('mileage'))}
                textFieldProps={{
                  value: mileage,
                  type: 'number',
                  onChange: (event) => {
                    try {
                      setMileage(Math.abs(parseInt(event.target.value)));
                    } catch (er: any) {}
                  },
                }}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <RadioSwitch
                name='mileageMetric'
                defaultValue={defaultValues?.mileageMetric}
                values={[
                  { value: 'km', label: 'Km' },
                  { value: 'mi', label: 'Mi' },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box mt={3} mb={4}>
          <StyledButton label={editMode ? t('__common__save') : t(tKey('create_car'))} type='submit' />
        </Box>
      </Grid>
    </Form>
  ) : (
    <></>
  );
};
