import { Box, Grid } from '@mui/material';
import React, { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { AssistedRecordsHeader, ConfirmationDialog, PageLoader, RecordTitle, Wrapper } from 'components';
import { AssistedRecordInstruction } from 'components/AssistedRecordInstruction/AssistedRecordInstruction';
import { AssistedRecordsCarItem } from 'features/assisted-records/components/AssistedRecordsCarItem';
import { useAssistedLayout } from 'features/assisted-records/hooks/useAssistedLayout';
import { DraftsList } from 'features/records/components/DraftsList';
import { DEFAULT_CURRENCY } from 'utils';

export const AssistedLayout: ComponentType = ({ children }) => {
  const { t } = useTranslation();
  const rootRoute = useRouteMatch({ path: '/assisted/:carId', exact: true });
  const {
    carDataLoading,
    carData,
    draftsDataLoading,
    draftsData,
    identifier,
    deleteRecordId,
    groupData,
    groupDataLoading,
    totalCost,
    onSessionFinish,
    handleDraftDelete,
    handleConfirmDraftDelete,
    handleConfirmDraftClose,
  } = useAssistedLayout();
  const isRecordsListEmpty = rootRoute && draftsData && draftsData.length === 0;
  const [reminder, setReminder] = useState(true);
  const canFinishSession = Boolean(groupData?.title && draftsData?.length);

  const recordTitleBlock = (
    <>
      {!groupDataLoading && groupData && (
        <RecordTitle
          data={groupData}
          recordsCount={draftsData && draftsData.length}
          totalCost={totalCost}
          identifier={identifier}
        />
      )}
    </>
  );

  const myRef = React.useRef<HTMLDivElement>(null);

  const executeScroll = () => {
    // @ts-ignore
    myRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  useEffect(() => {
    if (isRecordsListEmpty) {
      setReminder(false);
    } else setReminder(true);
  }, [isRecordsListEmpty]);

  return (
    <>
      <AssistedRecordsHeader
        appear={isRecordsListEmpty && !reminder}
        canFinish={canFinishSession}
        onSessionFinish={onSessionFinish}
        notification={reminder}
      />
      <ConfirmationDialog
        description={t('confirm_dialog_draft')}
        open={Boolean(deleteRecordId)}
        onConfirm={handleConfirmDraftDelete}
        onClose={handleConfirmDraftClose}
      />
      <Wrapper>
        <Box my={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              {carDataLoading ? (
                <PageLoader />
              ) : !carDataLoading && carData ? (
                <AssistedRecordsCarItem executeScroll={executeScroll} car={carData} identifier={identifier} />
              ) : null}
              <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
                {recordTitleBlock}
              </Box>
              <DraftsList
                loading={draftsDataLoading}
                data={draftsData}
                carId={carData?.id}
                currency={carData?.currency ?? DEFAULT_CURRENCY}
                volume={carData?.volumeMetric ?? 'l'}
                handleDraftDelete={handleDraftDelete}
              />
            </Grid>
            <Grid item xs={12} md={8} ref={myRef}>
              <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
                {recordTitleBlock}
              </Box>
              {isRecordsListEmpty && !reminder && <AssistedRecordInstruction hideReminder={() => setReminder(true)} />}
              {children}
            </Grid>
          </Grid>
        </Box>
      </Wrapper>
    </>
  );
};
