import { Add, CarRepair, Description, DocumentScanner, KeyboardVoice } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  CircularProgress,
  Fab,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  SvgIconProps,
  Theme,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import React, { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUserIsBannedCreationMutation } from 'services/stories/useUserIsBannedQuery';
import { theme } from 'theme';
import { isLoggedIn } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = (theme: Theme) => ({
  button: {
    zIndex: 0,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
    '& svg': {
      color: theme.palette.background.default,
    },
  },
  menuPaper: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogButton: {
    paddingLeft: 0,
    fontSize: 16,
  },
  speedDial: {
    '& button': {
      backgroundColor: theme.palette.secondary.main + '!important',
    },
    '& .MuiSpeedDialAction-staticTooltipLabel': {
      color: theme.palette.text.primary,
      background: 'unset',
      width: 200,
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
  },
  speedDialAction: {
    color: theme.palette.primary.main,
    background: 'unset',
  },
});

interface addRecordAction {
  label: string;
  icon: React.ReactElement<SvgIconProps>;
  path: string;
}

const addRecordActions: addRecordAction[] = [
  { label: 'add_new_button_local', icon: <Description />, path: 'new' },
  { label: 'add_new_button_voice', icon: <KeyboardVoice />, path: 'voice' },
  { label: 'add_new_button_invoice', icon: <DocumentScanner />, path: 'invoice' },
  { label: 'add_new_button_assisted', icon: <CarRepair />, path: 'generate' },
];

export const AddNewButton: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const desktopResolution = useMediaQuery(`(min-width: ${theme.breakpoints.values.md}px)`);
  const history = useHistory();
  const { pathname } = useLocation();
  const carsRouteMatch = useRouteMatch({ path: '/cars', exact: true });
  const storiesRouteMatch = useRouteMatch({
    path: [
      '/',
      '/stories',
      '/stories/recent',
      '/stories/follow',
      '/stories/m',
      '/stories/d',
      '/stories/w',
      '/stories/news',
    ],
    exact: true,
  });
  const remindersRouteMatch = useRouteMatch({
    path: ['/reminders/:carId'],
    exact: true,
  });
  const recordsRouteMatch = useRouteMatch({ path: '/records/:carId', exact: true });
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const isAuth = isLoggedIn();
  const isBannedMutation = useUserIsBannedCreationMutation();
  const [isBannedLoader, setIsBannedLoader] = useState(false);
  const [openRecords, setOpenRecords] = React.useState(false);

  let clickTimer: any = null;
  const handleClickAddRecord = () => {
    if (clickTimer == null) {
      // This is the first click. Start the timer.
      clickTimer = setTimeout(() => {
        // Timer expired, treat this as a single click.
        setOpenRecords(!openRecords);
        clickTimer = null;
      }, 200); // Adjust the timeout to suit your needs, e.g., 250 milliseconds.
    } else {
      // A second click detected before the timer expired, treat this as a double click.
      clearTimeout(clickTimer);
      clickTimer = null;
      if (pathname.indexOf('records') > -1) {
        history.push(`${pathname}/new`);
      }
    }
  };

  const onAddStoryClick = async () => {
    if (isAuth) {
      setIsBannedLoader(true);
      isBannedMutation.mutate(undefined, {
        onSuccess(data, variables, context) {
          setIsBannedLoader(false);
          let isBanned = data;

          if (isBanned?.is_story_banned === true) {
            toast(t('create_story_banned'));
          }
          if (isBanned?.is_story_banned === false) {
            history.push('/stories/new');
          }
          if (isBanned.error) {
            toast(t('__common__error'));
          }
        },
        onError(error, variables, context) {
          setIsBannedLoader(false);
        },
      });
    } else {
      setOpenUnregistered(true);
    }
  };

  const onAddCarClick = () => {
    if (isAuth) {
      setIsBannedLoader(true);
      isBannedMutation.mutate(undefined, {
        onSuccess(data, variables, context) {
          setIsBannedLoader(false);
          let isBanned = data;

          if (isBanned?.is_car_banned === true) {
            toast(t('create_car_banned'));
          }
          if (isBanned?.is_car_banned === false) {
            history.push('/cars/new');
          }
          if (isBanned.error) {
            toast(t('__common__error'));
          }
        },
        onError(error, variables, context) {
          setIsBannedLoader(false);
        },
      });
    }
  };

  const onAddReminderClick = () => {
    history.push(`${pathname}/new`);
  };

  return carsRouteMatch ? (
    <Fab color='secondary' aria-label='Add' className={classes.button} onClick={onAddCarClick}>
      {isBannedLoader ? <CircularProgress /> : <Add />}
    </Fab>
  ) : recordsRouteMatch ? (
    <>
      <Backdrop
        open={openRecords}
        sx={{ zIndex: desktopResolution ? 10 : -1, backgroundColor: 'rgba(0,0,0,0.8)' }}
        onClick={handleClickAddRecord}
      />
      <Box sx={{ height: 56, transform: 'translateZ(0px)', zIndex: 20, flexGrow: 1, position: 'relative' }}>
        <SpeedDial
          ariaLabel='Record menu'
          sx={{
            position: !desktopResolution ? 'absolute' : 'relative',
            bottom: 0,
            right: 0,
            left: desktopResolution ? 16 : 'auto',
          }}
          icon={<SpeedDialIcon />}
          onClick={handleClickAddRecord}
          open={openRecords}
          direction={desktopResolution ? 'down' : 'up'}
          className={clsx(classes.speedDial, classes.button)}
        >
          {addRecordActions.map((action) => (
            <SpeedDialAction
              className={classes.speedDialAction}
              key={action.label}
              icon={action.icon}
              tooltipTitle={t(action.label)}
              tooltipOpen
              onClick={() => history.push(`${pathname}/${action.path}`)}
            />
          ))}
        </SpeedDial>
      </Box>
    </>
  ) : storiesRouteMatch ? (
    <>
      <Fab color='secondary' aria-label='Add' className={classes.button} onClick={onAddStoryClick}>
        {isBannedLoader ? <CircularProgress /> : <Add />}
      </Fab>
      <UnregisteredPopup
        open={openUnregistered}
        from='stories/new'
        onClose={() => {
          setOpenUnregistered(false);
        }}
      />
    </>
  ) : remindersRouteMatch ? (
    <Fab color='secondary' aria-label='Add' className={classes.button} onClick={onAddReminderClick}>
      <Add />
    </Fab>
  ) : null;
};
