import { FormControl, InputLabel, MenuItem, Select, Theme, useMediaQuery } from '@mui/material';
import { capitalize } from 'lodash';
import getOr from 'lodash/fp/getOr';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { InnerMobileHeader, PageLoader } from 'components';
import { useCarQuery } from 'services';
import { Record, RecordAITypeShort } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { RecordDefaultForm } from './RecordDefaultForm';
import { RecordFuelForm } from './RecordFuelForm';
import { recordCategories } from './record-categories';
import { theme } from 'theme';

const tKey = getTKey('record_form');

interface RecordFormProps {
  onSubmit: (values: any) => void;
  defaultValues?: Record | undefined;
  editMode?: boolean;
  isSubmitting?: boolean;
  ai?: RecordAITypeShort;
  onRemove?: (id: string) => void;
  copying?: boolean;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  category: {
    padding: spacing(1, 1),
    width: '100%',
    marginTop: spacing(0.9),
    marginBottom: spacing(1),
    [breakpoints.up('md')]: {
      width: '49%',
    },
    '& select': {
      paddingRight: '0px !important',
    },
  },
  container: {
    position: 'relative',
  },
});

export const RecordForm: ComponentType<RecordFormProps> = ({
  onSubmit,
  defaultValues,
  editMode,
  isSubmitting,
  ai,
  onRemove,
  copying,
}) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState(getOr('refueling', 'category', defaultValues));
  const [values, setValues] = useState(defaultValues);
  const classes = useClasses(styles);
  const { carId } = useParams<{ carId: string }>();
  const { data: carData, isLoading } = useCarQuery(carId);
  const fuelCategory = () => category === 'refueling' || category === 'recharging';
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setValues(defaultValues);
    if (defaultValues?.category) {
      setCategory(defaultValues.category);
    }
  }, [defaultValues]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <div className={classes.container}>
      {!ai && (
        <InnerMobileHeader
          title={`${values && !copying ? t(tKey('edit_record_for')) : t(tKey('new_record_for'))} ${capitalize(
            carData?.make
          )} ${carData?.model}`}
        />
      )}
      <FormControl fullWidth size='small'>
        <InputLabel htmlFor={category}>{t(tKey('category'))}</InputLabel>
        {mobile ? (
          <Select
            sx={{ display: { xs: 'block', md: 'none' } }}
            native
            size='small'
            name='category'
            className={classes.category}
            label={t(tKey('category'))}
            value={category}
            onChange={(event) => {
              if (
                (!fuelCategory() && (event.target.value === 'refueling' || event.target.value === 'recharging')) ||
                (fuelCategory() && event.target.value !== 'refueling' && event.target.value !== 'recharging')
              ) {
                setValues(undefined);
              }
              setCategory(event.target.value as string);
            }}
          >
            {recordCategories.map((option: any) => {
              return (
                <option key={option.value} value={option.value}>
                  {t(option.label)}
                </option>
              );
            })}
          </Select>
        ) : (
          <Select
            sx={{ display: { md: 'block', xs: 'none' } }}
            size='small'
            name='category'
            className={classes.category}
            label={t(tKey('category'))}
            value={category}
            onChange={(event) => {
              if (
                (!fuelCategory() && event.target.value === 'recharging') ||
                (fuelCategory() && event.target.value !== 'recharging')
              ) {
                setValues(undefined);
              }
              setCategory(event.target.value as string);
            }}
          >
            {recordCategories.map((option: any) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {t(option.label)}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
      {fuelCategory() ? (
        <RecordFuelForm
          onSubmit={onSubmit}
          defaultValues={values}
          editMode={editMode}
          category={category}
          isSubmitting={isSubmitting}
          ai={ai}
          onRemove={onRemove}
        />
      ) : (
        <RecordDefaultForm
          onSubmit={onSubmit}
          defaultValues={values}
          editMode={editMode}
          category={category}
          isSubmitting={isSubmitting}
          ai={ai}
          onRemove={onRemove}
        />
      )}
    </div>
  );
};
