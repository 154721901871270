import { ComponentType, Suspense } from 'react';
import { render } from 'react-dom';

import withClearCache from './ClearCache';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'react-toastify/dist/ReactToastify.css';

import { App } from './App';
import './assets/fonts/AGFatumC.otf';
import './i18n';
import './index.css';

const ClearCacheComponent: ComponentType = withClearCache(App);

render(<Suspense fallback={<div>Loading...</div>}><ClearCacheComponent /></Suspense>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
