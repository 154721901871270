import React, { ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isLoggedIn } from 'utils/api';

interface PrivateRouteProps extends RouteProps {
  component: ComponentType;
}

export const PrivateRoute: ComponentType<PrivateRouteProps> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props: RouteProps) => {
      return isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      );
    }}
  />
);
