import { Button, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import store from 'store2';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  outerContainer: {
    display: 'flex',
    position: 'fixed',
    bottom: 10,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  },
  container: {
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10,
    padding: spacing(2),
    boxShadow: '1px 1px rgba(32, 32, 32)',
    borderRadius: 5,
    backgroundColor: 'rgba(42, 42, 42)',
  },
  btnContainer: {
    width: '30%',
    margin: 'auto',
    justifyContent: 'space-around',
    marginTop: spacing(1),
    display: 'flex',
    [breakpoints.down('sm')]: {
      width: '80%',
    },
  },
});

const tKey = getTKey('cookie_banner');

type CookieBannerPrams = {
  close: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CookieBanner = ({ close }: CookieBannerPrams) => {
  const classes = useClasses(styles);
  const history = useHistory();
  const { t } = useTranslation();

  const hideBanner = () => {
    close(false);
    store('driverbook_cookies', true);
  };
  const navigateToPrivatePolicy = () => {
    history.push('/privacy-policy');
  };

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div>{t(tKey('text'))}</div>
        <div className={classes.btnContainer}>
          <Button className={classes.btn} variant='text' color='primary' onClick={hideBanner}>
            {t(tKey('ok'))}
          </Button>
          <Button className={classes.btn} variant='text' color='primary' onClick={navigateToPrivatePolicy}>
            {t(tKey('privacy_policy'))}
          </Button>
        </div>
      </div>
    </div>
  );
};
