import { CommonLayout } from "components";
import { ComponentType } from "react";
import { UserWrapper } from "./components/UserWrapper";
import { useParams } from "react-router-dom";


export const PublicUserPage: ComponentType = () => {

  const { userId } = useParams<{ userId: string }>();
  
  return (
    <CommonLayout>
      <UserWrapper userId={userId}/>
    </CommonLayout>
  )
}