import { ComponentType, createRef, LegacyRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AssistedLayout } from 'components';
import { useCreateDraftMutation } from 'services';

import { AssistedRecordForm } from './components/AssistedRecordForm';

export const CreateAssistedRecordPage: ComponentType = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { carId, identifier } = useParams<{ carId: string; identifier: string }>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const mutation = useCreateDraftMutation();
  const myRef = createRef<LegacyRef<HTMLDivElement>>();

  const onSubmit = (data: any) => {
    setIsSubmitting(true);
    //@ts-ignore
    gtag?.('event', 'assisted_record_added', { category: data.category });

    mutation.mutate(
      { carId, formData: data },
      {
        onSuccess: () => {
          const event = new Event('assistedRecordCreate');
          window.dispatchEvent(event);
          setIsSubmitting(false);
          toast(t('assisted_record_added'));
          history.push(`/assisted/${identifier}`);
        },
        onError: () => {
          setIsSubmitting(false);
          console.warn('Something went wrong');
        },
      }
    );
  };

  useEffect(() => {
    //    @ts-ignore
    myRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [myRef]);

  return (
    <AssistedLayout>
      {/* @ts-ignore */}
      <div ref={myRef}>
        <AssistedRecordForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
      </div>
    </AssistedLayout>
  );
};
