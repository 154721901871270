import { ComponentType, useState } from 'react';
import { Theme, Button, IconButton } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { ConfirmationDialog, PageLoader, PrevNextButton, StyledButton } from 'components';
import { Check, VisibilityOff, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AdminReviewItem } from './components/AdminReview/AdminReviewItem';
import {
  useAdminReview,
  useAdminStoryModerate,
  useAdminCommentModerate,
  useAdminWarnBanUser,
  useAdminUserModerate,
} from './hooks';
import { ItemHiddenDialog, UserBanDialog, UserWarnDialog } from './components/Dialogs';
import { EmptyData } from './components/EmptyData';
import { ElementToCorrect, ItemActionType } from './components/AdminReview/AdminItemTypes';
import { ProfileWarnDialog } from './components/Dialogs/ProfileWarnDialog';
import { useAdminCarModerate } from './hooks/useAdminCarModerate';

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    margin: spacing(5, 10),
    width: `calc(100vw - ${spacing(20)})`,
    height: `calc(100vh - ${spacing(10)})`,
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    itemAlign: 'center',
    justifyContent: 'space-between',
  },

  itemControlBar: {
    display: 'flex',
    flexDirection: 'row',
    itemAlign: 'center',
    justifyContent: 'center',
    height: 56,
    marginTop: spacing(2),

    button: {
      width: 180,
      margin: spacing(0, 0.5),
    },
  },
  hideButton: {
    background: '#FF5050',
  },
  openOriginalText: {
    fontWeight: '600',
  },
  currentItemNumber: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontWeight: 500,
  },
});

export const AdminReviewPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const history = useHistory();
  const { items, total, loading, refreshItems } = useAdminReview();
  const { approveStory } = useAdminStoryModerate();
  const { approveComment } = useAdminCommentModerate();
  const { profileApprove } = useAdminUserModerate();
  const { approveCar } = useAdminCarModerate();
  const {
    onBan,
    onWarn,
    onBanOpenDialog,
    openBanUserDialog,
    openHiddenDialog,
    setOpenHiddenDialog,
    hiddenType,
    setHiddenType,
    setHiddenUser,
    openWarnUserDialog,
    onWarnOpenDialog,
    setHiddenItemId,
    onCloseBanDialog,
    onCloseWarnDialog,
    hiddenUser,
    setHiddenItem,
    openProfileWarnDialog,
    onWarnProfile,
    setOpenProfileWarnDialog,
    setWarnNote,
  } = useAdminWarnBanUser();
  const [currentItem, setCurrentItem] = useState(0);
  const [refreshing, setRefreshing] = useState(false);

  const nextItem = () => {
    if (currentItem === items.length - 1) return;
    setCurrentItem((current) => current + 1);
  };

  const prevItem = () => {
    if (!currentItem) return;
    setCurrentItem((current) => current - 1);
  };

  const approveReviewItem = async () => {
    setRefreshing(true);
    const { itemType, id } = getCurrentItemData();
    if (itemType === 'story') {
      await approveStory(id);
    }
    if (itemType === 'comment') {
      await approveComment(id);
    }
    await refreshItemsForReview();
    setRefreshing(false);
  };

  const hideItem = async () => {
    const { itemType, id, user_id } = getCurrentItemData();
    setHiddenUser(user_id);
    setHiddenItemId(id);
    setHiddenItem(items[currentItem]);
    if (itemType === 'story') setHiddenType('creating_story');
    if (itemType === 'comment') setHiddenType('commenting');
    if (items[currentItem].username) setHiddenType('profile');
    if (items[currentItem].make) setHiddenType('car');
    setOpenHiddenDialog(true);
  };

  const getCurrentItemData = () => {
    const itemType = items[currentItem].type;
    const id = items[currentItem].id;
    const user_id = items[currentItem].user_id;
    return { itemType, id, user_id };
  };

  const refreshItemsForReview = async () => {
    setRefreshing(true);
    await refreshItems();
    setCurrentItem(0);
    setRefreshing(false);
  };

  const onBanUser = async (ban_from: ItemActionType, duration: string, note: string) => {
    await onBan(ban_from, duration, note);
    await refreshItemsForReview();
  };
  const onWarnUser = async (note: string) => {
    if (items[currentItem].username !== undefined && !items[currentItem].was_corrected) {
      setOpenProfileWarnDialog(true);
      setWarnNote(note);
    } else {
      await onWarn(note);
      await refreshItemsForReview();
    }
  };

  const onWarnUserProfile = async (elementsToCorrect: ElementToCorrect[]) => {
    await onWarnProfile(elementsToCorrect);
    await refreshItemsForReview();
  };

  const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false);

  const onApproveItem = async () => {
    if (items[currentItem].username) {
      approveUserProfile();
    }
    if (items[currentItem].title || items[currentItem].text) {
      approveReviewItem();
    }
    if (items[currentItem].make) {
      approveVehicle();
    }
    setOpenApproveDialog(false);
  };

  const approveUserProfile = async () => {
    setRefreshing(true);
    await profileApprove(items[currentItem].user_id);
    await refreshItemsForReview();
    setRefreshing(false);
  };

  const approveVehicle = async () => {
    setRefreshing(true);
    await approveCar(items[currentItem].id);
    await refreshItemsForReview();
    setRefreshing(false);
  };

  const openReviewItem = () => {
    if (items[currentItem].username) {
      const url = process.env.REACT_APP_PUBLIC_API_URL?.replace('/api/', '') + `/user/${items[currentItem].user_id}`;
      openInNewTab(url);
    }
    if (items[currentItem].title) {
      const url =
        process.env.REACT_APP_PUBLIC_API_URL?.replace('/api/', '') +
        `/stories/${items[currentItem].car_id}/${items[currentItem].id}`;
      openInNewTab(url);
    }
    if (items[currentItem].text) {
      const url =
        process.env.REACT_APP_PUBLIC_API_URL?.replace('/api/', '') +
        `/stories/${items[currentItem].car_id}/${items[currentItem].story_id}#comments`;
      openInNewTab(url);
    }
    if (items[currentItem].mileage) {
      const url = process.env.REACT_APP_PUBLIC_API_URL?.replace('/api/', '') + `/user/${items[currentItem].user.id}`;
      openInNewTab(url);
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const getTypeItemI18n = () => {
    if (items[currentItem]?.type) {
      return items[currentItem].type;
    }
    if (items[currentItem]?.username) {
      return 'user';
    }
    if (items[currentItem].make) {
      return 'car';
    }
  };

  const getContentDescription = () => {
    if (items[currentItem]?.type) {
      return items[currentItem]?.title ?? items[currentItem]?.text;
    }
    if (items[currentItem]?.username) {
      return items[currentItem]?.username;
    }
    if (items[currentItem].make) {
      return `${items[currentItem]?.make} ${items[currentItem]?.model}`;
    }
  };

  return (
    <div className={classes.root}>
      {loading || refreshing ? (
        <PageLoader />
      ) : (
        <>
          <div className={classes.appBar}>
            <IconButton color='inherit' onClick={() => history.push('/secret/machineroom/stories')} size='large'>
              <Close />
            </IconButton>
            <div className={classes.currentItemNumber}>
              <div>
                {items.length > 0 && (
                  <div>
                    {currentItem + 1}/{total}
                  </div>
                )}
              </div>
            </div>
            {items.length > 0 && (
              <Button color='primary' variant='text' className={classes.openOriginalText} onClick={openReviewItem}>
                {t('admin_open_original_button')}
              </Button>
            )}
          </div>
          <div className={classes.itemBox}></div>
          {items.length === 0 && <EmptyData title={t('admin_empty_review')} />}
          {items.length > 0 && (
            <>
              <AdminReviewItem item={items[currentItem]} />
              <div className={classes.itemControlBar}>
                <PrevNextButton
                  next={nextItem}
                  prev={prevItem}
                  prevDisabled={currentItem === 0}
                  nextDisabled={currentItem === items.length - 1}
                />
                <StyledButton
                  label={t('admin_item_approve')}
                  LeftMuiIcon={Check}
                  color='secondary'
                  onClick={setOpenApproveDialog}
                />
                <StyledButton
                  label={t('admin_item_hide')}
                  LeftMuiIcon={VisibilityOff}
                  className={classes.hideButton}
                  onClick={hideItem}
                />
              </div>

              <ConfirmationDialog
                title={t(`admin_approve_dialog_title_${getTypeItemI18n()}`)}
                description={t('admin_approve_dialog_description') + ('\n' + getContentDescription())}
                open={openApproveDialog}
                onConfirm={onApproveItem}
                onClose={() => setOpenApproveDialog(false)}
                confirmText={t('__common__confirm')}
              />
              <ItemHiddenDialog
                open={openHiddenDialog}
                onClose={() => setOpenHiddenDialog(false)}
                onBan={onBanOpenDialog}
                onWarn={onWarnOpenDialog}
                reason={hiddenType}
                userId={hiddenUser}
                user={items[currentItem].user}
                wasCorrected={items[currentItem].was_corrected}
              />
              <ProfileWarnDialog
                open={openProfileWarnDialog}
                onClose={async () => {
                  setOpenProfileWarnDialog(false);
                  await refreshItemsForReview();
                }}
                onConfirm={onWarnUserProfile}
              />
            </>
          )}
        </>
      )}
      <UserBanDialog onClose={onCloseBanDialog} onConfirm={onBanUser} open={openBanUserDialog} haveCarOption={true} />
      <UserWarnDialog onClose={onCloseWarnDialog} onConfirm={onWarnUser} open={openWarnUserDialog} />
    </div>
  );
};
