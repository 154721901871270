import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useDeleteCarMutation } from 'services';

type useCarsListType = () => {
  idToDelete: string;
  handleOnEdit: (id: string) => void;
  handleOnDelete: (id: string) => void;
  handleClose: () => void;
  handleConfirmDelete: () => void;
  handleOnTransfer: (id: string) => void;
  handleOnShare: (id: string) => void;
};

export const useCarsList: useCarsListType = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const mutation = useDeleteCarMutation();
  const [idToDelete, setIdToDelete] = useState<string>('');

  const handleOnEdit = (id: string) => {
    history.push(`/cars/${id}/edit`);
  };

  const handleOnDelete = (id: string) => {
    setIdToDelete(id);
  };

  const handleClose = () => {
    setIdToDelete('');
  };

  const handleConfirmDelete = () => {
    mutation.mutate(idToDelete);
    setIdToDelete('');
    toast(t('__toast__car_was_deleted'));
  };

  const handleOnTransfer = (id: string) => {
    history.push(`/cars/${id}/transfer`);
  };

  const handleOnShare = (id: string) => {
    history.push(`/cars/${id}/share`);
  };

  return {
    idToDelete,
    handleOnEdit,
    handleOnDelete,
    handleClose,
    handleConfirmDelete,
    handleOnTransfer,
    handleOnShare,
  };
};
