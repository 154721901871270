import { Grid } from '@mui/material';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute, SettingsLayout, SettingsMenuDesktop } from 'components';
import { useProfileInfoQuery } from 'services';
import { setProfile } from 'store/profile/slice';
import { ProfileContext as Context } from 'store/profile/store';

import { DemoTour } from 'components/DemoTour/DemoTour';
import { SettingsRootPage } from './SettingsRootPage';
import { CurrencyTab } from './Tabs/CurrencyTab';
import { DateAndMetricTab } from './Tabs/DateAndMetricTab';
import { FeedbackTab } from './Tabs/FeedbackTab';
import { LanguageTab } from './Tabs/LanguageTab';
import { LogoutTab } from './Tabs/LogoutTab';
import { PasswordTab } from './Tabs/PasswordTab';
import { ProfileSettings } from './Tabs/ProfileSettings';
import { ProfileTab } from './Tabs/ProfileTab';
import { SettingsTab } from './Tabs/SettingsTab';
import { SigninTab } from './Tabs/SigninTab';
import { NotificationsTab } from './Tabs/NotificationsTab';
import { BlockedUsersTab } from './Tabs/BlockedUsersTab';

export const SettingsPage: ComponentType = () => {
  const { dispatch, state } = useContext(Context);
  const { data } = useProfileInfoQuery();
  const [demo, setDemo] = useState(false);

  useEffect(() => {
    if (data) {
      dispatch(setProfile(data));
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SettingsLayout>
      <Grid container spacing={8}>
        <Grid item md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
          <SettingsMenuDesktop
            startDemo={() => {
              //@ts-ignore
              gtag?.('event', 'demo_tour_clicked', { method: 'demo_tour_clicked' });
              setDemo(true);
            }}
          />
        </Grid>
        {state.id && (
          <Grid item xs={12} md={8}>
            <Switch>
              <PrivateRoute path='/settings' component={SettingsRootPage} exact />
              <PrivateRoute path='/settings/profile' component={ProfileTab} />
              <PrivateRoute path='/settings/setProfile' component={ProfileSettings} />
              <PrivateRoute path='/settings/general' component={SettingsTab} />
              <PrivateRoute path='/settings/language' component={LanguageTab} />
              <PrivateRoute path='/settings/feedback' component={FeedbackTab} />
              <PrivateRoute path='/settings/logout' component={LogoutTab} />
              <PrivateRoute path='/settings/password' component={PasswordTab} exact />
              <PrivateRoute path='/settings/sign_in' component={SigninTab} exact />
              <PrivateRoute path='/settings/date_metric' component={DateAndMetricTab} exact />
              <PrivateRoute path='/settings/currency' component={CurrencyTab} exact />
              <PrivateRoute path='/settings/notifications' component={NotificationsTab} exact />
              <PrivateRoute path='/settings/blocked_users' component={BlockedUsersTab} exact />
            </Switch>
          </Grid>
        )}
      </Grid>
      {demo && <DemoTour handleCloseModal={() => setDemo(false)} />}
    </SettingsLayout>
  );
};
